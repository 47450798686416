<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>Tool and Die Add</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="invalid-feedback" v-if="errors">
                                        <div class="alert alert-danger">
                                            <ul>
                                                <li v-for="error in errors" :key="error">
                                                    {{ error }}
                                                </li>
                                            </ul>                    
                                        </div>
                                    </div>
                                    <form @submit.prevent="addMachine" ref="myForm">
                                        <div class="form-group row">
                                            
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Logo or Profile Picture</label>
                                                    <input type="file" name="logo"  class="form-control"  @change="onFileChange">
                                                    <div class="invalid-feedback" v-if="errors.logo">
                                                        {{ errors.logo[0] }}
                                                    </div>
                                                    <div id="preview">
                                                            <img v-if="url" :src="url" />
                                                          </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Company Name *</label>
                                                    <input type="text" name="name" class="form-control" :class="{ 'is-invalid': errors.name }" v-model="machine.name" required>
                                                    <div class="invalid-feedback" v-if="errors.name">
                                                        {{ errors.name[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Address *</label>
                                                    <input type="text" name="address"  class="form-control" :class="{ 'is-invalid': errors.address }" v-model="machine.address" required="">
                                                    <div class="invalid-feedback" v-if="errors.address">
                                                        {{ errors.address[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Country *</label>
                                                    <select name="country_id" class="form-control" :class="{ 'is-invalid': errors.country_id }" v-model="machine.country_id" required="">
                                                        <option value="">Select</option>
                                                        <option v-for="country in countries" v-bind:key="country.id" :value="country.id" :selected="1">{{ country.name }}</option>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.country_id">
                                                        {{ errors.country_id[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>State *</label>
                                                    <input type="text" name="state"  class="form-control" :class="{ 'is-invalid': errors.state }" v-model="machine.state" required="">
                                                    <div class="invalid-feedback" v-if="errors.state">
                                                        {{ errors.state[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>City *</label>
                                                    <input type="text" name="city"  class="form-control" :class="{ 'is-invalid': errors.city }" v-model="machine.city" required="">
                                                    <div class="invalid-feedback" v-if="errors.city">
                                                        {{ errors.city[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <input type="text" name="description" class="form-control" :class="{ 'is-invalid': errors.description }" v-model="machine.description">
                                                    <div class="invalid-feedback" v-if="errors.description">
                                                        {{ errors.description[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="form-group">
                                            <input type="submit" value="Submit" class="btn btn-info">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './../SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            machine: {
            },
            countries: [],
            success:null,
            errors:false,
            loading: false,
            logo:null,
            url: null,
            imgPath: process.env.VUE_APP_API_URL
        }
    },
    created() {
        this.loading = true
        
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/countries")
        .then(response => {
            this.countries = response.data.countries;
        }).finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/tool-room-company1/edit")
        .then(response => {
            if(response.data.error==false)
            {
                this.machine = response.data.company;
            this.url = this.imgPath+response.data.company.logo;
            }
            
        });
    },
    methods: {
            addMachine() {
                this.loading = true
                this.success = null
                this.errors = null
                var myFormData = new FormData(this.$refs.myForm)
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/tool-room-company", myFormData)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        window.location.reload();
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            onFileChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
            },
            
        }
};
</script>