<template>
  <div id="app">
    <div class="top-sec">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-xs-6">
                <div class="top-left-sec">
                <ul>
                    <li><a href="https://www.facebook.com/Grownet-102001492377548/" target="_blank"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="https://twitter.com/IndiaGrownet" target="_blank"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="https://www.instagram.com/grownet.in/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/grownet-india-6b8118233/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                    <li><a href="https://wa.me/+919899080914" target="_blank"><i class="fa fa-whatsapp"></i></a></li>
                    <li><a>Email: admin@thegrownet.com</a></li>
                </ul>
                </div>
            </div>
            <div class="col-md-6 col-xs-6">
                <div class="top-right-sec">
                <ul>
                    <li class="dropdown" v-show="user">
                    <a  data-toggle="dropdown" aria-expanded="false">
                      <i class="fa fa-user-o"></i> Hi <span v-for="userpro in user" v-bind:key="userpro.name">{{ userpro.name}}</span><span class="caret"></span></a>
                    <ul class="dropdown-menu" style="left: auto;right: 0;">
                      <li><router-link to="/dashboard">Dashboard</router-link></li>
                      <li><router-link to="/profile">Profile</router-link></li>
                        <li><a @click="logout">Logout</a></li>
                    
                    </ul>
                    </li>
                    <li v-show="!user"><router-link to="/login"><i class="fa fa-user"></i>Login</router-link></li> 
                    
                </ul>
                </div>
            </div>
        </div>
        </div>
        </div><div class="header">
  <div class="container">
    <div class="row">
      <div class="col-md-2 col-xs-8">
        <div class="logo-sec">
          <router-link to="/"><img src="./assets/img/logo.png"></router-link>
        </div>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-4 col-xs-12">
        <!-- <div class="input-group top-search-sec">
          <input type="search" class="form-control" id="search_cate" name="search_cate" value="" placeholder="What are you looking for?">
          <span class="input-group-btn">
            <button class="btn btn-success" type="button">Search</button>
          </span>
          <div id="ab">
            <ul class="dropdown-menu ul">
              <li><a href="#">AC Repair Service</a>
                <ul>
                  <li><a href="#">AC Service @399*</a></li>
                  <li><a href="#">Gas Charging @1999*</a></li>
                  <li><a href="#">Installation Window AC @499*</a></li>
                  <li><a href="#">Uninstallation Window AC @399*</a></li>
                  <li><a href="#">Installation Split AC @1599*</a></li>
                  <li><a href="#">Uninstallation Split AC @599</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
      <div class="col-md-5 col-xs-12">
        <nav id='cssmenu'>
          <div id="head-mobile"></div>
          <div class="button"></div>
          <ul>
            <li><router-link to="/"> Home</router-link></li>
            <li><router-link to="/connect">Connect</router-link></li>
            <li><router-link to="/trade">Trade</router-link></li>
            <li><router-link to="/educate">Educate</router-link></li>
            <!-- <li><router-link to="/car-scrappage">Car Scrappage</router-link></li> -->
            <!-- <li><a>Connect <span class="caret"></span></a>
              <ul>
                <li><a href="#">Money</a></li>
                <li><a href="#">Manpower</a></li>
                <li><a href="#">Machine</a></li>
                <li><a href="#">Material</a></li>
              </ul>
            </li> -->
            <!-- <li><a href="#">Sell</a></li> -->
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
        <router-view></router-view>
        <footer>
  <div class="content_footer_wrapper">
    <div class="content_footer_inner">
      <div class="container">  
        <div class="row">
          <div class="col-md-3 col-lg-3 column">
            <h5>Important Link</h5>
            <ul class="brdr2px">
              <li><i class="fa fa-angle-right"></i><router-link to="/"> Home</router-link></li>
              <li><i class="fa fa-angle-right"></i><router-link to="/about-us"> About Us</router-link></li>
              <li><i class="fa fa-angle-right"></i><router-link to="/contact-us"> Contact Us</router-link></li>
               <li><i class="fa fa-angle-right"></i><router-link to="/privacy-policy"> Privacy Policy</router-link></li>
              <li><i class="fa fa-angle-right"></i><router-link to="/terms-of-use">Terms of Use</router-link></li>
              <!-- <li><i class="fa fa-angle-right"></i><a href="#"> Careers</a></li> -->
            </ul>
          </div>
          <div class="col-md-3 col-lg-3 column">
            <h5>Services</h5>
            <ul class="brdr2px">
              <li><i class="fa fa-angle-right"></i><router-link to="/trade"> Trade</router-link></li>
              <li><i class="fa fa-angle-right"></i><router-link to="/connect"> Connect</router-link></li>
              <li><i class="fa fa-angle-right"></i><router-link to="/educate"> Educate</router-link></li>
              <!-- <li><i class="fa fa-angle-right"></i><router-link to="/car-scrappage">Car Scrappage</router-link></li> -->
            </ul>
          </div>
         <!--  <div class="col-md-2 col-lg-2 column">
            <h5>Policy </h5>
            <ul class="brdr2px">
             
              <li><i class="fa fa-angle-right"></i><a href="#"> Sitemap</a></li>
              <li><i class="fa fa-angle-right"></i><a href="#"> Disclaimer</a></li>
            </ul>
          </div> -->
          <div class="col-md-3 col-lg-3 column">
            <div class="ffsocial">
              <ul class="brdr2px">
                <h5>Follow Us</h5>
                <li><a href="https://www.facebook.com/Grownet-102001492377548/" target="_blank"><i class="fa fa-facebook"> </i> Facebook</a></li>
                <li><a href="https://twitter.com/IndiaGrownet" target="_blank"><i class="fa fa-twitter"> </i> Twitter</a></li>
                <li><a href="https://www.instagram.com/grownet.in/" target="_blank"><i class="fa fa-instagram"> </i> Instagram</a></li>
                
                <li><a href="https://www.linkedin.com/in/grownet-india-6b8118233/" target="_blank"><i class="fa fa-linkedin"></i> Linkedin</a></li>
              </ul>
            </div>
          </div>
           <div class="col-md-3 col-lg-3 column">
             <h5>Download Our App</h5>
            <ul>
              <li><a href="https://play.google.com/store/apps/details?id=com.grownet" target="_blank"><img src="./assets/img/android.png"></a></li>
              <li><a href="https://apps.apple.com/us/app/id1578617847" target="_blank"><img src="./assets/img/ios.png"></a></li>
            </ul>
           </div>
        </div>
      </div>
    </div>
    <div class="copyright-container text-center">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="powered">Copyright © 2022 Grownet. All rights reserved. Design & Developed by Invispa IT Solution Pvt. Ltd.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
  </div>
</template>

<script>
 import { mapGetters, mapActions } from "vuex";
export default {
  name: 'App',
  metaInfo: {
      titleTemplate: '%s | The Grownet',
      meta: [
     { name: 'description', content:  "Grownet is a Multipurpose Application for Industries dealing in Plastic and Polymer where you can find best Resources for your business." }
    ]
    },
  data(){
    return{
      imagePath: process.env.VUE_APP_URL,
      user1: ''
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isLoggedIn : function(){ return this.$store.getters.isAuthenticated}
  },

  mounted() {
    if (localStorage.getItem("authToken")) {
      this.user1 = this.getUserData();
      console.log(this.getUserData());
    }
  },
  created() {
    mapActions("auth", ["getUserData"])
    if (localStorage.getItem("authToken")) {
      this.user1 = this.getUserData();
      console.log(this.getUserData());
    }
  },

  methods: {
    ...mapActions("auth", ["sendLogoutRequest", "getUserData"]),

    logout() {
      this.sendLogoutRequest().then(() => {
            this.$router.push({ name: "login" });
      });
      //this.$router.push({ name: "login" });
    }
  }
};

</script>

