<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		<div class="filter">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="fltr">
          <i class="fa fa-sliders"></i> <span>Filter</span>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Country <i class="fa fa-angle-down"></i>
          </button>
          <form class="dropdown-menu" style="width: 320px;">
            <ul class="dm">
              <li class="dropdown-actions">
                <button type="button" @click="filter" class="btn btn-success">Apply</button>
                <button type="Reset" @click="filter" class="btn btn-danger">Reset</button>
              </li>
              <li v-for="country in countries" v-bind:key="country.id"><label><input type="checkbox" :id="country.id" v-bind:value="country.id" v-model="country_ids">{{ country.name }}</label></li>
            </ul>
          </form>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Purpose <i class="fa fa-angle-down"></i>
          </button>
          <form class="dropdown-menu" style="width: 320px;">
            <ul class="dm">
              <li class="dropdown-actions">
                <button type="button"  @click="filter" class="btn btn-success">Apply</button>
                <button type="Reset" class="btn btn-danger" @click="filter">Reset</button>
              </li>
              <li v-for="purpose in purposes" v-bind:key="purpose.name"><label><input type="checkbox" :id="purpose.name" v-bind:value="purpose.name" v-model="purposes_ids">{{ purpose.name }}</label></li>
             
            </ul>
          </form>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Funding <i class="fa fa-angle-down"></i>
          </button>
          <form class="dropdown-menu" style="width: 320px;">
            <ul class="dm">
              <li v-for="funding in fundings" v-bind:key="funding.name"><label><input type="checkbox" :id="funding.name" v-bind:value="funding.name" v-model="fundings_ids">{{ funding.name }}</label></li>
              <li role="separator" class="divider"></li>
              <li class="dropdown-actions">
                <button type="button" @click="filter" class="btn btn-success">Apply</button>
                <button type="Reset" class="btn btn-danger" @click="filter">Reset</button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Money</span></h2>
          
        </div>
      </div>
    </div>
    <div class="row" v-if="finacial_institutions.length">
      <div class="col-md-3" v-for="(finacial_institution , index) in finacial_institutions" v-bind:key="index">
        <div class="grid-item" v-if="finacial_institution">
          <div class="imgholder ">
            <a @click="viewProduct(finacial_institution.id)">
              <img :src="imgPath+finacial_institution.logo">
            </a>
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ finacial_institution.name }} </span>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid">{{ finacial_institution.type_of_funding }} {{ finacial_institution.purpose }}</div>
              <div class="product-location-grid">{{ finacial_institution.city }}, {{ finacial_institution.countrys.name }}</div>
            </div>
          </div>
        </div>
        <div v-else>Record Not Found</div>
      </div>
    </div>
    <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            finacial_institutions: [],
            fundings: [],
            purposes: [],
            countries: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            param : '',
            country_ids:[],
            purposes_ids:[],
            fundings_ids:[],
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/financial-institution-list")
          .then(response => {
            this.finacial_institutions = response.data.finacial_institutions;
        })
        .finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/funding-type")
        .then(response => {
            this.fundings = response.data.funding_type;
        });
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/funding-purpose")
        .then(response => {
            this.purposes = response.data.funding_purpose;
        });
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/countries")
        .then(response => {
            this.countries = response.data.countries;
        });
    },
    methods: {
            viewProduct(id) {
              localStorage.setItem("finacialInstitution", id);
              this.$router.push({ name: "FinacialInstitutionDetails" });
            },
            filter() {
              this.finacial_institutions= []
              /*this.ab = this.country_ids.join(',')
              this.category_id = this.category_ids.join(',')
              this.condition_id = this.condition_ids.join(',')*/
              this.axios
          .get(process.env.VUE_APP_API_URL+"api/financial-institution-filter?country_ids="+this.country_ids+"&funding="+this.fundings_ids.join(',')+"&purpose="+this.purposes_ids.join(','))
          .then(response => {
              this.finacial_institutions = response.data.finacialInstitutions;
          })
          .finally(() => this.loading = false);
              }
        }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>