<template>
    <div>
      <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
<div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Price List</span></h2>
          <div class="container">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-2"><label>Select Company</label></div>
            <div class="col-md-6">
                <select name="search" class="form-control" v-model="brand" @change="brandFilter()">
                  <option value="All">All</option>
                  <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
                </select>
            </div>
            
            <div class="col-md-2"></div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="ratelists.length">
      <div class="col-md-4" v-for="ratelist in ratelists" :key="ratelist.file">
        <div class="grid-item" v-if="ratelist">
            <a :href="imagePath+ratelist.list" download="">
          
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name"> <img :src="imagePath+ratelist.brand.image" style="height:70px"> {{ ratelist.name }} <i class="fa fa-download" style="font-size:23px;color:white;background-color:green;border-radius:50%;border:2px green;width: 45px;height:41px;padding: 10px;"></i> </span>

            </div>
            
          </div>
            </a>
        </div>
        <div v-else>Record Not Found</div>
      </div>
    </div>
    <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ratelists1: [],
            ratelists: [],
            brands: [],
            imagePath: process.env.VUE_APP_API_URL,
            loading: false,
            brand: 'All'
        }
    },
    created() {
        this.loading = true
        this.axios
            .get(process.env.VUE_APP_API_URL+"api/rate-list")
            .then(response => {
              this.ratelists1 = response.data['ratelist'];
              this.ratelists = this.ratelists1;
            })
            .finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/brands")
        .then(response => {
            this.brands = response.data['brands'];            
        });
    },
    methods: {
        brandFilter() {
          if(this.brand=='All')
          {
            this.ratelists = this.ratelists1;
          }
          else
          {
            this.ratelists = this.ratelists1.filter(ratelist => {
              return ratelist.brand_id.includes(this.brand)
            })
          }
      },
    }
    
};
</script>