<template>
    <div>
        <div class="main bgF6F9FC">
            <div class="container">
                
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-6" style="text-align: left">
                        <div class="home-tit1">
                          <h2><span style="font-weight: 700">Material</span> </h2>
                          
                      </div>
                     </div>
                        <div class="col-md-6" style="text-align:right">
                            <span  @click="showDialog = true">{{city}}<i class="fa fa-pencil"></i></span><br>
                            <button @click="downloadList()" class="btn btn-success">Download Price List</button>
                        </div>
                  </div>
              </div>
              <div class="row">
              <div class="col-md-12">     
                <div class="tab-content">
                    <div class="tab-pane active" id="home-v">
                        <div class="col-md-3" v-for="brand in brands" :key="brand.id">
                            <a @click="viewMaterial(brand.id)">
                                <div class="cat-itm">
                                    <div class="cat-img">
                                        <img :src="imagePath+brand.image" alt="" class="img-responsive">
                                    </div>
                                    <div class="cat-tt">
                                        <h4>{{ brand.name}}</h4>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <md-dialog :md-active.sync="showDialog">
                        <!-- <md-dialog-actions>
                            <md-button class="md-primary" @click="showDialog = false">X</md-button>
                        </md-dialog-actions> -->

                        <form style="padding: 20px;">
                          <label for="movie">Select City</label>
                          <select class="form-control" v-model="city" required  @change="cityFilter()">
                            <option value="" selected="">Select City</option>
                            <option v-for="city in cities" :key="city.name" :value="city.name">{{city.name}}</option>
                        </select>
                    </form>
                </md-dialog>
            </div>
        </div>
    </div>
</div>
</div>

</div>
</template>

<script>
export default {

    data() {
        return {
            brands: [],
            imagePath: process.env.VUE_APP_API_URL,
            showDialog: false,
            cities: [],
            city: ''
        }
    },
    created() {
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/brands")
        .then(response => {
            this.brands = response.data['brands'];            
        });
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/connect-material-cities")
        .then(response => {
            this.cities = response.data['cities'];
            
        });
        this.city = sessionStorage.getItem("materialCity");
        if(! this.city)
        {
            this.showDialog=true;
        }
        
    },
    methods: {
        viewMaterial(id) {
          sessionStorage.setItem("material", id);
          this.$router.push({ name: "Material" });
      },
      downloadList() {
          this.$router.push({ name: "MaterialPriceList" });
      },
      cityFilter() {
          sessionStorage.setItem("materialCity", this.city);
          this.showDialog=false
      }
  }
}
</script>