<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
        <div class="form-x">
            <div class="container">    
                <div class="row">
                    <div class="bg-clr">
                        <div class="col-md-6">
                            <img src="../assets/img/login.png" class="img-responsiv">
                        </div>
                        <div class="col-md-6">
                            <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="imgcontainer">
                                    <h3>LOGIN WITH OTP </h3>
                                </div>
                            <form v-on:submit.prevent="login">
                                
                                <div class="form-group">
                                    <label>Email / Mobile</label>
                                    <input type="text" name="v" placeholder="Enter Email/Mobile"  class="form-style" :class="{ 'is-invalid': errors.username }" 
                                        v-model="details.username" id="username" required=""/>
                                        <div class="alert alert-danger" v-if="errors.username">
                        {{ errors.username[0] }}
                    </div>
                                </div>
                                    <center><input type="submit" name="next" class="pulse btnlogin" value="Login" /></center>
                                    <br>
                                    <p>Don't have an account <router-link to="/register" style="color:blue">Register with us free </router-link> </p>
                            </form>
                        </div>
                    </div>
                </div>  
            </div>      
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
//import { mapGetters, mapActions } from "vuex";
export default {
    name: "Register",
    data() {
        return {
            details: {
        username: null
      },
      loading: false,
      success:null,
      errors:{

            },
        }
    },
    
  methods: {
    login() {
    this.loading = true
    this.success = null
    this.errors = {}
      this.axios
                    .post(process.env.VUE_APP_API_URL+"api/login-withotp", this.details)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                            this.success = response.data.msg
                        }else{
                            this.success = response.data.msg
                            sessionStorage.setItem("loginMobile", this.details.username);
                        this.$router.push({ name: "LoginVerify" });
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
    }
  }
};
</script>