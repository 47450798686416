<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>My Injection Molding Machines Details</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                   <div class="main" v-for="(injectionMachine , index) in injectionMachines" v-bind:key="index">
  <div class="">
    <div class="row">
      <div class="col-md-5">
        <VueSlickCarousel :arrows="true" :dots="true" :autoplay="true" :autoplaySpeed="2000">
          <div><img :src="imgPath+injectionMachine.image1"/></div>
          <div v-if="injectionMachine.image2"><img :src="imgPath+injectionMachine.image2"/></div>
          <div v-if="injectionMachine.image3"><img :src="imgPath+injectionMachine.image3"/></div>
          <div v-if="injectionMachine.image4"><img :src="imgPath+injectionMachine.image4"/></div>
        </VueSlickCarousel>
      </div>
      <div class="col-md-7">
        <div class="packs-contant">
          <h3 style="margin-top:0;">{{ injectionMachine.name }} <button class="btn btn-success" @click="editMachine(injectionMachine.id)"><i class="fa fa-pencil"></i> Edit</button></h3>
          <table class="table table-bordered">
            <tbody>
            <tr>
              <th width="40%">Reference ID:</th>
              <td>{{ injectionMachine.id }}</td>
            </tr>
             <tr>
              <th width="40%">Clamp Force</th>
              <td>{{ injectionMachine.machine_size }} Ton</td>
            </tr>
             <tr>
              <th>Shot Capacity</th>
              <td>{{ injectionMachine.shot_wt }} gms</td>
            </tr>
            <tr>
              <th>Platen Size(mm)</th>
              <td>Length: {{ injectionMachine.platen_size_length }} Width: {{ injectionMachine.platen_size_width }}</td>
            </tr>
            <tr>
              <th>Tie Bar Distance(mm)</th>
              <td>Length: {{ injectionMachine.tie_bar_distance_length }} Width: {{ injectionMachine.tie_bar_distance_width }}</td>
            </tr>
            
           <!--  <tr>
              <th>Machine Drive </th>
              <td>{{ injectionMachine.machine_drive }}</td>
            </tr>
             <tr> 
              <th>Machine Operation</th>
              <td>{{ injectionMachine.machine_operation }}</td>
            </tr>
            <tr> 
              <th>Raw Material Feed</th>
              <td>{{ injectionMachine.raw_material_feed }}</td>
            </tr> -->
            <tr> 
              <th>Make</th>
              <td>{{ injectionMachine.make }}</td>
            </tr>
            <tr> 
              <th>Mfg Year</th>
              <td>{{ injectionMachine.mfg_year }}</td>
            </tr>
            <tr> 
              <th>Spare Capacity</th>
              <td>{{ injectionMachine.spare_capacity }} Hours/Month</td>
            </tr>
            <tr> 
              <th>Country</th>
              <td v-if="injectionMachine.country">{{ injectionMachine.country.name }}</td>
              <td v-else></td>
            </tr>
            <tr> 
              <th>State/Province</th>
              <td>{{ injectionMachine.state }}</td>
            </tr>
            <tr> 
              <th>City</th>
              <td>{{ injectionMachine.city }}</td>
            </tr>
            <tr> 
              <th>Pincode</th>
              <td>{{ injectionMachine.zip_code }}</td>
            </tr>
            <h3>Description</h3>
          <p>{{ injectionMachine.description }}</p>
           
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './SideBar.vue';
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    components: {
        'sidebar': SideBar,
        VueSlickCarousel
    },
        
    data() {
        return {
            injectionMachines: {},
            imgPath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/my-injection-modling-machine-details?id="+sessionStorage.getItem("MyInjectionMachine"))
        .then(response => {
            this.injectionMachines = response.data.injection_modling_machine;
        })
        .finally(() => this.loading = false);
    },
    methods: {
            editMachine(id) {
              sessionStorage.setItem("MyInjectionMachine", id);
              this.$router.push({ name: "InjectionMachineEdit" });
            }
        }
};
</script>
<style type="text/css">
    .top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  color: red;
  text-align: right;
}
</style>