<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		<div class="adult-title"></div>
<div class="main" v-for="(job , index) in jobs" v-bind:key="index">
  <div class="container wrapper">
    <div class="row">
      
      <div class="col-md-7">
        <div class="packs-contant">
          <!-- <h3 style="margin-top:0;">{{ job.company_name }}</h3> -->
          <table class="table table-bordered">
            <tbody>
             <!-- <tr>
              <th width="30%">Category</th>
              <td><span v-if="job.executive_manpower_category">{{ job.executive_manpower_category.name }}</span></td>
            </tr> -->

             <tr>
              <th width="30%">Job Title</th>
              <td>{{ job.title }}</td>
            </tr>
            <tr>
              <th width="30%">Company Name</th>
              <td>{{ job.company_name }}</td>
            </tr>
            <tr>
              <th width="30%">Salary</th>
              <td>Rs. {{ job.salary_from }} To Rs. {{ job.salary_to }}</td>
            </tr>
            <tr>
              <th>Type</th>
              <td>{{ job.job_type }}</td>
            </tr>
            <tr>
              <th>Minimum Education</th>
              <td></td>
            </tr>
            <tr>
              <th>Experience</th>
              <td>{{ job.experience }}</td>
            </tr>
            <tr>
              <th>Timing</th>
              <td></td>
            </tr>
            <tr>
              <th>Working Days</th>
              <td></td>
            </tr>
            <tr>
              <th>Opening</th>
              <td>{{ job.opening }}</td>
            </tr>
             <tr>
              <th>Address</th>
              <td>{{ job.address }}</td>
            </tr>
             <tr>
              <td colspan="2">{{ job.details }}</td>
            </tr>
            <tr>
              <td colspan="2">
            <button v-on:click="showInfo = true" class="btn btn-success">View Contact Info</button>
            &nbsp;
                  
            </td>
            </tr>
            </tbody>
          </table>
            <table class="table table-bordered" >
              <tbody>
            <tr>
              <th width="30%">Name </th>
              <td>{{ job.sellername }}</td>
            </tr>
            
            <tr v-if="showInfo">
              <th>Company Mobile </th>
              <td>{{ job.sellermobile }}</td>
            </tr>
            <tr v-if="showInfo">
              <th>Company Email </th>
              <td>{{ job.selleremail }}</td>
            </tr>
             <tr>
              <td colspan="2">
            <button @click="applyJob(job.id)" class="btn btn-success">Apply For Job</button>
            &nbsp;
                  
            </td>
            </tr>
            </tbody>
          </table>
          
        </div>
      </div>
    </div>
  </div>
</div>

<div class="cl"></div>
	</div>
</template>
<script>

export default {
  
   mounted: function () {
  this.timer = setInterval(() => {
    this.getMessage()
  }, 5000)
},
	data() {
        return {
            jobs: [],
            categories: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            showInfo: false,
            open: false,
            timer: null,
            
            listing_id: sessionStorage.getItem("jobId")
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/job-details?id="+sessionStorage.getItem("jobId"))
        .then(response => {
            this.jobs = response.data.jobs;
            console.log(this.jobs);
        })
        .finally(() => this.loading = false);
    },
    methods: {
            
            applyJob(id) {
              this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/job-apply?job_id="+id)
        .then(response => {
            console.log(response.data);
        })
        .finally(() => this.loading = false);
              this.$router.go();
            },
        },
        
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
  .usrimg { height: 90px !important; }
</style>