<template>
    <div>
        
<div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12">     
        <div class="tab-content">
          <div class="tab-pane active" id="home-v" v-for="executive in executives" :key="executive.id">
            <div class="col-md-4">
              <div class="cnd-main">
                <div class="cad-img">
                  <img :src='imagePath+executive.image' class="img-responsive">
                </div>
                <div class="cad-cnt">
                  <div class="cnd-tt">
                    <h3>{{ executive.name }}</h3>
                    <span v-if="showInfoButton">
                    <button v-on:click="showInfo = true;showInfoButton = false" class="btn btn-success">View Contact Details</button></span>
                  </div>
                  <ul v-if="showInfo">
                    <li><i class="fa fa-mobile"></i> {{ executive.mobile }}</li>
                    <li><i class="fa fa-envelope"></i> {{ executive.email }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div >
        <div class="packs-contant">
          <h3 style="margin-top:0;">Candidate Details</h3>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">About Me</td>
              </tr>
             <tr >
              <td colspan="2">{{ executive.about }}</td>
            </tr>
             <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Category</td>
              </tr>
             <tr >
              <td colspan="2">{{ executive.executive_manpower_categories }}</td>
            </tr>
             <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Key Skill(s)</td>
              </tr>
             <tr >
              <td colspan="2">{{ executive.key_skills }}</td>
            </tr>
             <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Highest Qualification</td>
              </tr>
             <tr>
              <td colspan="2">{{ executive.highest_qualification }}</td>
            </tr>
            
            <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Specialization</td>
              </tr>
                           <tr >
              <td colspan="2">{{ executive.executive_manpower_specializations }}</td>
            </tr>
              <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Past Company Details</td>
              </tr>
             <tr >
              <td colspan="2"><b>Total Work Experience</b> {{ executive.total_experience }} Years</td>
            </tr>

            <tr v-for='exp in JSON.parse(executive.past_experience)' :key="exp.from">
              <td colspan="2">
                <table width="100%" style="border:0px" class="table">
                  <tr>
                    <td><b>{{ exp.company_name}}</b> </td>
                  </tr>
                  <tr>
                    <td><b>Designation:</b></td>
                  </tr>
                  <tr >
                    <td><b>From:</b> {{ exp.from}} <b>To:</b> {{ exp.to}}</td>
                  </tr>
                  <tr>
                    <td>{{ exp.description}}</td>
                  </tr>
                  <tr></tr>
                </table>
              </td>
            </tr>
            <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Address</td>
              </tr>
              <tr >
              <td colspan="2">{{ executive.address }}</td>
            </tr>
            <tr>
              <th>City</th>
              <td>{{ executive.city}} </td>
            </tr>
            
            <tr> 
              <th>State</th>
              <td>{{ executive.state}}</td>
            </tr>
            <tr> 
              <th>Pincode</th>
              <td>{{ executive.pin_code }}</td>
            </tr>
            <tr> 
              <th>Country</th>
              <td>{{ executive.country.name }}</td>
            </tr>
            
            </tbody>
          </table>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
</template>

<script>
export default {
 props: ['details'],
    data() {
        return {
            executives: [],
            imagePath: process.env.VUE_APP_API_URL,
            showInfo:false,
            showInfoButton:true
        }
    },
    created() {
        this.axios
            .get(process.env.VUE_APP_API_URL+'api/manpower-executive-details?id='+localStorage.getItem("executive"))
            .then(response => {
                this.executives = response.data.manpower_executive_details;
            });
    }
}
</script>