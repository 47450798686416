<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>My Transaction</h3>
                        </div>
                            <div class="profile-details" >
                                <div v-if="transactions.length">
      <div class="col-md-12" v-for="(transaction , index) in transactions" :key="index">
        <div class="grid-item" v-if="transaction">
            <div class="product_footer">
            <div class="grid_product_header" style="display: flex;justify-content: space-between;">
              <span class="name"><i class="fa fa-money" style="font-size: 50px;color: red;width: 50px;"></i></span>
              <span class="name" style="width: 100%;text-align: left;padding: 0 15px;"> {{ transaction.description }}<br> {{ transaction.transaction_date || formatDateTime }} </span>
              <span v-if="transaction.type=='In'" style="color:green">+{{ transaction.token }}</span>
              <span v-else style="color:red">-{{ transaction.token }}</span>
              <span>
                <i class="fa fa-inr" style="font-size:23px;color:white;background-color:green;border-radius:50%;border:2px green;width: 45px;height:41px;padding: 10px;"></i>
              </span>

            </div>
            
          </div>
        </div>
        <div v-else>Record Not Found</div>
      </div>
    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './../SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            errors:{

            },
            success:null,
            loading: false,
            transactions: []
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/token-transaction")
        .then(response => {
            this.transactions = response.data.transactions;
        }).finally(() => this.loading = false);
        
    }
}
</script>