<template>
    <div>
      <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
<div class="main">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Know Your Car Status</span></h2>
          <img src="../assets/img/car_scrappage4.jpeg" style="height:100px">
          <br>
          <div class="container">
            <div class="row">
              <form @submit.prevent="carStatus">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <label>Enter Your Vechile No</label>
                  <input type="text"  name="search" class="form-control" placeholder="Vechile No e.g. DL3CAB1111" required="">
              </div>
              <br>
              <div class="col-md-1"><button type="submit" class="btn btn-success">SUBMIT</button></div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <router-link to="/car-scrappage">>> Back</router-link>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
  data() {
        return {
            imgPath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    methods: {
            carStatus() {
                this.loading = true
                this.$router.push({ name: "CarStatus" });
            }
        }
};
</script>