<template>
    <div>
        <div v-if="loading">
            <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
                <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="cl"></div>
        <div class="form-x">
            <div class="container">	   
                <div class="row">
                    <div class="bg-clr">
                        <div class="col-md-6">
                            <br><br>
                            <img src="../assets/img/login.png" class="img-responsive">
                        </div>
                        <div class="col-md-6">
                            <div class="login-form">
                                <br>
                                <div class="alert alert-success text-center" v-if="success!=null">
                                    {{ success }}
                                </div>
                                <div class="imgcontainer">
                                    <h3>Login </h3>
                                </div>
                                <form v-on:submit.prevent="login">
                                    <div class="form-group">
                                        <label>Email / Mobile</label>
                                        <input
                                        type="text"
                                        class="form-style"
                                        :class="{ 'is-invalid': errors.email }"
                                        name="username"
                                        id="username"
                                        v-model="details.username"
                                        placeholder="Enter email"
                                        />
                                        <div class="invalid-feedback" v-if="error.email">
                                          {{ error.email[0] }}
                                      </div>
                                  </div>
                                  <div class="form-group">
                                    <label>Password</label>
                                    <input
                                    type="password"
                                    class="form-style"
                                    :class="{ 'is-invalid': errors.password }"
                                    name="password"
                                    id="password"
                                    v-model="details.password"
                                    placeholder="Password"
                                    />
                                    <div class="invalid-feedback" v-if="error.password">
                                      {{ error.password[0] }}
                                  </div>

                              </div>
                              <div class="form-group">
                                        <!-- <label>
                                            <input type="checkbox" class="rememberme" id="" > Remember me
                                        </label> -->
                                        <!-- <label class="pull-right">
                                            <a href="#">Forgot password?</a>
                                        </label> -->
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-md-12">
                                            <center>
                                                <button class="pulse btnlogin" id="btnlogin" type="submit">Login</button>
                                            </center><br>
                                            <router-link to="/login-with-otp" class="login">Login With OTP</router-link>
                                            <p>Don't have an account <router-link to="/register" style="color:blue">Register with us free </router-link> </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>	
        <div class="cl"></div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "Home",
    data() {
        return {
            details: {
                username: null,
                password: null
            },
            loading: false,
            success:null,
            error:{

            },
        }
    },
    computed: {
        ...mapGetters(["errors"])
    },

    mounted() {
        this.$store.commit("setErrors", {});
    },

    methods: {
        ...mapActions("auth", ["sendLoginRequest"]),

        login: function() {
            this.loading = true
            this.success = null
            this.error = {}
            this.axios.post(process.env.VUE_APP_API_URL+"api/login", this.details)
            .then(response => 
            {
                if(response.data.error==true)
                {
                    if(response.data.validate)
                    {
                        this.error = response.data.validate
                    }
                    this.success = response.data.msg
                }else
                {
                    this.sendLoginRequest(this.details).then(() => {
                        this.$router.push({ name: "Home" });
                    });
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.loading = false)

        }
    }
};
</script>
