<template>
    <div>
      <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
<div>
  <img src="../assets/images/educate.jpg" alt="Educate">
    </div>
<div class="main">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
          <div class="home-tit">
            <h2><span>Educate</span></h2>
            <!-- <p>Get the best suppliers, manufacturers and professionals.</p> -->
          </div>
          <div class="home-city">
            <ul>
              <li>
                <div class="hcity">
                  <div>
                    <img src="../assets/img/products/know your polymer.jpg" alt="Machine Manufacturers">
                  </div>
                  <div>
                    <img src="../assets/img/products/know your polymer.jpg" alt="Machine Manufacturers">
                    <h4>Know Your Polymer</h4>
                  </div>
                  <router-link to="/know-your-polymer" class="fclick">&nbsp;</router-link>
                </div>
              </li>
              <li>
                <div class="hcity">
                  <div>
                    <img src="../assets/img/products/know your composition.jpg" alt="Packaging and Labeling">
                  </div>
                  <div>
                    <img src="../assets/img/products/know your composition.jpg" alt="Packaging and Labeling">
                    <h4>Know Your Product Composition</h4>
                  </div>
                  <router-link to="/know-your-product" class="fclick">&nbsp;</router-link>
                </div>
              </li>
              <li>
                <div class="hcity">
                  <div>
                    <img src="../assets/img/products/blog.jpg" alt="Oil, Lubricants, Grease">
                  </div>
                  <div>
                    <img src="../assets/img/products/blog.jpg" alt="Oil, Lubricants, Grease">
                    <h4>Blogs</h4>
                  </div>
                  <router-link to="/blogs" class="fclick">&nbsp;</router-link>
                </div>
              </li>
              <li>
                <div class="hcity">
                  <div>
                    <img src="../assets/img/products/question.jpg" alt="Electrical and Electronics">
                  </div>
                  <div>
                    <img src="../assets/img/products/question.jpg" alt="Electrical and Electronics">
                    <h4>Q & A</h4>
                  </div>
                  <router-link to="/qa" class="fclick">&nbsp;</router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
    
};
</script>