<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>My Profile</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <form @submit.prevent="updateProfile">
                                        <div class="form-group row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Name *</label>
                                                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.name }" v-model="profile.name" required>
                                                    <div class="invalid-feedback" v-if="errors.name">
                                                        {{ errors.name[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label>Country Code*</label>
                                                    <input type="text" required name="country_code" class="form-control" :class="{ 'is-invalid': errors.country_code }" v-model="profile.country_code">
                                                    <div class="invalid-feedback" v-if="errors.country_code">
                                                        {{ errors.country_code[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Mobile No.*</label>
                                                    <input type="text" required name="mobile" class="form-control" :class="{ 'is-invalid': errors.mobile }" v-model="profile.mobile">
                                                    <div class="invalid-feedback" v-if="errors.mobile">
                                                        {{ errors.mobile[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Email Id</label>
                                                    <input type="email" name="email"  required class="form-control" :class="{ 'is-invalid': errors.email }" v-model="profile.email">
                                                    <div class="invalid-feedback" v-if="errors.email">
                                                        {{ errors.email[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Company Name</label>
                                                    <input type="text" name="company_name"  v-model="profile.company_name" class="form-control" :class="{ 'is-invalid': errors.company_name }">
                                                    <div class="invalid-feedback" v-if="errors.company_name">
                                                        {{ errors.company_name[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Address</label>
                                                    <input type="text" name="address"  class="form-control" :class="{ 'is-invalid': errors.address }" v-model="profile.address">
                                                    <div class="invalid-feedback" v-if="errors.address">
                                                        {{ errors.address[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Country</label>
                                                    <select class="form-control" :class="{ 'is-invalid': errors.country_id }" name="country_id" v-model="profile.country_id">
                                                        <option value="">Select</option>
                                                        <option v-for="country in countries" v-bind:key="country.id" :value="country.id" :selected="1">{{ country.name }}</option>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.country_id">
                                                        {{ errors.country_id[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>State</label>
                                                    <input type="text" name="state"  class="form-control" :class="{ 'is-invalid': errors.state }" v-model="profile.state">
                                                    <div class="invalid-feedback" v-if="errors.state">
                                                        {{ errors.state[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                           <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>City</label>
                                                    <input type="text" name="city"  class="form-control" :class="{ 'is-invalid': errors.city }" v-model="profile.city">
                                                    <div class="invalid-feedback" v-if="errors.city">
                                                        {{ errors.city[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="submit" value="Update Profile" class="btn btn-info">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            profile: {},
            countries: [],
            errors:{

            },
            success:null,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/profile")
        .then(response => {
            this.profile = response.data.profile[0];
        })
        .finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/countries")
        .then(response => {
            this.countries = response.data.countries;
        });
    },
    methods: {
            updateProfile() {
                this.loading = true
                this.success = null
                this.errors = {}
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/profile-update", this.profile)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        window.location.reload();
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            }
        }
}
</script>