<template>
  <div>
    <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Scrap Car Dealers</span></h2>
          
        </div>
      </div>
    </div>
    <div class="row" v-if="carDealers.length">
      <div class="col-md-3" v-for="(carDealer , index) in carDealers" v-bind:key="index">
        <div class="grid-item" v-if="carDealer">
          <div class="hm-shop-imgsc ">
            <img src="../assets/img/usericon.png">
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ carDealer.dealer }} </span>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid">{{ carDealer.mobile }}<br> {{ carDealer.email }}</div>
              <div>{{ carDealer.address }} </div>
            </div>
          </div>
        </div>
        <div v-else>Record Not Found</div>
      </div>
    </div>
    <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
<div class="cl"></div>
  </div>
</template>
<script>
export default {
  data() {
        return {
            carDealers: [],
            fitness: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            param : ''
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/skrap-dealers?state_id="+sessionStorage.getItem('carDealerState')+"&city_id="+sessionStorage.getItem('carDealerCity'))
          .then(response => {
            console.log(response.data.skrapDealers);
            this.carDealers = response.data.skrapDealers;
        })
        .finally(() => this.loading = false);
        /*this.axios
        .get(process.env.VUE_APP_API_URL+"api/skrap-dealers?state_id="+sessionStorage.getItem('carDealerState')+"&city_id="+sessionStorage.getItem('carDealerCity'))
          .then(response => {
           this.fitness = response.data.fitnessCenters;
        })*/
    }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
  .hm-shop-imgsc {
    width: 28%;
    margin-right: 8px;
    margin-left: 10px;
    margin: auto;
    padding-top: 15px;
  }
</style>