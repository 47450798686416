<template>
    <div>
       <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div> 
<div class="main" v-for="(polymerDetails , index) in polymerDetails1" v-bind:key="index">
  <div class="container wrapper">
    <div class="row">
      <div class="col-md-5">
        <VueSlickCarousel :arrows="true" :dots="true" :autoplay="true" :autoplaySpeed="2000">
          <div><img :src="imagePath+polymerDetails.image1"/></div>
          <div v-if="polymerDetails.image2"><img :src="imagePath+polymerDetails.image2"/></div>
          <div v-if="polymerDetails.image3"><img :src="imagePath+polymerDetails.image3"/></div>
        </VueSlickCarousel>
      </div>
      <div class="col-md-7">
        <div class="packs-contant">
          <h3>{{ polymerDetails.name }}</h3>
          
          <h4 v-html="polymerDetails.question1 " style="font-weight:600"></h4>
          <p v-html="polymerDetails.answer1"></p>
          <br>
          <h4 v-html="polymerDetails.question2" style="font-weight:600"></h4>
          <p v-html="polymerDetails.answer2"></p>
          <br>
          <h4 v-html="polymerDetails.question3" style="font-weight:600"></h4>
          <p v-html="polymerDetails.answer3"></p>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: { VueSlickCarousel },
    data() {
        return {
            polymerDetails1: [],
            imagePath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
      this.loading = true
      this.axios
        .get(process.env.VUE_APP_API_URL+"api/polymers-details?id="+sessionStorage.getItem("polymersId"))
        .then(response => {
            this.polymerDetails1 = response.data.polymers;
        })
        .finally(() => this.loading = false);
    }
};
</script>
<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>