<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../assets/images/loader.gif" alt="" />
            </div>
        </div>
<div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>Finacial Institution Profile</h3>
                        </div>
                        <div v-if="finacial_institutions.length">
        <div class="main" v-for="(finacial_institution , index) in finacial_institutions" v-bind:key="index" >
  <div class="container wrapper">
    <div class="row">
      <div class="col-md-5">
        
        <VueSlickCarousel :arrows="true" :dots="true" :autoplay="true" :autoplaySpeed="2000">
          <div><img :src="imgPath+finacial_institution.logo"/></div>
        </VueSlickCarousel>
      </div>
      <div class="col-md-7">
        <div class="packs-contant">
          <h3 style="margin-top:0;">{{ finacial_institution.name }}</h3>
          <table class="table table-bordered">
            <tbody>
             <tr width="30%">
              <th>Type of Funding</th>
              <td>{{ finacial_institution.type_of_funding }}</td>
            </tr>
            <tr>
              <th>Purpose</th>
              <td>{{ finacial_institution.purpose }}</td>
            </tr>
            <tr>
              <th>Interest Rate</th>
              <td>{{ finacial_institution.interest_rate }} %</td>
            </tr>
            
            <!-- <tr>
              <th>Address</th>
              <td>{{ finacial_institution.city }}, {{ finacial_institution.state }}, {{ finacial_institution.countrys.name }}</td>
            </tr> -->
             <tr> 
              <th>Country</th>
              <td>{{ finacial_institution.countrys.name }}</td>
            </tr>
            
            </tbody>
          </table>
          <table class="table table-bordered">
              <tbody>
                <tr class="btn-success">View Contact Info</tr>
            <tr>
              <th><i class="fa fa-user"></i> {{ finacial_institution.sellername }}</th>
              
            </tr>
            <tr>
              <th><i class="fa fa-building-o"></i> {{ finacial_institution.sellercompany_name }}</th>
            </tr>
            <tr>
              <td><i class="fa fa-mobile"></i> {{ finacial_institution.sellermobile }}</td>
            </tr>
            <tr>
              <td><i class="fa fa-envelope"></i> {{ finacial_institution.selleremail }}</td>
            </tr>
            <tr>
              <td><i class="fa fa-map-marker"></i> {{ finacial_institution.address }}</td>
              
            </tr>
          </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
      </div>
      </div>
    </div>
  </div>
</div>

    </div>
</template>

<script>
  import SideBar from './SideBar.vue';
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: {
        'sidebar': SideBar,
        VueSlickCarousel
    },
 props: ['details'],
    data() {
        return {
            finacial_institutions: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            showInfo: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/profile")
        .then(response => {
            this.finacial_institutions = response.data.finacial_institution[0];
        })
        .finally(() => this.loading = false);
    }
}
</script>
<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>