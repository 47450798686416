<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>Product Add</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="invalid-feedback" v-if="errors.length">
                                        <div class="alert alert-danger">
                                            <ul>
                                                <li v-for="error in errors" :key="error">
                                                    {{ error }}
                                                </li>
                                            </ul>                    
                                        </div>
                                    </div>
                                    <form @submit.prevent="addProduct">
                                        <div class="form-group row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Product Type *</label>
                                                    <select class="form-control" :class="{ 'is-invalid': errors.product_type }" v-model="product.product_type" required>
                                                        <option value="" selected="">Select</option>
                                                        <option value="Virgin Polymers">Virgin Polymers</option>
                                                        <option value="Polymer Compounds">Polymer Compounds</option>
                                                        <option value="Reprocessed Polymer">Reprocessed Polymer</option>
                                                        <option value="Scrap Material">Scrap Material</option>

                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.category_id">
                                                        {{ errors.category_id[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Category *</label>
                                                    <select class="form-control" :class="{ 'is-invalid': errors.category_id }" v-model="product.category_id" required>
                                                        <option value="" selected="">Select</option>
                                                        <option v-for="productCategory in productCategories" :key="productCategory.id" :value="productCategory.id">{{productCategory.name}}</option>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.category_id">
                                                        {{ errors.category_id[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Condition *</label>
                                                    <select class="form-control" :class="{ 'is-invalid': errors.condition_id }" v-model="product.condition_id" required>
                                                        <option value="" selected="">Select</option>
                                                        <option v-for="productCondition in productConditions" :key="productCondition.id" :value="productCondition.id">{{productCondition.name}}</option>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.condition_id">
                                                        {{ errors.condition_id[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Name *</label>
                                                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.name }" v-model="product.name" required>
                                                    <div class="invalid-feedback" v-if="errors.name">
                                                        {{ errors.name[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Description*</label>
                                                    <input type="text" required name="description" class="form-control" :class="{ 'is-invalid': errors.description }" v-model="product.description">
                                                    <div class="invalid-feedback" v-if="errors.description">
                                                        {{ errors.description[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                           <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Quantity *</label>
                                                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.quantity }" v-model="product.quantity" required>
                                                    <div class="invalid-feedback" v-if="errors.quantity">
                                                        {{ errors.quantity[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Unit *</label>
                                                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.unit }" v-model="product.unit" required>
                                                    <div class="invalid-feedback" v-if="errors.unit">
                                                        {{ errors.unit[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Supply Type *</label>
                                                    <!-- <input type="text" class="form-control" :class="{ 'is-invalid': errors.supply_type }" v-model="product.supply_type" required> -->
                                                    <select class="form-control" :class="{ 'is-invalid': errors.supply_type }" v-model="product.supply_type" required>
                                                        <option value="" selected="">Select</option>
                                                        <option value="Regular">Regular</option>
                                                        <option value="One Time">One Time</option>
                                                        
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.supply_type">
                                                        {{ errors.supply_type[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Price Basis</label>
                                                    <!-- <input type="text" name="price_basis"  v-model="product.price_basis" class="form-control" :class="{ 'is-invalid': errors.price_basis }"> -->
                                                    <select class="form-control" :class="{ 'is-invalid': errors.price_basis }" v-model="product.price_basis" required>
                                                        <option value="" selected="">Select</option>
                                                        <option value="Exworks">Exworks</option>
                                                        <option value="FOB">FOB</option>
                                                        <option value="CIF">CIF</option>
                                                        <option value="FOB">FOB</option>

                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.price_basis">
                                                        {{ errors.price_basis[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Currency</label>
                                                    <input type="text" name="currency"  v-model="product.currency" class="form-control" :class="{ 'is-invalid': errors.currency }">
                                                    <div class="invalid-feedback" v-if="errors.currency">
                                                        {{ errors.currency[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Price</label>
                                                    <input type="text" name="price"  v-model="product.price" class="form-control" :class="{ 'is-invalid': errors.price }">
                                                    <div class="invalid-feedback" v-if="errors.price">
                                                        {{ errors.price[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Address</label>
                                                    <input type="text" name="address"  class="form-control" :class="{ 'is-invalid': errors.address }" v-model="product.address">
                                                    <div class="invalid-feedback" v-if="errors.address">
                                                        {{ errors.address[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>City</label>
                                                    <input type="text" name="city"  class="form-control" :class="{ 'is-invalid': errors.city }" v-model="product.city">
                                                    <div class="invalid-feedback" v-if="errors.city">
                                                        {{ errors.city[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>State</label>
                                                    <input type="text" name="state"  class="form-control" :class="{ 'is-invalid': errors.state }" v-model="product.state">
                                                    <div class="invalid-feedback" v-if="errors.state">
                                                        {{ errors.state[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Country</label>
                                                    <select class="form-control" :class="{ 'is-invalid': errors.country }" name="country" v-model="product.country">
                                                        <option value="">Select</option>
                                                        <option v-for="country in countries" v-bind:key="country.id" :value="country.id" :selected="1">{{ country.name }}</option>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.country">
                                                        {{ errors.country[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Target Country</label>
                                                    <select class="form-control" :class="{ 'is-invalid': errors.target_country }" name="country_id" v-model="product.target_country">
                                                        <option value="">Select</option>
                                                        <option v-for="country in countries" v-bind:key="country.id" :value="country.id" :selected="1">{{ country.name }}</option>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.target_country">
                                                        {{ errors.target_country[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Image1</label>
                                                    <input type="file" name="image1"  class="form-control" :class="{ 'is-invalid': errors.image1 }" @change="uploadImage" accept="image/*">
                                                    <div class="invalid-feedback" v-if="errors.image1">
                                                        {{ errors.image1[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Image</label>
                                                    <input type="file" name="state"  class="form-control" :class="{ 'is-invalid': errors.state }">
                                                    <div class="invalid-feedback" v-if="errors.state">
                                                        {{ errors.state[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Image</label>
                                                    <input type="file" name="state"  class="form-control" :class="{ 'is-invalid': errors.state }">
                                                    <div class="invalid-feedback" v-if="errors.state">
                                                        {{ errors.state[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Image</label>
                                                    <input type="file" name="state"  class="form-control" :class="{ 'is-invalid': errors.state }">
                                                    <div class="invalid-feedback" v-if="errors.state">
                                                        {{ errors.state[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="submit" value="Update Profile" class="btn btn-info">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            product: {
                image1:'',
                supply_type:'',
                price_basis:'',
                product_type:'',
                condition_id:'',
                category_id:''
            },
            productCategories: [],
            productConditions: [],
            countries: [],
            errors:{

            },
            success:null,
            loading: false,
            image1:null
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/product-categories")
        .then(response => {
            this.productCategories = response.data.product_categories;
        })
        .finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/product-conditions")
        .then(response => {
            this.productConditions = response.data.product_conditions;
        });
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/countries")
        .then(response => {
            this.countries = response.data.countries;
        });
    },
    methods: {
            addProduct() {
                this.loading = true
                this.success = null
                this.errors = {}
                var formData = new FormData();
                //formData.append(this.product);
                formData.append("image1", this.image1);
                //console.log(this.product);
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/trade-product-add", this.product)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        window.location.reload();
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            uploadImage (e) {
              let img = e.target.files[0]
              const reader = new FileReader()
            reader.readAsDataURL(img)
            reader.onload = e => {
                this.product.image1 = e.target.result
                //console.log(this.product.image1)
            }
              //this.product.image1= img
              
            }
        }
};
</script>