<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		<div class="adult-title"></div>
<div class="main" v-for="(material , index) in materials" v-bind:key="index">
  <div class="container wrapper">
    <div class="row">
      <div class="col-md-5">
        <VueSlickCarousel :arrows="true" :dots="true" :autoplay="true" :autoplaySpeed="2000">
          <div v-if="material.image1"><img :src="imgPath+material.image1"/></div>
          <div v-else><img :src="imgPath+material.brand.image"/></div>
        </VueSlickCarousel>
      </div>
      <div class="col-md-7">
        <div class="packs-contant" id="service">
          <h3>{{ material.name }}</h3>
          <table class="table table-bordered">
             <tr>
              <th>Category</th>
              <td>{{ material.product_category.name }}</td>
            </tr>
             <tr>
              <th>Brand</th>
              <td>{{ material.brand.name }} Ton</td>
            </tr>
            <tr>
              <th>Grade</th>
              <td>{{ material.grade }}</td>
            </tr>
            
            <tr>
              <th>Polymer Type </th>
              <td>{{ material.polymer_type }}</td>
            </tr>
             <tr> 
              <th>Density @ 23°C</th>
              <td>{{ material.density }}</td>
            </tr>
            <tr> 
              <th>Melt Flow Rate (230°C/2.16 kg)</th>
              <td>{{ material.melt_flow_rate }}</td>
            </tr>
            <tr> 
              <th>Notched Izod Impact Strength (23°C)</th>
              <td>{{ material.notched_izod }}</td>
            </tr>
            <tr> 
              <th>Tensile Strength at Yield (50 mm/min)</th>
              <td>{{ material.tensile_strength }}</td>
            </tr>
            <tr> 
              <th>Elongation at Yield (50 mm/min)</th>
              <td>{{ material.elongation }} Hours/Month</td>
            </tr>
            <tr> 
              <th>Flexural Modulus (1.3 mm/Min)</th>
              <td>{{ material.flexural_modulus }}</td>
            </tr>
            <tr> 
              <th>Heat Deflection Temperature (0.46 N/m2)</th>
              <td>{{ material.heat_deflection }}</td>
            </tr>
            <tr> 
              <th>Vicat Softening Point (10 N)</th>
              <td>{{ material.vicat_softening_point }}</td>
            </tr>
            <tr> 
              <th>Colour</th>
              <td>{{ material.colour }}</td>
            </tr>
            <tr> 
              <th>Condition</th>
              <td>{{ material.product_condition.name }}</td>
            </tr>
            <h3>Description</h3>
          <p>{{ material.description }}</p>
            
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: { VueSlickCarousel },
	data() {
        return {
            materials: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            showInfo: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/connect-materials-details?id="+sessionStorage.getItem("materialId"))
        .then(response => {
            this.materials = response.data.connect_materials;
        })
        .finally(() => this.loading = false);
    }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
  #service th,td {
    border-bottom: 1px solid #999;
  } 
</style>