<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>My Tool and Die Details</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                   <div class="main" >
  <div class="">
    <div class="row" v-if="toolMachine">
      <div class="col-md-3">
        <div class="imgholder ">

             <img :src="imgPath+toolMachine.logo">
             <div class="top-right">
               <div class="btn btn-warning" v-if="toolMachine.approved=='Pending'">
                Under Review
            </div>
            <div class="btn btn-success" v-if="toolMachine.approved=='Approved'">
                Published
            </div>
            </div>
        </div>
        <button class=""></button>
      </div>
      <div class="col-md-9">
        <div class="packs-contant">
            <p style="margin-top:0;">Reference ID: {{ toolMachine.id }}</p>
          <h3 style="margin-top:0;">{{ toolMachine.name }} 
            <button class="btn btn-success" @click="addMachine(toolMachine.id)"> Add Machine</button>&nbsp;
            <router-link to="/tool-die-machine-add" ><button class="btn btn-success"><i class="fa fa-pencil"></i> Edit</button></router-link>&nbsp;
            <button class="btn btn-danger" @click="deleteTool(toolMachine.id)" title="Delete"><i class="fa fa-trash"></i></button>
            
          </h3>
          <table class="table table-bordered">
            <tbody>
                <tr style="background-color:#5cb85c;color:white;">
                    <th colspan="2">Description</th>
                </tr>
                <tr>
                    <td colspan="2">
                        <p>{{ toolMachine.description }}</p>
                    </td>
                </tr>
             <tr style="background-color:#5cb85c;color:white;">
                    <th colspan="2">Tool Die Machines</th>
                </tr>
                <tr>
                    <td colspan="2"></td>
                </tr>
                <tr>
                    <td colspan="2">
                  <table class="table table-bordered">
                      <tr>
                            <th>Image</th>
                          <th>Name</th>
                          <th>Make</th>
                          <th>Model</th>
                          <th>Qty</th>
                          <th>Description</th>
                      </tr>
                      <tr v-for="(toolRoomMachine , index) in toolMachine.tool_room_machines" v-bind:key="index">
                        <td><img :src="imgPath+toolRoomMachine.image" height="100px" width="100px"></td>
                          <td>{{ toolRoomMachine.name }}</td>
                          <td>{{ toolRoomMachine.make }}</td>
                          <td>{{ toolRoomMachine.model }}</td>
                          <td>{{ toolRoomMachine.quantity }}</td>
                          <td>{{ toolRoomMachine.description }}</td>
                          <td>
                              <button class="btn btn-info" @click="editMachine(toolRoomMachine.id)" title="Edit"><i class="fa fa-pencil"></i></button>
                              <button class="btn btn-danger" @click="deleteMachine(toolRoomMachine.id)" title="Delete"><i class="fa fa-trash"></i></button>
                          </td>
                      </tr>
                  </table>  
                  </td>
                </tr>
            <tr style="background-color:#5cb85c;color:white;">
                    <th colspan="2">Address</th>
                </tr>
             <tr>
              <td colspan="2">{{ toolMachine.address }}</td>
            </tr>
           <tr>
              <th>City</th>
              <td>{{ toolMachine.city }}</td>
            </tr>
            <tr>
              <th>State</th>
              <td>{{ toolMachine.state }}</td>
            </tr>
            <tr>
              <th>Country</th>
              <td>{{ toolMachine.country.name }}</td>
            </tr>
           
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './../SideBar.vue';
export default {
    components: {
        'sidebar': SideBar,
        
    },
        
    data() {
        return {
            toolMachine: {},
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            success:null
        }
    },
    created() {
        this.loading = true
        this.axios
       .get(process.env.VUE_APP_API_URL+"api/tool-room-company/"+sessionStorage.getItem("MyToolMachine"))
        .then(response => {
            this.toolMachine = response.data.company;
        })
        .finally(() => this.loading = false);
    },
    methods: {
            
            deleteTool(id) {
          this.axios
          .delete(process.env.VUE_APP_API_URL+"api/tool-room-company/"+id)
          .then(response => {
            if(response.error==false){
                window.location.reload();
                    }
                })
          .finally(() => this.loading = false);
        },
        addMachine(id) {
          sessionStorage.setItem("MyToolMachine", id);
          this.$router.push({ name: "ToolMachineAdd" });
      },
      editMachine(id) {
          sessionStorage.setItem("MyToolMachineId", id);
          this.$router.push({ name: "ToolMachineEdit" });
      },
      deleteMachine(id) {
          this.axios
          .delete(process.env.VUE_APP_API_URL+"api/tool-room-machine/"+id)
          .then(response => {
            if(response.data.error==false){
                window.location.reload();
                    }
                })
          .finally(() => this.loading = false);
        },
    }
};
</script>
<style type="text/css">
    .top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  color: red;
  text-align: right;
}
</style>