<template>
	<div>
		<div class="cl"></div>
		<div class="profile-sec">
		  <div class="container-fluid">
		    <div class="row">
		      <div class="col-md-2 zero">
		        <sidebar/>
		      </div>
		      <div class="col-md-10">
		        <div class="profl-sec">
		          <div class="h3-title">
		            <h3>Material Product Add</h3>
		          </div>
		          <div class="profile-details">
		            <div class="col-md-10 p_t_10">
		              <form>
		                <div class="row">
		                  <div class="col-md-3">
		                    <label style="width: 100%">Upload Product Photo</label>
		                    <div class="box">
		                      <div class="js--image-preview"></div>
		                      <div class="upload-options">
		                        <label>
		                          <input type="file" name="photo1" class="image-upload" accept="image/*">
		                        </label>
		                      </div>
		                    </div>
		                  </div>
		                  <div class="col-md-3">
		                    <label style="visibility: hidden;">Upload Product Photo</label>
		                    <div class="box">
		                      <div class="js--image-preview"></div>
		                      <div class="upload-options">
		                        <label>
		                          <input type="file" name="photo2" class="image-upload" accept="image/*">
		                        </label>
		                      </div>
		                    </div>
		                  </div>
		                  <div class="col-md-3">
		                    <label style="visibility: hidden;">Upload Product Photo</label>
		                    <div class="box">
		                      <div class="js--image-preview"></div>
		                      <div class="upload-options">
		                        <label>
		                          <input type="file" name="photo3" class="image-upload" accept="image/*">
		                        </label>
		                      </div>
		                    </div>
		                  </div>
		                  <div class="col-md-3">
		                    <label style="visibility: hidden;">Upload Product Photo <span>*</span></label>
		                    <div class="box">
		                      <div class="js--image-preview"></div>
		                      <div class="upload-options">
		                        <label>
		                          <input type="file" name="photo4" class="image-upload" accept="image/*">
		                        </label>
		                      </div>
		                    </div>
		                  </div>
		                </div>
		                <div class="row">
		                  <div class="col-md-12">
		                    <div class="form-group">
		                      <label>Product Name</label>
		                      <input type="text" name="" value="" class="form-style" placeholder="Product Name">
		                    </div>
		                  </div>
		                  <div class="col-md-6">
		                    <div class="form-group">
		                      <label>Product Brand</label>
		                      <select  class="form-style">
		                        <option>-- Select --</option>
		                        <option>Category - 2</option>
		                      </select>
		                    </div>
		                  </div>
		                  <div class="col-md-6">
		                    <div class="form-group">
		                      <label>Product Condition</label>
		                      <select  class="form-style">
		                        <option>-- Select --</option>
		                        <option>Category - 2</option>
		                      </select>
		                    </div>
		                  </div>
		                  <div class="col-md-12">
		                    <div class="form-group">
		                      <label>Discription</label>
		                      <textarea class="form-style" rows="4" placeholder="Discription"></textarea>
		                    </div>
		                  </div>
		                </div>
		                <div class="row">
		                  <div class="col-md-12">
		                    <h4 style="color: #15a46a;margin-bottom: 15px;">Availability</h4>
		                  </div>
		                  <div class="col-md-4">
		                    <div class="form-group">
		                      <label>Quantity</label>
		                      <select  class="form-style">
		                        <option>Category - 1</option>
		                        <option>Category - 2</option>
		                      </select>
		                    </div>
		                  </div>
		                  <div class="col-md-4">
		                    <div class="form-group">
		                      <label>Unit</label>
		                      <select  class="form-style">
		                        <option>-- Select --</option>
		                        <option>Category - 2</option>
		                      </select>
		                    </div>
		                  </div>
		                  <div class="col-md-4">
		                    <div class="form-group">
		                      <label>Supply Type</label>
		                      <select  class="form-style">
		                        <option>-- Select --</option>
		                        <option>Category - 2</option>
		                      </select>
		                    </div>
		                  </div>
		                </div>
		                <div class="row">
		                  <div class="col-md-12">
		                    <h4 style="color: #15a46a;margin-bottom: 20px;">Location</h4>
		                  </div>
		                  <div class="col-md-6">
		                    <div class="form-group">
		                      <label>Address</label>
		                      <input type="text" name="" value="" disabled="" class="form-style" placeholder="Your street address">
		                    </div>
		                  </div>
		                  <div class="col-md-6">
		                    <div class="form-group">
		                      <label>City</label>
		                      <select  class="form-style">
		                        <option>-- Select --</option>
		                        <option>Category - 2</option>
		                      </select>
		                    </div>
		                  </div>
		                  <div class="col-md-6">
		                    <div class="form-group">
		                      <label>Country</label>
		                      <select  class="form-style">
		                        <option>-- Select --</option>
		                        <option>Category - 2</option>
		                      </select>
		                    </div>
		                  </div>
		                  <div class="col-md-6">
		                    <div class="form-group">
		                      <label>Target Country</label>
		                      <select  class="form-style">
		                        <option>-- Select --</option>
		                        <option>Category - 2</option>
		                      </select>
		                    </div>
		                  </div>
		                </div>
		                <div class="row">
		                  <div class="col-md-4">
		                    <div class="form-group">
		                      <label>Zipcode</label>
		                      <input type="text" name="" value="" class="form-style">
		                    </div>
		                  </div>
		                </div>
		                <div class="form-group">
		                  <input type="submit" value="Submit" class="btn btn-submit">
		                </div>
		              </form>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
		<div class="cl"></div>
	</div>
</template>
<script>
import SideBar from './SideBar.vue';
	export default {
		 components: {
        'sidebar': SideBar
    },
	}
</script>