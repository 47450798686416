<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
        <div class="form-x">
            <div class="container">    
                <div class="row">
                    <div class="bg-clr">
                        <div class="col-md-6">
                            <img src="../assets/img/login.png" class="img-responsiv">
                        </div>
                        <div class="col-md-6">
                            <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="imgcontainer">
                                    <h3>Verify</h3>
                                    <p>We send you OTP on {{ details.username }} <router-link to="/login-with-otp"><i class="fa fa-pencil"></i></router-link></p>
                                </div>
                            <form v-on:submit.prevent="login">
                                <div class="row form-group">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input type="text" name="otp" placeholder="Enter OTP"  class="form-style" :class="{ 'is-invalid': errors.otp }" 
                                        v-model="details.otp" id="otp" required=""/>
                                        <div class="invalid-feedback" v-if="errors.otp">
                                            {{ errors.otp[0] }}
                                        </div>
                                    </div>
                                </div>
                                     <center><input type="submit" name="next" class="pulse btnlogin" value="Login" /></center>
                                    <br>
                                    <p>Don't have an account <router-link to="/register" style="color:blue">Register with us free </router-link> </p>
                                    
                            </form>
                        </div>
                    </div>
                </div>  
            </div>      
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
//import { mapGetters, mapActions } from "vuex";
export default {
    name: "RegisterVerify",
    data() {
        return {
            details: {
        username: sessionStorage.getItem("loginMobile"),
        otp: null,
      },
      loading: false,
      success:null,
      errors:{

            },
        }
    },
    
  methods: {
    login() {
    this.loading = true
    this.success = null
    this.errors = {}
      this.axios
                    .post(process.env.VUE_APP_API_URL+"api/login-withotp/verify", this.details)
                    .then(response => {
                        //console.log(response.data);
                        if(response.data.error==true){
                            if(response.data.validate){
                                this.errors = response.data.validate
                            }
                            this.success = response.data.msg
                        }else{
                            this.success = response.data.msg
                            localStorage.setItem("authToken", response.data.token);
                        this.$router.push({ name: "Home" });
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
    }
  }
};
</script>