<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>About Us</span></h2>
        </div>
      </div>
    </div>
    <div class="row" v-for="(about, index) in abouts" v-bind:key="index" >
      <p v-html="about.about_us"></p>
    </div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            abouts: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/about-us")
        .then(response => {
            this.abouts = response.data.aboutUs;
        })
        .finally(() => this.loading = false);
    }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>