<template>
    <div>
      <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
<div class="main">
 <div class="container wrapper">
    <div class="row">
      <div class="col-md-5">
        <VueSlickCarousel :arrows="true" :dots="true" :autoplay="true" :autoplaySpeed="2000">
          <div><img src="../assets/img/car_scrappage4.jpeg"/></div>
        </VueSlickCarousel>
      </div>
      <div class="col-md-7">
        <div class="packs-contant">
          <h3 style="margin-top:0;">Car Status</h3>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;"><center>Vechile Info</center></td>
              </tr>
             <tr >
              <th width="30%">Owner Name</th>
              <td> R C Sharma</td>
            </tr>
            <tr>
              <th>Reg. Year</th>
              <td>2004 </td>
            </tr>
            
            <tr> 
              <th>Fuel</th>
              <td>Diesel</td>
            </tr>
            <tr> 
              <th>Last Fitness Check</th>
              <td>01.08.2021</td>
            </tr>
            <tr> 
              <th>Fitness Status</th>
              <td>Failed</td>
            </tr>
            <tr> 
              <th>Remaining Life</th>
              <td>0 Years</td>
            </tr>
            <tr> 
              <th>Car Scrap Value</th>
              <td>Rs 30000</td>
            </tr>
            <tr> 
              <th>Action Required</th>
              <td>Please visit nearest car scrap dealer</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
  data() {
        return {
            imgPath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    methods: {
            carStatus() {
                this.loading = true
                this.success = null
                this.errors = {}
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/profile-update", this.profile)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        window.location.reload();
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            }
        }
};
</script>