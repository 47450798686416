<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		<div class="adult-title"></div>
<div class="main" v-for="(product , index) in products" v-bind:key="index">
  <div class="container wrapper">
    <div class="row">
      <div class="col-md-5">
        <!-- <div class="exzoom hidden" id="exzoom">
          <div class="exzoom_img_box">
            <ul class='exzoom_img_ul'>
              <li><img :src="imgPath+product.image1"/></li>
              <li><img :src="imgPath+product.image2"/></li>
              <li><img :src="imgPath+product.image3"/></li>
              <li><img :src="imgPath+product.image4"/></li>
            </ul>
          </div>
          <div class="exzoom_nav"></div>
          <p class="exzoom_btn">
            <a href="javascript:void(0);" class="exzoom_prev_btn"> >> </a>
            <a href="javascript:void(0);" class="exzoom_next_btn"> > </a>
          </p>
        </div> -->
        <VueSlickCarousel :arrows="true" :dots="true" :autoplay="true" :autoplaySpeed="2000">
          <div><img :src="imgPath+product.image1"/></div>
          <div v-if="product.image2"><img :src="imgPath+product.image2"/></div>
          <div v-if="product.image3"><img :src="imgPath+product.image3"/></div>
          <div v-if="product.image4"><img :src="imgPath+product.image4"/></div>
        </VueSlickCarousel>
      </div>
      <div class="col-md-7">
        <div class="packs-contant">
          <h3 style="margin-top:0;">{{ product.name }}</h3>
          <table class="table table-bordered">
            <tbody>
             <tr>
              <th width="30%">Category</th>
              <td><span v-if="product.product_category">{{ product.product_category.name }}</span></td>
            </tr>
             <tr>
              <th>Condition</th>
              <td><span v-if="product.product_condition">{{ product.product_condition.name }}</span></td>
            </tr>
            <tr>
              <th>Quantity</th>
              <td>{{ product.quantity }} {{ product.unit }}</td>
            </tr>
            <tr>
              <th>Supply Type</th>
              <td>{{ product.supply_type }}</td>
            </tr>
            <tr>
              <th>Product Type</th>
              <td>{{ product.product_type }}</td>
            </tr>
            <tr>
              <th>Price</th>
              <td>{{ product.currency }} {{ product.price }}/ {{ product.price_basis }}</td>
            </tr>
             <tr>
              <th>Address</th>
              <td>{{ product.city }}, {{ product.state }}, <span v-if="product.country">{{ product.country.name }}</span></td>
            </tr>
             <tr>
              <th>Target Country</th>
              <td>{{ product.target_country }}</td>
            </tr>
            <tr>
              <td colspan="2">
            <button v-on:click="showInfo = true" class="btn btn-success">View Contact Info</button>
            &nbsp;
                  <button @click.prevent="openSideElement(product.sellerid)" class="btn btn-info">Chat</button>
                  <div id="app">
                    <!-- <button class="btn btn-info" @click.prevent="openSideElement()">Chat</button> -->
                    <div class="side-element" :class="open ? 'show-element' : ''">
                      <div class="chat-head">
                        <div>
                          <div class="_icon">
                            <img src="../assets/img/usericon.png">
                            <div class="uoffline" v-if="online_status=='online'"></div>
                            <p>Last Seen: {{ lastSeen | formatDateTime }}</p>
                          </div>
                        </div>
                        <div style="width: 326px;">
                          <h4>{{ product.sellername }} <!-- Last Seen: {{ lastSeen | formatDateTime }} --></h4>
                          <p></p>
                        </div>
                        <div><span class="close-btn2" @click.prevent="close()">x</span></div>
                      </div>
                      <div class="content1">
                        <div class="chat">
                          <div id="showallchatmessage" class="chat__wrapper">
                            <div class="chat__wrapper" v-for="(chat , index) in chats" v-bind:key="index">
                              <div class="chat__message" v-if="fromUser == chat.from_user">
                                <div>{{ chat.message }}</div>
                                <div class="date">
                                  {{ chat.date | formatDateTimeMonth }}
                                </div>
                              </div>
                              <div class="chat__message chat__message-own" v-else>
                                <div>{{ chat.message }}</div>
                                <div class="date">
                                  {{ chat.date | formatDateTimeMonth }}
                                </div>
                                <span style="color:#53bdeb" v-if="chat.read==1"><svg viewBox="0 0 16 15" width="16" height="15" class=""><path fill="currentColor" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg></span>
                                <span v-else><svg viewBox="0 0 16 15" width="16" height="15" class=""><path fill="currentColor" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg></span>
                              </div>
                            </div>
                          </div>
                        </div> 
                        <div class="chat__form">
                          <form id="chat__form" @submit.prevent="sendMessage">
                            <input id="message_text" type="text" placeholder="Type your message here ..." v-model="message.message" required> <button type="submit">
                              <img src="../assets/img/send.png" alt="" class="send-img"></button>
                          </form> 
                          <span id="showmsgerror" class="text-danger"></span>
                        </div> 
                        <div id="result"></div>
                      </div>
                    </div>
                  </div>
            </td>
            </tr>
            </tbody>
          </table>
            <table class="table table-bordered" >
              <tbody>
            <tr>
              <th width="30%">Name </th>
              <td>{{ product.sellername }}</td>
            </tr>
            <tr>
              <th>Company </th>
              <td>{{ product.sellercompany_name }}</td>
            </tr>
            <tr v-if="showInfo">
              <th>Mobile </th>
              <td>{{ product.sellermobile }}</td>
            </tr>
            <tr v-if="showInfo">
              <th>Email </th>
              <td>{{ product.selleremail }}</td>
            </tr>
            </tbody>
          </table>
          <h3>Description</h3>
          <p>{{ product.description }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="main">
  <div class="container" v-if="similarProducts">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Similar Products</span></h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" v-for="(similarProduct , index1) in similarProducts" :key="index1">
        <div class="grid-item"  v-if="similarProduct">
          <div class="imgholder ">
            <a @click="viewProduct(similarProduct.id, similarProduct.category_id)">
              <img :src="imgPath+similarProduct.image1">
            </a>
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ similarProduct.name}} </span>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid"> {{ similarProduct.currency}} {{ similarProduct.price}} / {{ similarProduct.price_basis}} </div>
              <!-- <div class="product-location-grid">{{ similarProduct.zip_code}}, <span v-if="similarProduct.country">{{ similarProduct.country.name}}</span></div> -->
            </div>
          </div>
        </div>
                <div v-else>Record Not Found</div>
      </div>
      <!-- <div class="col-md-3">
        <div class="grid-item">
          <div class="imgholder ">
            <a href="trade-details.php">
              <img :src="imgPath+'images/1620907157img1.jpg'">
            </a>
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">ass </span>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid"> A 12 / mt </div>
              <div class="product-location-grid">DElhi</div>
            </div>
          </div>
        </div>
      </div> -->

      
    </div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: { VueSlickCarousel },
   mounted: function () {
  this.timer = setInterval(() => {
    this.getMessage()
  }, 5000)
},
	data() {
        return {
            products: [],
            similarProducts: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            showInfo: false,
            open: false,
            timer: null,
            chats: [],
            fromUser: '',
            userName: '',
            userImage: '',
            lastSeen: '',
            online_status: 'offline',
            message: {
              sender_id: sessionStorage.getItem("chatId"),
              listing_id: sessionStorage.getItem("productId"),
              type: 'Trade Product',
              message: ''
            },
            listing_id: sessionStorage.getItem("productId")
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/trade-product-details?id="+sessionStorage.getItem("productId")+"&category_id="+sessionStorage.getItem("productCategoryId"))
        .then(response => {
            this.products = response.data.products;
            this.similarProducts = response.data.similar_products;
            console.log(response.data.similar_products);
        })
        .finally(() => this.loading = false);
    },
    methods: {
            openSideElement: function(id){
                this.open = true;
                this.message.sender_id = id
                this.fromUser = id
                sessionStorage.setItem("chatId",id)

            },
            close: function(){
                this.open = false;
            },
            sendMessage() {
                this.loading = true
                this.success = null
                this.errors = {}
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/chat-send-message", this.message)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                            this.chats = []
                            this.message.message = ''
                          this.axios
                          .get(process.env.VUE_APP_API_URL+"api/chat-message-listingwise?chat_user_id="+sessionStorage.getItem("chatId")+"&listing_id="+sessionStorage.getItem("chatListingId")+"&type="+sessionStorage.getItem("chatListingType"))
                          .then(response => {
                              this.chats = response.data.chats;
                              this.lastSeen = response.data.chatUserDetails[0].last_seen;
                          })
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            getMessage() {
                this.chats = []
                          this.axios
                          .get(process.env.VUE_APP_API_URL+"api/chat-message-listingwise?chat_user_id="+sessionStorage.getItem("chatId")+"&listing_id="+sessionStorage.getItem("chatListingId")+"&type="+sessionStorage.getItem("chatListingType"))
                          .then(response => {
                              this.chats = response.data.chats;
                              this.lastSeen = response.data.chatUserDetails[0].last_seen;
                              this.online_status = response.data.online_status;
                          })
            },
            viewProduct(id,category) {
              sessionStorage.setItem("productId", id);
              sessionStorage.setItem("productCategoryId", category);
              this.$router.push({ name: "TradeProductDetails" });
              this.$router.go();
            },
        },
        getMessage() {
                this.chats = []
                          this.axios
                          .get(process.env.VUE_APP_API_URL+"api/chat-message-listingwise?chat_user_id="+sessionStorage.getItem("chatId")+"&listing_id="+sessionStorage.getItem("chatListingId")+"&type="+sessionStorage.getItem("chatListingType"))
                          .then(response => {
                              this.chats = response.data.chats;
                              this.lastSeen = response.data.chatUserDetails[0].last_seen;
                              this.online_status = response.data.online_status;
                          })
            }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
  .usrimg { height: 90px !important; }
</style>