<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
        <div class="form-x">
            <div class="container">    
                <div class="row">
                    <div class="bg-clr">
                        <div class="col-md-6">
                            <img src="../assets/img/login.png" class="img-responsiv">
                        </div>
                        <div class="col-md-6">
                            <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="imgcontainer">
                                    <h3>SIGN UP/REGISTER YOUR ACCOUNT </h3>
                                </div>
                            <form v-on:submit.prevent="register">
                                <div class="row form-group">
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" name="first_name" placeholder="Enter first name"  class="form-style" :class="{ 'is-invalid': errors.first_name }" 
                                        v-model="details.first_name" id="first_name" required=""/>
                                        <div class="invalid-feedback" v-if="errors.first_name">
                                            {{ errors.first_name[0] }}
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" name="last_name" placeholder="Enter last name"  class="form-style" :class="{ 'is-invalid': errors.last_name }" 
                                        v-model="details.last_name" id="last_name" required=""/>
                                        <div class="invalid-feedback" v-if="errors.last_name">
                                            {{ errors.last_name[0] }}
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row form-group">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input type="text" name="company_name" placeholder="Enter company name"  class="form-style" :class="{ 'is-invalid': errors.company_name }" 
                                        v-model="details.company_name" id="company_name" required=""/>
                                        <div class="invalid-feedback" v-if="errors.company_name">
                                            {{ errors.company_name[0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    
                                        <div class="col-md-3">
                                         <input type="text" name="country_code" placeholder="country code"  class="form-style" :class="{ 'is-invalid': errors.country_code }" 
                                        v-model="details.country_code"  id="country_code" readonly=""/>
                                        </div>
                                        <div class="col-md-9">
                                        <input type="text" name="mobile" placeholder="Enter Mobile"  class="form-style" :class="{ 'is-invalid': errors.mobile }" 
                                        v-model="details.mobile" id="mobile" readonly=""/>
                                    </div>
                                        <div class="invalid-feedback" v-if="errors.mobile">
                                            {{ errors.mobile[0] }}
                                        </div>
                                    </div>
                                <div class="row form-group">
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" name="email" placeholder="Enter email"  class="form-style" :class="{ 'is-invalid': errors.email }" 
                                        v-model="details.email" id="email" required=""/>
                                        <div class="invalid-feedback" v-if="errors.email">
                                            {{ errors.email[0] }}
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" name="otp" placeholder="Enter OTP"  class="form-style" :class="{ 'is-invalid': errors.otp }" 
                                        v-model="details.otp" id="otp" required=""/>
                                        <div class="invalid-feedback" v-if="errors.otp">
                                            {{ errors.otp[0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <input type="password" name="password" placeholder="Enter password"  class="form-style" :class="{ 'is-invalid': errors.password }" 
                                        v-model="details.password" id="password" required=""/>
                                        <div class="invalid-feedback" v-if="errors.password">
                                            {{ errors.password[0] }}
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" name="password_confirmation" placeholder="Confirm password"  class="form-style" :class="{ 'is-invalid': errors.password_confirmation }" 
                                        v-model="details.password_confirmation" id="password_confirmation" required=""/>
                                        <div class="invalid-feedback" v-if="errors.password_confirmation">
                                            {{ errors.password_confirmation[0] }}
                                        </div>
                                    </div>
                                </div>
                                
                                     <center><input type="submit" name="next" class="pulse btnlogin" value="Register" /></center>
                                    <br>
                                    <p>Already have an account?<router-link to="/login" style="color:blue"> Login ! </router-link></p>
                            </form>
                        </div>
                    </div>
                </div>  
            </div>      
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
//import { mapGetters, mapActions } from "vuex";
export default {
    name: "RegisterVerify",
    data() {
        return {
            details: {
        first_name: null,
        last_name: null,
        company_name: null,
        mobile: sessionStorage.getItem("registerMobile"),
        country_code: sessionStorage.getItem("registercountryCode"),
        email: null,
        password: null,
        password_confirmation: null,
        otp: null,
      },
      loading: false,
      success:null,
      errors:{

            },
        }
    },
    
  methods: {
    register() {
    this.loading = true
    this.success = null
    this.errors = {}
      this.axios
                    .post(process.env.VUE_APP_API_URL+"api/register", this.details)
                    .then(response => {
                        //console.log(response.data);
                        if(response.data.error==true){
                            if(response.data.validate){
                                this.errors = response.data.validate
                            }
                            this.success = response.data.msg
                        }else{
                            this.success = response.data.msg
                            //commit("setUserData", response.data["details"]);
                            localStorage.setItem("authToken", response.data.token);
                        this.$router.push({ name: "UpdateRole" });
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
    }
  }
};
</script>