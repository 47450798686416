<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Unfortunately the page you are looking for has been moved or deleted</span></h2>
          <br>
          <router-link to="/"> <button>GO TO HOMEPAGE</button></router-link>
        </div>
      </div>
    </div>
    
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
    
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>