<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		<div class="filter">
  <div class="container">
        <div class="row">
      <div class="col-md-6">
        <div class="fltr">
          <i class="fa fa-sliders"></i> <span>Filter</span>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Job Category <i class="fa fa-angle-down"></i>
          </button>
          <form class="dropdown-menu" style="width: 320px;">
            <ul class="dm">
              <li class="dropdown-actions">
                <button type="button"  @click="filter" class="btn btn-success">Apply</button>
                <button type="Reset" @click="filter" class="btn btn-danger">Reset</button>
              </li>
              <li v-for="category in categories" v-bind:key="category.id" ><label><input type="checkbox" :id="category.id" v-bind:value="category.id" v-model="category_ids">{{ category.name }}</label></li>
            </ul>
          </form>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Location <i class="fa fa-angle-down"></i>
          </button>
          <form class="dropdown-menu" style="width: 320px;">
            <ul class="dm">
              <li class="dropdown-actions">
                <button type="button" @click="filter" class="btn btn-success">Apply</button>
                <button type="Reset" @click="filter" class="btn btn-danger">Reset</button>
              </li>
              <!-- <li role="separator" class="divider"></li> -->

              <!-- <li class="dropdown-header">Type</li> -->
              <li v-for="city in cities" v-bind:key="city.id"><label><input type="checkbox" :id="city.id" v-bind:value="city.id" v-model="location_ids">{{ city.name }}</label></li>
             
            </ul>
          </form>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Salary <i class="fa fa-angle-down"></i>
          </button>
          <form class="dropdown-menu" style="width: 320px;">
            <ul class="dm">
              <li><label><input type="radio" value="Below 10K" v-model="condition_ids">Below 10k</label></li>
              <li><label><input type="radio" value="10k-15k" v-model="condition_ids">10k - 15k</label></li>
              <li><label><input type="radio" value="15k-25k" v-model="condition_ids">15k - 25k</label></li>
              <li><label><input type="radio" value="25k-50k" v-model="condition_ids">25k - 50k</label></li>
              <li><label><input type="radio" value="50k-1L" v-model="condition_ids">50k - 1L</label></li>
              <li><label><input type="radio" value="Above of 1L" v-model="condition_ids">Above of 1L</label></li>
              <li class="dropdown-actions">
                <button type="button" @click="filter" class="btn btn-success">Apply</button>
                <button type="Reset" @click="filter" class="btn btn-danger">Reset</button>
              </li>
            </ul>
          </form>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" @click="reloadPage" class="btn">Clear filters</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="cl"></div>
<div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Job List ({{countryType}})</span></h2>
        </div>
        </div>
      </div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>{{category}}</span></h2>
        </div>
      </div>
    </div>
    <div class="row" v-if="jobs.length">
      <div class="col-md-4" v-for="(job , index) in jobs" v-bind:key="index">
        <div class="grid-item" v-if="job">
          <a @click="viewProduct(job.id)">
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">COMPANY NAME:{{ job.company_name }} </span>
              <span class="name">JOB PROFILE: {{ job.title }} </span>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid">SALARY: Rs {{ job.salary_from }} To Rs {{ job.salary_to }} </div>
              <div>AREA: {{ job.city }} , {{ job.state }}</div>
              <p>OPENING: {{ job.opening }}</p>
              <button class="btn btn-success" @click="viewProduct(job.id)">View Details</button>&nbsp;
              <button class="btn btn-success" @click="applyJob(job.id)">Apply</button>
            </div>
          </div>
          </a>
        </div>
        <div v-else>Record Not Found</div>
      </div>
      
    </div>
    <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            jobs: [],
            categories: [],
            cities: [],
            countries: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            countryType: '',
            param : '',
            location_ids:[],
            ab:'',
            category_ids:[],
              condition_id:'',
              category_id:'',
        }
    },
    created() {
        this.loading = true
        this.countryType = localStorage.getItem("ManpowerCountryType");
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/job-categorywise?available_country_type="+localStorage.getItem("ManpowerCountryType"))
        .then(response => {
            this.jobs = response.data.jobs;
        })
        .finally(() => this.loading = false);
        this.axios
            .get(process.env.VUE_APP_API_URL+"api/executive-manpower-categories?country_type="+localStorage.getItem("ManpowerCountryType"))
            .then(response => {
                this.categories = response.data['executive_manpower_category'];
            });
            this.axios
        .get(process.env.VUE_APP_API_URL+"api/cities")
        .then(response => {
            this.cities = response.data.cities;
        });
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/countries")
        .then(response => {
            this.countries = response.data.countries;
        });
    },
    methods: {
            viewProduct(id) {
              sessionStorage.setItem("jobId", id);
              this.$router.push({ name: "JobDetails" });
            },
            filter() {
              this.products= []
              this.ab = this.country_ids.join(',')
              this.category_id = this.category_ids.join(',')
              this.condition_id = this.condition_ids.join(',')
              this.axios
          .get(process.env.VUE_APP_API_URL+"api/trade-product-filter?product_type="+sessionStorage.getItem("Tradecategory")+"&country_ids="+this.country_ids+"&category_ids="+this.category_id+"&condition_ids="+this.condition_id)
          .then(response => {
              this.products = response.data.products;
          })
          .finally(() => this.loading = false);
              },
              applyJob(id) {
              this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/job-apply?job_id="+id)
        .then(response => {
            console.log(response.data);
        })
        .finally(() => this.loading = false);
              this.$router.go();
            },

          reloadPage() {
            window.location.reload();
          }
        }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>