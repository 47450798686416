<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		<div class="filter">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="fltr">
          <i class="fa fa-sliders"></i> <span>Filter</span>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Country <i class="fa fa-angle-down"></i>
          </button>
          <form class="dropdown-menu" style="width: 320px;">
            <ul class="dm">
              <li class="dropdown-actions">
                <button type="button"  @click="filter" class="btn btn-success">Apply</button>
                <button type="Reset" @click="filter" class="btn btn-danger">Reset</button>
              </li>
              <li v-for="country in countries" v-bind:key="country.id" ><label><input type="checkbox" :id="country.id" v-bind:value="country.id" v-model="country_ids">{{ country.name }}</label></li>
            </ul>
          </form>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Category <i class="fa fa-angle-down"></i>
          </button>
          <form class="dropdown-menu" style="width: 320px;">
            <ul class="dm">
              <li class="dropdown-actions">
                <button type="button" @click="filter" class="btn btn-success">Apply</button>
                <button type="Reset" @click="filter" class="btn btn-danger">Reset</button>
              </li>
              <!-- <li role="separator" class="divider"></li> -->

              <!-- <li class="dropdown-header">Type</li> -->
              <li v-for="productCategory in productCategories" v-bind:key="productCategory.id"><label><input type="checkbox" :id="productCategory.id" v-bind:value="productCategory.id" v-model="category_ids">{{ productCategory.name }}</label></li>
             
            </ul>
          </form>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Condition <i class="fa fa-angle-down"></i>
          </button>
          <form class="dropdown-menu" style="width: 320px;">
            <ul class="dm">
              <li v-for="productCondition in productConditions" v-bind:key="productCondition.id"><label><input type="checkbox" :id="productCondition.id" v-bind:value="productCondition.id" v-model="condition_ids">{{ productCondition.name }}</label></li>
              <li role="separator" class="divider"></li>
              <li class="dropdown-actions">
                <button type="button" @click="filter" class="btn btn-success">Apply</button>
                <button type="Reset" @click="filter" class="btn btn-danger">Reset</button>
              </li>
            </ul>
          </form>
        </div>
        <div class="btn-group dropdown-filter">
          <button type="button" @click="reloadPage" class="btn">Clear filters</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>{{category}}</span></h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" v-for="(product , index) in products" v-bind:key="index">
        <div class="grid-item" v-if="product">
          <div class="imgholder ">
            <a @click="viewProduct(product.id, product.category_id)">
              <img :src="imgPath+product.image1">
            </a>
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ product.name }} </span>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid"><i class="fa fa-inr"></i> {{ product.price }} {{ product.currency }} / {{ product.price_basis}} </div>
              <div class="product-location-grid">{{ product.state }}, {{ product.city }}, <span v-if="product.country">{{ product.country.name }}</span></div>
            </div>
          </div>
        </div>
        <div v-else>Record Not Found</div>
      </div>
      
    </div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            products: [],
            productCategories: [],
            productConditions: [],
            countries: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            category: sessionStorage.getItem("Tradecategory"),
            param : '',
            country_ids:[],
            ab:'',
            category_ids:[],
              condition_ids:[],
              condition_id:'',
              category_id:'',
        }
    },
    created() {
        this.loading = true
        /*if(this.$route.params.name == 'virgin-polymers'){ this.param = 'Virgin Polymers' }
          else{
            this.param = 'Virgin'
          }*/
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/typewise-trade-product-list?product_type="+sessionStorage.getItem("Tradecategory"))
        .then(response => {
            this.products = response.data.products;
        })
        .finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/product-categories")
        .then(response => {
            this.productCategories = response.data.product_categories;
        });
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/product-conditions")
        .then(response => {
            this.productConditions = response.data.product_conditions;
        });
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/countries")
        .then(response => {
            this.countries = response.data.countries;
        });
    },
    methods: {
            viewProduct(id,category) {
              sessionStorage.setItem("productId", id);
              sessionStorage.setItem("productCategoryId", category);
              this.$router.push({ name: "TradeProductDetails" });
            },
            filter() {
              this.products= []
              this.ab = this.country_ids.join(',')
              this.category_id = this.category_ids.join(',')
              this.condition_id = this.condition_ids.join(',')
              this.axios
          .get(process.env.VUE_APP_API_URL+"api/trade-product-filter?product_type="+sessionStorage.getItem("Tradecategory")+"&country_ids="+this.country_ids+"&category_ids="+this.category_id+"&condition_ids="+this.condition_id)
          .then(response => {
              this.products = response.data.products;
          })
          .finally(() => this.loading = false);
              },

          reloadPage() {
            window.location.reload();
          }
        }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>