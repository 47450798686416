<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="main bgF6F9FC">
        <div class="container">
            <div class="row">
            <div class="col-md-12"> 
            <div class="home-tit">
            <h2><span>Blogs Category</span></h2>
          </div>    
                <div class="tab-content">
                <div class="tab-pane active" id="home-v">
                    <div class="col-md-3" v-for="category in categories" :key="category.id">
                    <div class="hm-shop-itm">
                        <a @click="view(category.id, category.name)">
                        <div class="hm-shop-img">
                        <img :src="imagePath+category.image" alt="" class="img-responsive">
                        </div>
                        <div class="hm-shop-cnt">
                        <div class="sp-tt">
                            <h4>{{ category.name}}</h4>
                        </div>
                        </div>
                        
                        </a>
                    </div>
                    </div>

                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        
    </div>
</template>

<script>
export default {
    
    data() {
        return {
            categories: [],
            imagePath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
            .get(process.env.VUE_APP_API_URL+"api/blog-categories")
            .then(response => {
                this.categories = response.data['categories'];

            
            })
            .finally(() => this.loading = false);
    },
    methods: {
        view(id, name){
            localStorage.setItem("blogCategory", id);
            localStorage.setItem("blogCategoryName", name);
              this.$router.push({ name: "Blog" });
        }
    }
};
</script>
<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>