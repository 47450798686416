import axios from "axios";

export default {
  namespaced: true,

  state: {
    userData: null
  },

  getters: {
    user: state => state.userData,
    isAuthenticated: state => !!state.userData
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
    }
  },

  actions: {
    getUserData({ commit }) {
      axios
        .get(process.env.VUE_APP_API_URL + "api/profile")
        .then(response => {
          //console.log(response.data);
          commit("setUserData", response.data["profile"]);
        })
        .catch(() => {
          localStorage.removeItem("authToken");
        });
    },
    sendLoginRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "api/login", data)
        .then(response => {
          if(response.data.error==false){
            // console.log(response.data);
            commit("setUserData", response.data["details"]);
            localStorage.setItem("authToken", response.data["token"]);
        }else{
            console.log('fail')
        }
          
        });
    },
    sendRegisterRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "api/register-otp", data)
        .then(response => {
          commit("setUserData", response.data.user);
          localStorage.setItem("authToken", response.data.token);
        });
    },
    sendLogoutRequest({ commit }) {
      axios.get(process.env.VUE_APP_API_URL + "api/logout").then(res => {
        console.log(res.data.msg);
        commit("setUserData", null);
        localStorage.removeItem("authToken");
      });
    },
    sendVerifyResendRequest() {
      return axios.get(process.env.VUE_APP_API_URL + "email/resend");
    },
    sendAbRequest({ commit }) {
      return axios.get(process.env.VUE_APP_API_URL + "api/manpower-category").then(res => {

        //console.log(res.data);
        commit('manpowers', res.data['menpower_category']);
      });
    },
    sendVerifyRequest({ dispatch }, hash) {
      return axios
        .get(process.env.VUE_APP_API_URL + "email/verify/" + hash)
        .then(() => {
          dispatch("getUserData");
        });
    }
  }
};
