<template>
    <div>
        <div class="main bgF6F9FC">
        <div class="container">
            <div class="row">
            <div class="col-md-12">     
                <div class="tab-content">
                <div class="tab-pane active" id="home-v" v-if="bulkManpowers.length">
                    <div class="col-md-4" v-for="bulkManpower in bulkManpowers" :key="bulkManpower.id">
                    <div class="hm-shop-itm">
                        <div class="hm-shop-img">
                        <img :src="imagePath+bulkManpower.logo" alt="" class="img-responsive">
                        </div>
                        <div class="hm-shop-cnt">
                        <div class="sp-tt">
                            <h4>{{ bulkManpower.company_name}}</h4>
                        </div>
                        <div class="sop-nm">
                            <span class="product-price"><i class="fa fa-map-marker"></i> {{ bulkManpower.city}}</span>
                            <p><i class="fa fa-briefcase"></i> {{ bulkManpower.team_capacity}}</p>
                        </div>
                        </div>
                        <div class="hm-shop-cahrt">
                        <div class="add2crt">
                            
                            <a @click="view(bulkManpower.id)">View</a>
                        </div>
                        </div>
                    </div>
                    </div>

                </div>
                <div v-else><center><b><br><br><br>No Data Found <br><br><br></b></center></div>
                </div>
            </div>
            </div>
        </div>
        </div>
        
    </div>
</template>

<script>
export default {
    
    data() {
        return {
            bulkManpowers: [],
            imagePath: process.env.VUE_APP_API_URL,
        }
    },
    created() {
        this.axios
            .get(process.env.VUE_APP_API_URL+"api/bulk-manpower-work-list?category_id="+sessionStorage.getItem('labourCategory')+"&available_country_type="+localStorage.getItem("ManpowerCountryType"))
            .then(response => {
                console.log(response.data.error);
                this.bulkManpowers = response.data['bulk_manpowers'];

            
            });
    },
    methods:{
        view(id)
        {
            localStorage.setItem("labourid",id);
                this.$router.push({ name: "manpowerLabourDetails" });
        }
    }
}
</script>