<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
        <div class="form-x">
            <div class="container">    
                <div class="row">
                    <div class="bg-clr">
                        <div class="col-md-6">
                            <img src="../assets/img/login.png" class="img-responsiv">
                        </div>
                        <div class="col-md-6">
                            <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="imgcontainer">
                                    <h3>SIGN UP/REGISTER YOUR ACCOUNT </h3>
                                </div>
                            <form v-on:submit.prevent="register">
                                
                                <div class="row form-group">
                                    <div class="col-md-3 col-sm-3 col-xs-12">
                                        <input type="text" name="country_code" placeholder="Enter Country Code"  class="form-style" :class="{ 'is-invalid': errors.country_code }" 
                                        v-model="details.country_code" id="country_code" required=""/>
                                        <div class="alert alert-danger" v-if="errors.country_code">
                        {{ errors.country_code[0] }}
                    </div>
                                    </div>
                                    <div class="col-md-9 col-sm-9 col-xs-12">
                                        <input type="text" name="mobile" placeholder="Enter Mobile"  class="form-style" :class="{ 'is-invalid': errors.mobile }" 
                                        v-model="details.mobile" id="mobile" required=""/>
                                        <div class="alert alert-danger" v-if="errors.mobile">
                        {{ errors.mobile[0] }}
                    </div>
                                    </div>
                                </div>
                                    <center><input type="submit" name="next" class="pulse btnlogin" value="Register" /></center>
                                    <br>
                                    <p>Already have an account?<router-link to="/login" style="color:blue"> Login! </router-link></p>
                            </form>
                        </div>
                    </div>
                </div>  
            </div>      
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
//import { mapGetters, mapActions } from "vuex";
export default {
    name: "Register",
    data() {
        return {
            details: {
        mobile: null,
        country_code: '+91'
      },
      loading: false,
      success:null,
      errors:{

            },
        }
    },
    
  methods: {
    register() {
    this.loading = true
    this.success = null
    this.errors = {}
      this.axios
                    .post(process.env.VUE_APP_API_URL+"api/register-otp", this.details)
                    .then(response => {
                        //console.log(response.data);
                        if(response.data.error==true){
                            this.errors = response.data.validate
                            this.success = response.data.msg
                        }else{
                            this.success = response.data.msg
                            sessionStorage.setItem("registerMobile", this.details.mobile);
                            sessionStorage.setItem("registercountryCode", this.details.country_code);
                        this.$router.push({ name: "RegisterVerify" });
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
    }
  }
};
</script>