<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>{{ userName }}</h3>
                            <p>Last Seen: {{ lastSeen | formatDateTime }}</p>
                        </div>
                            <div class="row">
            <div class="col-md-12">
              <p><img src="" ></p>
              <div class="chat">
                <div class="chat__wrapper">
                  <div class="chat__wrapper" v-for="(chat , index) in chats" v-bind:key="index">
                  <div class="chat__message" v-if="fromUser == chat.from_user">
                    <div>{{ chat.message }}</div>
                    
                    <div class="date">
                      {{ chat.date | formatDateTimeMonth }}
                    </div>
                  </div>

                  <div class="chat__message chat__message-own" v-else>
                    <div v-if="chat.image"><img :src="imgPath+chat.image"></div>
                    <div v-else>{{ chat.message }}</div>
                    <div class="date">
                      {{ chat.date | formatDateTimeMonth }}
                    <span style="color:#53bdeb" v-if="chat.read==1"><svg viewBox="0 0 16 15" width="16" height="15" class=""><path fill="currentColor" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg></span>
                    <span v-else><svg viewBox="0 0 16 15" width="16" height="15" class=""><path fill="currentColor" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg></span>
                    </div>
                  </div>
                </div>
                  <!-- <div class="chat__message">
                    <div class="date"></div>
                    <div>Hello ..</div>
                  </div>
                  <div class="chat__message chat__message-own">
                    <div>Hi...</div>
                    <div class="date">
                      11 feb 2022
                    <span style="color:#53bdeb"><svg viewBox="0 0 16 15" width="16" height="15" class=""><path fill="currentColor" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg></span>
                    </div>
                  </div> -->
                  
                </div>
              </div>
              <div class="chat__form">
                <form id="chat__form" @submit.prevent="sendMessage">
                  <input id="text-message" type="text" placeholder="Type your message here ..." v-model="message.message" required>
                  <button type="submit">Send</button>
                </form>
              </div>
              <div id="result"></div>
            </div>
          </div>
        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './../SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
    mounted: function () {
  this.timer = setInterval(() => {
    this.getMessage()
  }, 5000)
},
    data() {
        return {
          timer: null,
            chats: [],
            userDetais: {},
            fromUser: '',
            userName: '',
            userImage: '',
            lastSeen: '',
            imgPath: process.env.VUE_APP_API_URL,
            errors:{

            },
            message: {
              sender_id: sessionStorage.getItem("chatId"),
              listing_id: sessionStorage.getItem("chatListingId"),
              type: sessionStorage.getItem("chatListingType"),
              message: ''
            },
            success:null,
            loading: false,
            open: false
        }
    },
    beforeDestroy() {
  clearInterval(this.timer)
},
    created() {
        this.loading = true
        this.fromUser = sessionStorage.getItem("chatId")
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/chat-message-listingwise?chat_user_id="+sessionStorage.getItem("chatId")+"&listing_id="+sessionStorage.getItem("chatListingId")+"&type="+sessionStorage.getItem("chatListingType"))
        .then(response => {
            this.chats = response.data.chats;
            this.userName = response.data.chatUserDetails[0].name;
            this.userImage = response.data.chatUserDetails[0].image;
            this.lastSeen = response.data.chatUserDetails[0].last_seen;
            
        })
        .finally(() => this.loading = false);
        
    },
    methods: {
            openSideElement: function(){
        this.open = true;
      },
      close: function(){
        this.open = false;
      },
      sendMessage() {
                this.loading = true
                this.success = null
                this.errors = {}
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/chat-send-message", this.message)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                            this.chats = []
                          this.axios
                          .get(process.env.VUE_APP_API_URL+"api/chat-message-listingwise?chat_user_id="+sessionStorage.getItem("chatId")+"&listing_id="+sessionStorage.getItem("chatListingId")+"&type="+sessionStorage.getItem("chatListingType"))
                          .then(response => {
                              this.chats = response.data.chats;
                              this.lastSeen = response.data.chatUserDetails[0].last_seen;
                          })
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            getMessage() {
                this.chats = []
                          this.axios
                          .get(process.env.VUE_APP_API_URL+"api/chat-message-listingwise?chat_user_id="+sessionStorage.getItem("chatId")+"&listing_id="+sessionStorage.getItem("chatListingId")+"&type="+sessionStorage.getItem("chatListingType"))
                          .then(response => {
                              this.chats = response.data.chats;
                              this.lastSeen = response.data.chatUserDetails[0].last_seen;
                          })
            }
        },
        getMessage() {
                this.chats = []
                          this.axios
                          .get(process.env.VUE_APP_API_URL+"api/chat-message-listingwise?chat_user_id="+sessionStorage.getItem("chatId")+"&listing_id="+sessionStorage.getItem("chatListingId")+"&type="+sessionStorage.getItem("chatListingType"))
                          .then(response => {
                              this.chats = response.data.chats;
                              this.lastSeen = response.data.chatUserDetails[0].last_seen;
                          })
            }
}
</script>

<style>
    /*-------------*/
html, body, #app{
  width:100%;
  overflow-x:hidden;
}


  </style>