<template>
    <div>
      <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
<div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Policy Update</span></h2>
        </div>
      </div>
    </div>
    <div class="row" v-if="policies.length">
      <div class="col-md-4" v-for="policy in policies" :key="policy.file">
        <div class="grid-item" v-if="policy">
            <a :href="imagePath+policy.file" download="">
          
          <div class="product_footer">
            <div class="grid_product_header" style="display: flex;justify-content: space-between;">
              <span class="name"><i class="fa fa-file-pdf-o" style="font-size: 50px;color: red;width: 50px;"></i></span>
              <span class="name" style="width: 100%;text-align: left;padding: 0 15px;"> {{ policy.title }}  </span>
              <span>
                <i class="fa fa-download" style="font-size:23px;color:white;background-color:green;border-radius:50%;border:2px green;width: 45px;height:41px;padding: 10px;"></i>
              </span>

            </div>
            
          </div>
            </a>
        </div>
        <div v-else>Record Not Found</div>
      </div>
    </div>
    <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            policies: [],
            imagePath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
            .get(process.env.VUE_APP_API_URL+"api/policies")
            .then(response => {
              console.log(response.data['policies']);
                this.policies = response.data['policies'];
            })
            .finally(() => this.loading = false);
    }
    
};
</script>