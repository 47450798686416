<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>Tool and Die Machine Add</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="invalid-feedback" v-if="errors">
                                        <div class="alert alert-danger">
                                            <ul>
                                                <li v-for="error in errors" :key="error">
                                                    {{ error }}
                                                </li>
                                            </ul>                    
                                        </div>
                                    </div>
                                    <form @submit.prevent="addMachine" ref="myForm">
                                        <div class="form-group row">
                                            
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Image</label>
                                                    <input type="file" name="image"  class="form-control"  @change="onFileChange">
                                                    <div class="invalid-feedback" v-if="errors.image">
                                                        {{ errors.image[0] }}
                                                    </div>
                                                    <div id="preview">
                                                            <img v-if="url" :src="url" />
                                                          </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Machine Name *</label>
                                                    <input type="text" name="name" class="form-control" :class="{ 'is-invalid': errors.name }" v-model="machine.name" required>
                                                    <div class="invalid-feedback" v-if="errors.name">
                                                        {{ errors.name[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Make *</label>
                                                    <input type="text" name="make"  class="form-control" :class="{ 'is-invalid': errors.make }" v-model="machine.make" required="">
                                                    <div class="invalid-feedback" v-if="errors.make">
                                                        {{ errors.make[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                             
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Mfg Year *</label>
                                                    <input type="text" name="model"  class="form-control" :class="{ 'is-invalid': errors.model }" v-model="machine.model" required="">
                                                    <div class="invalid-feedback" v-if="errors.model">
                                                        {{ errors.model[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Quantity *</label>
                                                    <input type="text" name="quantity"  class="form-control" :class="{ 'is-invalid': errors.quantity }" v-model="machine.quantity" required="">
                                                    <div class="invalid-feedback" v-if="errors.quantity">
                                                        {{ errors.quantity[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="hidden" v-model="machine.tool_room_company_id" name="tool_room_company_id">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <input type="text" name="description" class="form-control" :class="{ 'is-invalid': errors.description }" v-model="machine.description">
                                                    <div class="invalid-feedback" v-if="errors.description">
                                                        {{ errors.description[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="form-group">
                                            <input type="submit" value="Submit" class="btn btn-info">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './../SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            machine: {
                tool_room_company_id:'',
            },
            countries: [],
            success:null,
            errors:false,
            loading: false,
            logo:null,
            url: null,
            imgPath: process.env.VUE_APP_API_URL,
        }
    },
    created() {
        this.machine.tool_room_company_id = sessionStorage.getItem("MyToolMachine");
    },
    methods: {
            addMachine() {
                this.loading = true
                this.success = null
                this.errors = null
                var myFormData = new FormData(this.$refs.myForm)
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/tool-room-machine", myFormData)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        window.location.reload();
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            onFileChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
            },
            
        }
};
</script>