<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="main bgF6F9FC">
        <div class="container">
            <div class="row">
            <div class="col-md-12">
            <div class="home-tit">
            <h2><span>QA Category</span></h2>
            </div>  
            <div class="col-md-3" v-for="category in categories" :key="category.id">
                    <router-link v-bind:to="{ name: 'QA', params: { id: category.id }}">
                    <div class="cat-itm">
                        <div class="cat-img">
                            <img :src="imagePath+category.image" alt="" class="img-responsive">
                        </div>
                        <div class="cat-tt">
                            <h4>{{ category.name}}</h4>
                        </div>
                    </div>
                  </router-link>
                </div>   
                <!--<div class="tab-content">
                <div class="tab-pane active" id="home-v">
                    <div class="col-md-3" v-for="category in categories" :key="category.id">
                    <div class="hm-shop-itm">
                        <router-link v-bind:to="{ name: 'QA', params: { id: category.id }}">
                        <div class="hm-shop-img">
                        <img :src="imagePath+category.image" alt="" class="img-responsive">
                        </div>
                        <div class="hm-shop-cnt">
                        <div class="sp-tt">
                            <h4>{{ category.name}}</h4>
                        </div>
                        </div>
                        
                        </router-link>
                    </div>
                    </div>

                </div>
                </div>-->
            </div>
            </div>
        </div>
        </div>
        
    </div>
</template>

<script>
export default {
    
    data() {
        return {
            categories: [],
            imagePath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
            .get(process.env.VUE_APP_API_URL+"api/qa-categories")
            .then(response => {
                this.categories = response.data['categories'];

            
            })
            .finally(() => this.loading = false);
    }
};
</script>
<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
  .cat-itm{
    width: 100%;
    background-color: #fff;
    border: solid 1px #e8e8e8;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  .cat-img{
    height: 100px;
  }
  .cat-img img{height: 100%;width: auto;margin: auto;}
  .cat-tt {text-align: center;}
  .cat-tt h4{margin-bottom: 0;}
</style>