<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		
<div class="cl"></div>
<div class="contact-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Contact Us</span></h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="contact-rich">
          <h4 class="add-title">Get In Touch</h4>
          
          <!-- <div class="add-item">
            <div class="icon"><i class="fa fa-map-marker"></i></div>
            <div class="data"><strong>India :</strong> 16/5, Mathura Road, Haryana, India </div>
          </div>
          <div class="add-item">
            <div class="icon"><i class="fa fa-phone"></i></div>
            <div class="data">
              Call us:<a href="tel:9588165596"> +91 9588165596</a>
            </div>
          </div> -->
          <div class="add-item">
            <div class="icon"><i class="fa fa-envelope-o"></i></div>
            <div class="data">
              Email us:<br>
            </div>
            <a href="mailto:info@sdpolymer.in">info@thegrownet.com</a>
          </div>
          <!-- <div class="add-item">
            <div class="icon"><i class="fa fa-map-marker"></i></div>
            <div class="data"><strong>Dubai :</strong> Plot Number – S31004 Jabel Ali, Dubai United Arab Emirates P O Box – 263105</div>
          </div>  -->         
          <div class="add-item">
            <div class="icon"><i class="fa fa-whatsapp"></i></div>
            <div class="data">
              Whatsapp: <a href="tel:528086200">+ 91 9899080914 </a>
            </div>
          </div>
          <!-- <div class="add-item">
            <div class="icon"><i class="fa fa-envelope-o"></i></div>
            <div class="data">
              Email us:<br>
            </div>
            <a href="mailto:team@corporeal.in">team@XXXXXX.in</a>
          </div> -->
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-sec">
          <h4 class="add-title">Contact Form</h4>
          <form method="post">
            <div class="row form-group">
              <div class="col-md-6">
                <label>First Name</label>
                <input type="text" name="fname" minlength="2" maxlength="50" placeholder="" required="" class="form-style">
              </div>
              <div class="col-md-6">
                <label>Last Name</label>
                <input type="text" name="lname" placeholder="" required="" class="form-style">
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-6">
                <label>Mobile No.</label>
                <input type="text" name="mobile" onkeyup="this.value=this.value.replace(/[^\d]/,'')" placeholder="" required="" class="form-style">
              </div>
              <div class="col-md-6">
                <label>E-mail Id</label>
                <input type="text" name="email" placeholder="" required="" class="form-style">
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <label>Message</label>
                <textarea rows="4" name="message" required="" placeholder="" spellcheck="false"></textarea>
              </div>
            </div>            
            <div class="row form-group">
              <div class="col-md-12">
                <button type="Submit" name="submit" id="submit" class="btn btn-info">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            imgPath: process.env.VUE_APP_API_URL,
            loading: false
        }
    }
};
</script>
