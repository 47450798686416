<template>
    <div>
        <div class="main bgF6F9FC">
        <div class="container">
            <div class="row">
                <div class="col-md-3" v-for="category in categories" :key="category.id">
                    <a @click="view(category.id)">
                    <div class="cat-itm">
                        <div class="cat-img">
                            <img :src="imagePath+category.image" alt="" class="img-responsive">
                        </div>
                        <div class="cat-tt">
                            <h4>{{ category.name}}</h4>
                        </div>
                    </div>
                  </a>
                </div>
            <!-- <div class="col-md-12">     
                <div class="tab-content">
                <div class="tab-pane active" id="home-v">
                    <div class="col-md-3" v-for="category in categories" :key="category.id">
                    <div class="hm-shop-itm">
                        <router-link v-bind:to="{ name: 'manpowerExecutive', params: { id: category.id }}">
                        <div class="hm-shop-img">
                        <img :src="imagePath+category.image" alt="" class="img-responsive">
                        </div>
                        <div class="hm-shop-cnt">
                        <div class="sp-tt">
                            <h4>{{ category.name}}</h4>
                        </div>
                        </div>
                        
                        </router-link>
                    </div>
                    </div>

                </div>
                </div>
            </div> -->
            </div>
        </div>
        </div>
        
    </div>
</template>

<script>
export default {
    
    data() {
        return {
            categories: [],
            imagePath: process.env.VUE_APP_API_URL
        }
    },
    created() {
        this.axios
            .get(process.env.VUE_APP_API_URL+"api/bulk-manpower-work-categories?country_type="+localStorage.getItem("ManpowerCountryType"))
            .then(response => {
                this.categories = response.data['bulk_manpower_categoroies'];

            
            });
    },
    methods: {
            view(id) {
              sessionStorage.setItem("labourCategory", id);
              this.$router.push({ name: "manpowerLabour" });
            },
        }
};
</script>
<style>
.cat-itm{
    width: 100%;
    background-color: #fff;
    border: solid 1px #e8e8e8;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  .cat-img{
    height: 100px;
  }
  .cat-img img{height: 100%;width: auto;margin: auto;}
  .cat-tt {text-align: center;}
  .cat-tt h4{margin-bottom: 0;}
  </style>
