<template>
    <div>
      <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="cl"></div>
<div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12">     
        <div class="top-cat-sec">
          <div class="home-tit">
            <h2><span>Connect</span></h2>
            <p>Get the best suppliers, manufacturers and professionals.</p>
          </div>
          <div class="land-pack">
            <ul>
              <li>
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/img/products/money.jpg" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>Money</h5>
                    <router-link to="/money" class="land-pack-grid-btn"></router-link>
                  </div>
                </div>
              </li>
              <li>
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/img/machine.jpg" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>Machine</h5>
                    <router-link :to="{ name: 'MachineType' }" class="land-pack-grid-btn"></router-link>
                  </div>
                </div>
              </li>
              <li>
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/img/materials.jpg" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>Material</h5>
                    <router-link to="/material-brand" class="land-pack-grid-btn"></router-link>
                  </div>
                </div>
              </li>
              <md-dialog :md-active.sync="showDialog">
                  <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">X</md-button>
      </md-dialog-actions>
      <md-button class="md-primary" @click="country('India')">India</md-button>
      <md-button class="md-primary" @click="country('International')">International</md-button>
    </md-dialog>
              <li>
                <a class="" @click="showDialog = true">
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/img/products/manpower.jpg" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>Manpower</h5>
                    
                  </div>
                </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
      loading: false
    }
  },
  methods: {
            country(id) {
              this.loading = true
              localStorage.setItem("ManpowerCountryType", id);
              this.$router.push({ name: "manpower" });
            },
          }
};
</script>
<style type="text/css">
    .top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  color: red;
  text-align: right;
}
</style>