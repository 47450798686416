<template>
    <div>
        <div class="main bgF6F9FC">
            <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="home-tit">
                      <h2><span>{{category}} ({{ countryType}})</span></h2>
                      
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-12">     
                        <div class="tab-content">
                            <div class="filter">
                                  <div class="container">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="fltr">
                                          <i class="fa fa-sliders"></i> <span>Filter</span>
                                      </div>
                                      <div class="btn-group dropdown-filter">
                                          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Experience <i class="fa fa-angle-down"></i>
                                        </button>
                                        <form class="dropdown-menu" style="width: 320px;">
                                            <ul class="dm">
                                              <li class="dropdown-actions">
                                                <button type="button" @click="filter" class="btn btn-success">Apply</button>
                                                <button type="Reset" @click="filter" class="btn btn-danger">Reset</button>
                                            </li>
                                            <li v-for="experince in experinces" v-bind:key="experince"><label><input type="radio" :id="experince" v-bind:value="experince" v-model="experinceids">{{ experince }}</label></li>
                                        </ul>
                                    </form>
                                </div>
                                <div class="btn-group dropdown-filter">
                                  <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Keyskill(s) <i class="fa fa-angle-down"></i>
                                </button>
                                <form class="dropdown-menu" style="width: 320px;">
                                    <ul class="dm">
                                      <li class="dropdown-actions">
                                        <button type="button"  @click="filter" class="btn btn-success">Apply</button>
                                        <button type="Reset" class="btn btn-danger" @click="filter">Reset</button>
                                    </li>
                                    <li v-for="keyskill in keyskills" v-bind:key="keyskill.id"><label><input type="checkbox" :id="keyskill.id" v-bind:value="keyskill.id" v-model="keyskill_ids">{{ keyskill.name }}</label></li>

                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
                            <div class="tab-pane active" id="home-v" v-if="executives.length">
                                
        <div class="col-md-6" v-for="executive in executives" :key="executive.id">
            <div class="hm-shop-itm">
                <div class="hm-shop-img">
                    <img :src="imagePath+executive.image" alt="" class="img-responsive">
                </div>
                <div class="hm-shop-cnt">
                    <div class="sp-tt">
                        <h4>{{ executive.name}}</h4>
                    </div>
                    <div class="sop-nm">
                        <span class="product-price"><i class="fa fa-map-marker"></i> {{ executive.city}}</span>
                        <p><i class="fa fa-briefcase"></i> {{ executive.total_experience}} Years</p>
                    </div>
                </div>
                <div class="hm-shop-cahrt">
                    <div class="add2crt">

                        <a @click="view(executive.id)">View</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div v-else><center><b>No Data Found</b></center></div>
</div>
</div>
</div>
</div>
</div>

</div>
</template>

<script>
export default {

    data() {
        return {
            executives: [],
            keyskills: [],
            experinces: ['0-2','3-5','6-8','9-11','12-14','15-17','18-20','21-23','24-26'],
            imagePath: process.env.VUE_APP_API_URL,
            category: '',
            countryType: '',
            keyskill_ids: [],
            experinceids: ''
        }
    },
    created() {
        this.category = localStorage.getItem("executiveCategoryName");
        this.countryType = localStorage.getItem("ManpowerCountryType");
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/manpower-executive-list?category_id="+localStorage.getItem("executiveCategory")+"&available_country_type="+localStorage.getItem("ManpowerCountryType"))
        .then(response => {
            this.executives = response.data['manpower_executives'];            
        });
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/executive-manpower-specialization?category="+localStorage.getItem("executiveCategory"))
        .then(response => {
            this.keyskills = response.data.executive_manpower_specialization;
        });
    },
    methods: {
        filter()
        {
            this.executives= []
              this.axios
          .get(process.env.VUE_APP_API_URL+"api/manpower-executive-filter?category_id="+localStorage.getItem("executiveCategory")+"&available_country_type="+localStorage.getItem("ManpowerCountryType")+"&experince="+this.experinceids+"&keyskills="+this.keyskill_ids.join(','))
          .then(response => {
              this.executives = response.data.manpower_executives;
          });
        },
        view(id)
        {
            localStorage.setItem("executive",id);
            this.$router.push({ name: "manpowerExecutiveDetails" });
        }
    }
}
</script>