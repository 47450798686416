<template>
    <div>
      <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
<div class="main">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Car Scrappage</span></h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
          <div class="grid-item">
            <div class="imgholder ">             
                <a @click="showDialog = true">
                <img src="../assets/img/car_scrappage1.jpeg">
              </a>
            </div>
            <div class="product_footer">
              <div class="grid_product_header">
                <span class="name">Scrap Car Dealer</span>
              </div>
            </div>
          </div>
        </div>
         <md-dialog :md-active.sync="showDialog">
                  <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">X</md-button>
      </md-dialog-actions>
      <!-- <div class="md-layout-item">
        <md-field>
          <label for="movie">Choose State & City</label>
          <md-select v-model="statev" name="state" @change="city">
            <md-option value="">Select State</md-option>
            <md-option v-for="state in states" v-bind:key="state.id" :value="state.id">{{ state.name }}</md-option>
          </md-select>
        </md-field>
      </div> -->
      <!-- <div class="md-layout-item">
        <md-field>
          <md-select v-model="cityv" name="city">
            <md-option value="">Select City</md-option>
            <md-option v-for="city in cities" v-bind:key="city.id" :value="city.id">{{ city.name }}</md-option>
          </md-select>
        </md-field>
      </div> -->
      <form style="padding:0 20px 20px;">
        <div style="margin-bottom: 15px;">
      <label for="movie">Choose State & City</label>
      <select class="form-style" v-model="statev" required @change="city">
        <option value="" selected="">Select State</option>
        <option v-for="state in states" :key="state.id" :value="state.id">{{state.name}}</option>
      </select>
    </div>
      <select class="form-style" v-model="cityv" required>
        <option value="" selected="">Select City</option>
        <option v-for="city in cities" :key="city.id" :value="city.id">{{city.name}}</option>
      </select>
      <div class="text-center">
      <md-button @click="view()" type="submit"><span class="btn btn-info">Go</span></md-button>
    </div>
    </form>
    </md-dialog>
        <div class="col-md-3">
          <div class="grid-item">
            <router-link to="/policy-update">
            <div class="imgholder ">
              <img src="../assets/img/car_scrappage2.jpeg">
            </div>
            <div class="product_footer">
              <div class="grid_product_header">
                <span class="name">Policy Update</span>
              </div>
            </div>
          </router-link>
          </div>
        </div>
        <div class="col-md-3">
          <div class="grid-item">
            <a @click="showDialogFitness = true">
            <div class="imgholder ">
              <img src="../assets/img/car_scrappage3.jpeg">
             </div>
            <div class="product_footer">
              <div class="grid_product_header">
                <span class="name">Car Fitness Centre</span>
              </div>
            </div>
          </a>
          </div>
        </div>
        <md-dialog :md-active.sync="showDialogFitness">
                  <md-dialog-actions>
        <md-button class="md-primary" @click="showDialogFitness = false">X</md-button>
      </md-dialog-actions>
      <form style="padding:0 20px 20px;">
        <div style="margin-bottom: 15px;">
      <label for="movie">Choose State & City</label>
      <select class="form-style" v-model="statev" required @change="city">
        <option value="" selected="">Select State</option>
        <option v-for="state in states" :key="state.id" :value="state.id">{{state.name}}</option>
      </select>
      </div>
      <div class="">
      <select class="form-style" v-model="cityv" required>
        <option value="" selected="">Select City</option>
        <option v-for="city in cities" :key="city.id" :value="city.id">{{city.name}}</option>
      </select>
      </div>
      <div class="text-center">
      <md-button @click="viewFitness()" type="submit"><span class="btn btn-info">Go</span></md-button>
    </div>
    </form>
    </md-dialog>
        <div class="col-md-3">
          <div class="grid-item">
            <router-link to="/know-your-car-status">
            <div class="imgholder ">
             
                <img src="../assets/img/car_scrappage4.jpeg">
              
            </div>
            <div class="product_footer">
              <div class="grid_product_header">
                <span class="name">Know Your Car Status</span>
              </div>
            </div>
            </router-link>
          </div>
        </div>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
    data() {
    return {
      showDialog: false,
      showDialogFitness: false,
      loading: false,
      states: [],
      cities: [],
      statev: '',
      cityv: ''
    }
  },
  created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/states")
        .then(response => {
            this.states = response.data.states;
        })
        .finally(() => this.loading = false);
    },
  methods: {
            city() {
              this.loading = true
              console.log(this.statev);
              this.axios
              .get(process.env.VUE_APP_API_URL+"api/cities?state_id="+this.statev)
              .then(response => {
                  this.cities = response.data.cities;
                  console.log(response.data.cities);
              })
              .finally(() => this.loading = false);
            },
            view() {
              sessionStorage.setItem("carDealerCity", this.cityv);
              sessionStorage.setItem("carDealerState", this.statev);
              this.$router.push({ name: "ScrapCarDealer" });
            },
            viewFitness() {
              sessionStorage.setItem("carDealerCity", this.cityv);
              sessionStorage.setItem("carDealerState", this.statev);
              this.$router.push({ name: "CarFitnessCenter" });
            },
          }
};
</script>
<style type="text/css">
    .top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  color: red;
  text-align: right;
}
</style>