<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Trade Category</span></h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
          <div class="grid-item">
            <div class="imgholder ">
              <!-- <router-link v-bind:to="{ name: 'VirginPolymers', query: { name: 'Virgin Polymers' }}"> -->
                <a @click="viewTrade('Virgin Polymers')">
                <img src="../assets/img/trade1.jpg">
              </a>
            </div>
            <div class="product_footer">
              <div class="grid_product_header">
                <span class="name">Virgin Polymers</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="grid-item">
            <div class="imgholder ">
             <a @click="viewTrade('Polymer Compounds')">
                <img src="../assets/img/trade2.jpg">
              </a>
            </div>
            <div class="product_footer">
              <div class="grid_product_header">
                <span class="name">Polymer Compounds</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="grid-item">
            <div class="imgholder ">
            <a @click="viewTrade('Reprocessed Polymer')">
                <img src="../assets/img/trade3.jpg">
             </a>
            </div>
            <div class="product_footer">
              <div class="grid_product_header">
                <span class="name">Reprocessed Polymers</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="grid-item">
            <a @click="viewTrade('Scrap Material')">
            <div class="imgholder ">
             
                <img src="../assets/img/scrap.jpg">
              
            </div>
            <div class="product_footer">
              <div class="grid_product_header">
                <span class="name">Scrap Material</span>
              </div>
            </div>
            </a>
          </div>
        </div>
    </div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            products: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    /*created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/trade-product-list")
        .then(response => {
            this.products = response.data.products;
            console.log(response.data.products);
        })
        .finally(() => this.loading = false);
    },*/
    methods: {
            viewTrade(id) {
              sessionStorage.setItem("Tradecategory", id);
              this.$router.push({ name: "VirginPolymers" });
            }
        }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>