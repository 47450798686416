<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>My Extruder Machine Details</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                   <div class="main" v-for="(extruderMachine , index) in extruderMachines" v-bind:key="index">
  <div class="">
    <div class="row">
      <div class="col-md-5">
        <VueSlickCarousel :arrows="true" :dots="true" :autoplay="true" :autoplaySpeed="2000">
          <div><img :src="imgPath+extruderMachine.image1"/></div>
          <div v-if="extruderMachine.image2"><img :src="imgPath+extruderMachine.image2"/></div>
          <div v-if="extruderMachine.image3"><img :src="imgPath+extruderMachine.image3"/></div>
          <div v-if="extruderMachine.image4"><img :src="imgPath+extruderMachine.image4"/></div>
        </VueSlickCarousel>
      </div>
      <div class="col-md-7">
        <div class="packs-contant">
          <h3 style="margin-top:0;">{{ extruderMachine.name }} <button class="btn btn-success" @click="editMachine(extruderMachine.id)"><i class="fa fa-pencil"></i> Edit</button></h3>
          <table class="table table-bordered">
            <tbody>
             <tr>
              <th width="30%">Machine Type</th>
              <td>{{ extruderMachine.machine_type }}</td>
            </tr>
             <tr>
              <th>Extrusion Capacity</th>
              <td>{{ extruderMachine.extrusion_capacity }} Kg/Hr</td>
            </tr>
            <!-- <tr>
              <th>Screw Size(mm)</th>
              <td>Length: {{ extruderMachine.screw_size_diameter }} Width: {{ extruderMachine.screw_size_length }}</td>
            </tr>
           <tr> 
              <th>Raw Material Feed</th>
              <td>{{ extruderMachine.raw_material_feed }}</td>
            </tr> -->
            <tr> 
              <th>Make</th>
              <td>{{ extruderMachine.make }}</td>
            </tr>
            <tr> 
              <th>Mfg Year</th>
              <td>{{ extruderMachine.mfg_year }}</td>
            </tr>
            <tr> 
              <th>Spare Capacity</th>
              <td>{{ extruderMachine.spare_capacity }} Hours/Month</td>
            </tr>
             <tr> 
              <th>Application</th>
              <td>{{ extruderMachine.application }}</td>
            </tr>
            <tr> 
              <th>Country</th>
              <td v-if="extruderMachine.country">{{ extruderMachine.country.name }}</td>
              <td v-else></td>
            </tr>
            <tr> 
              <th>State/Province</th>
              <td>{{ extruderMachine.state }}</td>
            </tr>
            <tr> 
              <th>City</th>
              <td>{{ extruderMachine.city }}</td>
            </tr>
            <tr> 
              <th>Pincode</th>
              <td>{{ extruderMachine.zip_code }}</td>
            </tr>
            <h3>Description</h3>
          <p>{{ extruderMachine.description }}</p>
            
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>
</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './../SideBar.vue';
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    components: {
        'sidebar': SideBar,
        VueSlickCarousel
    },
        
    data() {
        return {
            extruderMachines: {},
            imgPath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/my-extruder-machine-details?id="+sessionStorage.getItem("MyExtruderMachine"))
        .then(response => {
            this.extruderMachines = response.data.extruder_machine_details;
        })
        .finally(() => this.loading = false);
    },
    methods: {
            editMachine(id) {
              sessionStorage.setItem("MyExtruderMachine", id);
              this.$router.push({ name: "ExtruderMachineEdit" });
            }
        }
};
</script>
<style type="text/css">
    .top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  color: red;
  text-align: right;
}
</style>