<template>
  <div>
    <div v-if="loading">
      <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
        <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
      </div>
    </div>
    <div class="profile-sec">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-2 zero col-xs-12">
            <sidebar/>
          </div>
          <div class="col-md-10 col-xs-12">
            <div class="profl-sec">
              <div class="h3-title">
                <h3>My Chat</h3>
              </div>
              <div class="profile-details" >
                <div id="app">
                  <!-- <button class="btn btn-info" @click.prevent="openSideElement()">Chat</button> -->
                  <div class="side-element" :class="open ? 'show-element' : ''">
                    <div class="chat-head">
                      <div>
                        <div class="_icon">
                          <img src="https://codersinn.club/uploads/user_image/1642583611660.png">
                          <div class="uoffline"></div>
                        </div>
                      </div>
                      <div style="width: 326px;">
                        <h4>Alex Xander</h4>
                      </div>
                      <div><span class="close-btn2" @click.prevent="close()">x</span></div>
                    </div>
                    <div class="content1">
                      <div class="chat">
                        <div id="showallchatmessage" class="chat__wrapper">
                          <div class="chat__message">
                            <div class="date">10:19 pm</div>
                            <div>hello</div>
                          </div>
                          <div class="chat__message chat__message-own">
                            <div class="date">10:19 pm</div>
                            <div>hello</div>
                          </div>
                        </div>
                      </div> 
                      <div class="chat__form">
                        <form id="chat__form">
                          <input id="message_text" type="text" placeholder="Type your message here ..."> <button type="submit" onclick="send_message(9)"><img src="https://codersinn.club/webassets/live/assets/images/send.png" alt="" class="send-img"></button>
                        </form> 
                        <span id="showmsgerror" class="text-danger"></span>
                      </div> 
                      <div id="result"></div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="chat-main" v-if="chats.length">
                        <div v-for="(chat , index) in chats" v-bind:key="index">
                          <a @click="viewChat(chat.user_id,chat.user_name)" >
                            <div class="chat-itam">
                              <div class="chat-img">
                                <img :src="imgPath+chat.user_image" />
                              </div>
                              <div class="online" v-if="chat.online_status == 'online'"></div>
                              <div class="offline" v-else></div>
                              <div class="chat-cnt">
                                <h4>{{ chat.user_name }} <span class="badge badge-danger" v-if="chat.unread_message >0 ">{{ chat.unread_message }}</span></h4>
                                <!-- <p>Message</p> -->
                              </div>
          <!-- <div class="chat-time">
            <span>Just Now</span>
          </div> -->
        </div>
      </a>
    </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="cl"></div>
</div>
</template>
<script>
  import SideBar from './../SideBar.vue';

  export default {
    components: {
      'sidebar': SideBar
    },
    data() {
      return {
        chats: [],
        errors:{

        },
        success:null,
        loading: false,
        open: false,
        imgPath: process.env.VUE_APP_API_URL,
      }
    },
    created() {
      this.loading = true
      this.axios
      .get(process.env.VUE_APP_API_URL+"api/chat-list")
      .then(response => {
        this.chats = response.data.chats;
      })
      .finally(() => this.loading = false);

    },
    methods: {
      openSideElement: function(){
        this.open = true;
      },
      close: function(){
        this.open = false;
      },
      viewChat(id, name) {
        sessionStorage.setItem("chatId", id);
        sessionStorage.setItem("chatUser", name);
        this.$router.push({ name: "ChatListing" });
      },
    }
  }
</script>

<style>
  /*-------------*/
  html, body, #app{
    width:100%;
    overflow-x:hidden;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .side-element{
    position:fixed;
    height:80vh;
    width:80%;
    border-radius: 5px 5px 0 0;
    right:-100%;
    bottom:0px;
    -webkit-box-shadow: -1px 0px 8px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: -1px 0px 8px 0px rgba(0,0,0,0.3);
    box-shadow: -1px 0px 8px 0px rgba(0,0,0,0.3);
    transition: .5s ease-out;
    z-index: 9999999999;
  }
  .show-element{
    right:0;
  }

  .side-element .content{padding:0px;}
  .side-element .content h3{
    line-height: 28px;
    font-size: 18px;
    margin-top: 0;
    text-align: center;
  }
  .side-element .content .form-control{border-radius: 5px !important}

  @media(min-width:768px){
    .side-element{width:400px;}
    .artcl{padding: 0px 0 20px;}
  }
/*-----*/
.chat-main{
  width: 100%;
  background-color: #ffffff;
  float: left;
  padding: 15px;
}
.chat-msg{
  width: 100%;
  height: 500px;
  overflow-x: hidden;
  margin-bottom: 15px;
}
.chat-input{
  width: 100%;
  float: left;
}
.chat-itam{
  width: 100%;
  padding: 15px;
  border-bottom: solid 2px #F1F1F1;
  display: flex;
  float: left;
  position: relative;
}
.offline{
  width: 20px;
  height: 20px;
  background-color: #E8E8E8;
  position: absolute;
  border: solid 4px #ffffff;
  z-index: 1;
  border-radius: 50%;
  left: 80px;
  top: 70%;
}
.online{
  width: 20px;
  height: 20px;
  background-color: #69F154;
  position: absolute;
  border: solid 4px #f5f5f5;
  z-index: 1;
  border-radius: 50%;
  left: 80px;
  top: 70%;
}
.uonline{
  width: 10px;
  height: 10px;
  background-color: #69F154;
  border: solid 0px #f5f5f5;
  z-index: 1;
  border-radius: 50%;
  float: left;
  position: absolute;
  right: -2px;
  bottom: 0px;
}
.uoffline{
  width: 10px;
  height: 10px;
  background-color: #69F154;
  border: solid 0px #f5f5f5;
  z-index: 1;
  border-radius: 50%;
  float: left;
  position: absolute;
  right: -2px;
  bottom: 0px;
}
.userr-img{
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  overflow:hidden;
  left: 70px;
  top: 20%;
}
.userr-img img{width: 100%;height: 100%;}
.chat-itam .chat-img{
  width: 80px;
  height: 80px;
  float: left;
  border-radius: 50%;
  overflow: hidden;
}
.chat-itam .chat-img img{width: 100%;height: 100%;}
.chat-itam .chat-cnt{
  width: 80%;
  float: left;
  margin: 0 15px;
}
.chat-itam .chat-cnt h4{color: #333;}
.chat-itam .chat-cnt p{color: #333;}
.chat-itam .chat-time{
  width: 10%;
}
.chat-main ul li .msg-tt{
  width: 80%;
  float: left;
  line-height: 24px;
}
.chat-main ul li .msg-time{
  width: 10%;
  float: right;
}
.sender {
  background-color: #F6F6F6;
  width: 90%;
  float: right;
  padding: 15px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 4px;
}

.receiver{
  width: 90%;
  background-color: #54C1F1;
  color: #fff;
  float: left;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.chat-head{
  background-color: #e9e9e9;
  padding: 5px;
  width: 100%;
  height:50px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  border-bottom: solid 1px #c1c1c1;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 30%);
  display: flex;
  justify-content: space-between;
}
.chat-head ._icon{
  width: 40px;
  height: 40px;
  border: solid 1px #e9e9e9;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  margin-right: 13px;
}
.chat-head ._icon img{
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}
.close-btn2{
  font-size: 30px;
  padding-right: 5px;
  color: #f00;
  cursor: pointer;
}
#shownam a{
  white-space: nowrap;
  width: 325px;
  overflow: hidden;
  float: left;
  text-overflow: ellipsis;
  font-size: 16px;
  color: #050211;
}
.chat {
  width: 100%;
  height: calc(80vh - 100px);
  min-height: 100%;
  padding: 15px 30px;
  margin: 0 auto;
  overflow-y: scroll;
  background-color: #fff;
  transform: rotate(180deg);
  direction: rtl;
}
.chat__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.chat__message {
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 25px;
  color: #000;
  background-color: #e6e7ec;
  max-width: 600px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin: 15px 0;
  word-break: break-all;
  transform: rotate(180deg);
  direction: ltr;
}
.chat__message:after {
  content: "";
  width: 20px;
  height: 12px;
  display: block;
  background-image: url("https://stageviewcincyshakes.firebaseapp.com/icon-gray-message.e6296433d6a72d473ed4.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  bottom: -2px;
  left: -5px;
}
.chat__message-own {
  color: #fff;
  margin-left: auto;
  background-color: #00a9de;
}
.chat__message-own:after {
  width: 19px;
  height: 13px;
  left: inherit;
  right: -5px;
  background-image: url("https://stageviewcincyshakes.firebaseapp.com/icon-blue-message.2be55af0d98ee2864e29.png");
}
.chat__message-own .date{
  font-size: 14px;
  text-align: right;
  margin-bottom: 5px;
}
.chat__message .date{
  font-size: 14px;
}
.chat__form {
  background-color: #ffffff;
  padding: 0px 15px;
  border-top: solid 1px #e9e9e9;
}
.chat__form .send-img{
  float: left;
  width: 25px;
  margin-left: 10px;
}
.chat__form form {
  max-width: 800px;
  margin: 0 auto;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat__form input {
  height: 36px;
  font-size: 14px;
  min-width: 90%;
  padding-left: 15px;
  background-color: #f1f1f1;
  border-radius: 15px;
  border: solid 1px #e9e9e9;
}
.chat__form input:focus{box-shadow: none;border: 1px solid #51c6ec}
.chat__form button {
  width: 10%;
  height: 100%;
  font-size: 16px;
  background-color: transparent;
  border: none;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  color: #10a9ee;
}
.chat__form button:hover {
  font-weight: bold;
}
.badge-danger{
  background-color: red !important;
}
</style>