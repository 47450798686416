import AllCategory from './components/AllCategory.vue';
//import AddCategory from './components/AddCategory.vue';
import Home from './components/Home.vue';
import Connect from './components/Connect.vue';
import Manpower from './components/Manpower.vue';
import ManpowerExecutive from './components/ManpowerExecutive.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import RegisterVerify from './components/RegisterVerify.vue';
import ManpowerExecutiveDetails from './components/ManpowerExecutiveDetails.vue';
import ManpowerExecutiveCategory from './components/ManpowerExecutiveCategory.vue';
import ManpowerLabour from './components/ManpowerLabour.vue';
import ManpowerLabourDetails from './components/ManpowerLabourDetails.vue';
import ManpowerInstitutionalPlacement from './components/ManpowerInstitutionalPlacement.vue';
import ManpowerInstitutionalPlacementDetails from './components/ManpowerInstitutionalPlacementDetails.vue';
import AddTradeProduct from './components/AddTradeProduct.vue';
import TradeProductDetails from './components/TradeProductDetails.vue';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import Trade from './components/Trade.vue';
import Educate from './components/Educate.vue';
import TradeProduct from './components/TradeProduct.vue';
import NotFound from './components/NotFound.vue';
import AboutUs from './components/AboutUs.vue';
import Privacy from './components/Privacy.vue';
import Termuse from './components/Termuse.vue';
import Blog from './components/Blog.vue';
import QA from './components/QA.vue';
import knowYourPolymer from './components/knowYourPolymer.vue';
import knowYourProduct from './components/knowYourProduct.vue';
import BlogCategory from './components/BlogCategory.vue';
import BlogDetails from './components/BlogDetails.vue';
import QACategory from './components/QACategory.vue';
import QADetails from './components/QADetails.vue';
import Finincial from './components/Finincial.vue';
import FinacialInstitutionDetails from './components/FinacialInstitutionDetails.vue';
import Machine from './components/Machine.vue';
import InjectionMachine from './components/InjectionMachine.vue';
import InjectionMachineDetails from './components/InjectionMachineDetails.vue';
import BlowMoldingMachine from './components/BlowMoldingMachine.vue';
import BlowMoldingMachineDetails from './components/BlowMoldingMachineDetails.vue';
import ExtruderMachine from './components/ExtruderMachine.vue';
import ExtruderMachineDetails from './components/ExtruderMachineDetails.vue';
import ToolDie from './components/ToolDie.vue';
import ToolDieDetails from './components/ToolDieDetails.vue';
import UtilityMachine from './components/UtilityMachine.vue';
import UtilityMachineDetails from './components/UtilityMachineDetails.vue';
import AuxiliaryMachine from './components/AuxiliaryMachine.vue';
import AuxiliaryMachineDetails from './components/AuxiliaryMachineDetails.vue';
import Material from './components/Material.vue';
import MaterialBrand from './components/MaterialBrand.vue';
import MaterialDetails from './components/MaterialDetails.vue';
import ProductAdd from './components/ProductAdd.vue';
import ProductView from './components/ProductView.vue';
import PolymerProduct from './components/PolymerProduct.vue';
import PolymerDetails from './components/PolymerDetails.vue';
import UpdateRole from './components/UpdateRole.vue';
import Polymers from './components/Polymers.vue';
import MachineView from './components/MachineView.vue';
import MachineViewBuy from './components/MachineViewBuy.vue';
import MachineAdd from './components/MachineAdd.vue';
import InjectionMachineAdd from './components/InjectionMachineAdd.vue';
import InjectionMachineView from './components/InjectionMachineView.vue';
import InjectionMachineEdit from './components/InjectionMachineEdit.vue';
import MyInjectionMachineDetails from './components/MyInjectionMachineDetails.vue';
import BlowMoldingMachineAdd from './components/blowmolding/BlowMoldingMachineAdd.vue';
import BlowMoldingMachineView from './components/blowmolding/BlowMoldingMachineView.vue';
import BlowMoldingMachineEdit from './components/blowmolding/BlowMoldingMachineEdit.vue';
import MyBlowMoldingMachineDetails from './components/blowmolding/MyBlowMoldingMachineDetails.vue';
import ExtruderMachineAdd from './components/extruder/ExtruderMachineAdd.vue';
import ExtruderMachineView from './components/extruder/ExtruderMachineView.vue';
import ExtruderMachineEdit from './components/extruder/ExtruderMachineEdit.vue';
import MyExtruderMachineDetails from './components/extruder/MyExtruderMachineDetails.vue';
import ToolDieMachineAdd from './components/tooldie/ToolDieMachineAdd.vue';
import ToolDieMachineView from './components/tooldie/ToolDieMachineView.vue';
import ToolDieMachineEdit from './components/tooldie/ToolDieMachineEdit.vue';
import MyToolDieMachineDetails from './components/tooldie/MyToolDieMachineDetails.vue';
import ManpowerLabourCategory from './components/ManpowerLabourCategory.vue';
import CarScrappage from './components/CarScrappage.vue';
import KnowCarStatus from './components/KnowCarStatus.vue';
import ScrapCarDealer from './components/ScrapCarDealer.vue';
import PolicyUpdate from './components/PolicyUpdate.vue';
import CarFitnessCenter from './components/CarFitnessCenter.vue';
import CarStatus from './components/CarStatus.vue';
import MaterialPriceList from './components/MaterialPriceList.vue';
import Jobs from './components/Jobs.vue';
import JobDetails from './components/JobDetails.vue';
import ToolMachineAdd from './components/tooldie/ToolMachineAdd.vue';
import ToolMachineEdit from './components/tooldie/ToolMachineEdit.vue';
import MaterialDealer from './components/MaterialDealer.vue';
import LoginWithOtp from './components/LoginWithOtp.vue';
import LoginVerify from './components/LoginVerify.vue';
import MachineType from './components/MachineType.vue';
import ContactUs from './components/ContactUs.vue';
import WalletAddMoney from './components/wallet/AddMoney.vue';
import Wallet from './components/wallet/Wallet.vue';
import Transaction from './components/wallet/Transaction.vue';
import Chat from './components/chat/MyChat.vue';
import ChatDetails from './components/chat/MyChatDetails.vue';
import ChatListing from './components/chat/ChatListing.vue';
import ChangePassword from './components/ChangePassword.vue';
import JobSeekerProfile from './components/JobSeekerProfile.vue';
import ContractorProfile from './components/ContractorProfile.vue';
import InstitutionProfile from './components/InstitutionProfile.vue';
import financialInstitutionProfile from './components/financialInstitutionProfile.vue';
import Subscribe from './components/material/Subscribe.vue';

const guest = (to, from, next) => {
	if (!localStorage.getItem("authToken")) {
	  return next();
	} else {
	  return next("/");
	}
  };
  const auth = (to, from, next) => {
	if (localStorage.getItem("authToken")) {
	  return next();
	} else {
	  return next("/login");
	}
  };

export const routes = [
	{
		name: 'Home',
		path: '/',
		component: Home
	},
	{
		name: 'ContactUs',
		path: '/contact-us',
		component: ContactUs
	},
	{
		name: 'login',
		path: '/login',
		beforeEnter: guest,
		component: Login
	},
	{
		name: 'register',
		path: '/register',
		beforeEnter: guest,
		component: Register
	},
	{
		name: 'RegisterVerify',
		path: '/register-verify',
		beforeEnter: guest,
		component: RegisterVerify
	},
	{
		name: 'category',
		path: '/',
		component: AllCategory
	},
	/*{
		name: 'add',
		path: '/add',
		component: AddCategory
	},*/
	{
		name: 'connect',
		path: '/connect',
		component: Connect
	},
	{
		name: 'manpower',
		path: '/manpower',
		props: true,
		component: Manpower
	},
	{
		name: 'manpowerExecutiveCategory',
		path: '/manpower-executives-category',
		props: true,
		component: ManpowerExecutiveCategory
	},
	{
		name: 'manpowerExecutive',
		path: '/manpower-executives',
		props: true,
		component: ManpowerExecutive
	},
	{
		name: 'manpowerExecutiveDetails',
		path: '/manpower-executives-details',
		props: true,
		component: ManpowerExecutiveDetails
	},
	{
		name: 'ManpowerLabourCategory',
		path: '/manpower-labours',
		props: true,
		component: ManpowerLabourCategory
	},
	{
		name: 'manpowerLabour',
		path: '/manpower-labours-view',
		props: true,
		component: ManpowerLabour
	},
	{
		name: 'manpowerLabourDetails',
		path: '/manpower-labours-details',
		props: true,
		component: ManpowerLabourDetails
	},
	{
		name: 'institutionalPlacement',
		path: '/manpower/institutional-placements',
		props: true,
		component: ManpowerInstitutionalPlacement
	},
	{
		name: 'institutionalPlacementDetails',
		path: '/manpower/institutional-placements/details',
		props: true,
		component: ManpowerInstitutionalPlacementDetails
	},
	{
		name: 'Jobs',
		path: '/manpower-jobs',
		beforeEnter: auth,
		component: Jobs
	},
	{
		name: 'JobDetails',
		path: '/manpower-jobs-details',
		beforeEnter: auth,
		component: JobDetails
	},
	{
		name: 'AddTradeProduct',
		path: '/trade-product-add',
		beforeEnter: auth,
		component: AddTradeProduct
	},
	{
		name: 'trade',
		path: '/trade',
		component: Trade
	},
	{
		name: 'educate',
		path: '/educate',
		component: Educate
	},
	{
		name: 'TradeProductDetails',
		path: '/trade-product-details',
		beforeEnter: auth,
		component: TradeProductDetails
	},
	{
		name: 'profile',
		path: '/profile',
		beforeEnter: auth,
		component: Profile

	},
	{
		name: 'Dasboard',
		path: '/dashboard',
		beforeEnter: auth,
		component: Dashboard
	},
	{
		name: 'JobSeekerProfile',
		path: '/job-seeker-profile',
		beforeEnter: auth,
		component: JobSeekerProfile
	},
	{
		name: 'InstitutionProfile',
		path: '/institution-profile',
		beforeEnter: auth,
		component: InstitutionProfile
	},
	{
		name: 'ContractorProfile',
		path: '/contractor-profile',
		beforeEnter: auth,
		component: ContractorProfile
	},
	{
		name: 'financialInstitutionProfile',
		path: '/financial-institution-profile',
		beforeEnter: auth,
		component: financialInstitutionProfile
	},
	{
		name: 'VirginPolymers',
		path: '/trade-product',
		beforeEnter: auth,
		component: TradeProduct
	},
	{ 
		path: '/about-us', 
		component: AboutUs,
		name: 'AboutUS'
	},
	{ 
		path: '/privacy-policy', 
		component: Privacy,
		name: 'PrivacyPolicy'
	},
	{ 
		path: '/terms-of-use', 
		component: Termuse,
		name: 'TermOfUse'
	},
	{
		name: 'BlogCategory',
		path: '/blogs',
		beforeEnter: auth,
		component: BlogCategory
	},
	{
		name: 'Blog',
		path: '/blogs-list',
		props: true,
		beforeEnter: auth,
		component: Blog
	},
	{
		name: 'BlogDetails',
		path: '/blogs-details',
		beforeEnter: auth,
		component: BlogDetails
	},
	{
		name: 'QACategory',
		path: '/qa',
		beforeEnter: auth,
		component: QACategory
	},
	{
		name: 'QA',
		path: '/qa/:id',
		props: true,
		beforeEnter: auth,
		component: QA
	},
	{
		name: 'QADetails',
		path: '/qa/details/:id',
		props: true,
		beforeEnter: auth,
		component: QADetails
	},
	{
		name: 'knowYourPolymer',
		path: '/know-your-polymer',
		beforeEnter: auth,
		component: knowYourPolymer
	},
	{
		name: 'knowYourProduct',
		path: '/know-your-product',
		beforeEnter: auth,
		component: knowYourProduct
	},
	{
		name: 'PolymerProduct',
		path: '/polymer-product',
		beforeEnter: auth,
		component: PolymerProduct
	},
	{
		name: 'PolymerDetails',
		path: '/polymer-product-details',
		beforeEnter: auth,
		component: PolymerDetails
	},
	{
		name: 'Money',
		path: '/money',
		beforeEnter: auth,
		props: true,
		component: Finincial
	},
	{
		name: 'FinacialInstitutionDetails',
		path: '/money-details',
		beforeEnter: auth,
		component: FinacialInstitutionDetails
	},
	{
		name: 'Machine',
		path: '/machine',
		beforeEnter: auth,
		component: Machine
	},
	{
		name: 'MachineType',
		path: '/machine-type',
		beforeEnter: auth,
		component: MachineType
	},
	{
		name: 'InjectionMachine',
		path: '/injection-machine',
		beforeEnter: auth,
		component: InjectionMachine
	},
	{
		name: 'InjectionMachineDetails',
		path: '/injection-machine-details',
		beforeEnter: auth,
		component: InjectionMachineDetails
	},
	{
		name: 'BlowMoldingMachine',
		path: '/blow-molding-machine',
		beforeEnter: auth,
		component: BlowMoldingMachine
	},
	{
		name: 'BlowMoldingMachineDetails',
		path: '/blow-molding-machine-details',
		beforeEnter: auth,
		component: BlowMoldingMachineDetails
	},
	{
		name: 'ExtruderMachine',
		path: '/extruder-machine',
		beforeEnter: auth,
		component: ExtruderMachine
	},
	{
		name: 'ExtruderMachineDetails',
		path: '/extruder-machine-details',
		beforeEnter: auth,
		component: ExtruderMachineDetails
	},
	{
		name: 'ToolDie',
		path: '/tool-and-die',
		beforeEnter: auth,
		component: ToolDie
	},
	{
		name: 'ToolDieDetails',
		path: '/tool-and-die-details',
		beforeEnter: auth,
		component: ToolDieDetails
	},
	{
		name: 'UtilityMachine',
		path: '/utility-machine',
		beforeEnter: auth,
		component: UtilityMachine
	},
	{
		name: 'UtilityMachineDetails',
		path: '/utility-machine-details',
		beforeEnter: auth,
		component: UtilityMachineDetails
	},

	{
		name: 'AuxiliaryMachine',
		path: '/auxiliary-machine',
		beforeEnter: auth,
		component: AuxiliaryMachine
	},
	{
		name: 'AuxiliaryMachineDetails',
		path: '/auxiliary-machine-details',
		beforeEnter: auth,
		component: AuxiliaryMachineDetails
	},
	{
		name: 'MaterialBrand',
		path: '/material-brand',
		beforeEnter: auth,
		component: MaterialBrand
	},
	{
		name: 'Material',
		path: '/material',
		beforeEnter: auth,
		component: Material
	},
	{
		name: 'MaterialDetails',
		path: '/material-details',
		beforeEnter: auth,
		component: MaterialDetails
	},
	{
		name: 'ProductAdd',
		path: '/product-add',
		beforeEnter: auth,
		component: ProductAdd
	},
	{
		name: 'ProductView',
		path: '/product-view',
		beforeEnter: auth,
		component: ProductView
	},
	{
		name: 'MachineAdd',
		path: '/machine-add',
		beforeEnter: auth,
		component: MachineAdd
	},
	{
		name: 'MachineView',
		path: '/machine-view',
		beforeEnter: auth,
		component: MachineView
	},
	{
		name: 'MachineViewBuy',
		path: '/buy-machine',
		beforeEnter: auth,
		component: MachineViewBuy
	},
	{
		name: 'InjectionMachineAdd',
		path: '/injection-molding-machine-add',
		beforeEnter: auth,
		component: InjectionMachineAdd
	},
	{
		name: 'InjectionMachineView',
		path: '/injection-molding-machine-view',
		beforeEnter: auth,
		component: InjectionMachineView
	},
	{
		name: 'MyInjectionMachineDetails',
		path: '/my-injection-molding-machine-details',
		beforeEnter: auth,
		component: MyInjectionMachineDetails
	},
	{
		name: 'InjectionMachineEdit',
		path: '/injection-molding-machine-edit',
		beforeEnter: auth,
		component: InjectionMachineEdit
	},
	{
		name: 'BlowMoldingMachineAdd',
		path: '/blow-molding-machine-add',
		beforeEnter: auth,
		component: BlowMoldingMachineAdd
	},
	{
		name: 'BlowMoldingMachineView',
		path: '/my-blow-molding-machine-view',
		beforeEnter: auth,
		component: BlowMoldingMachineView
	},
	{
		name: 'MyBlowMoldingMachineDetails',
		path: '/my-blow-molding-machine-details',
		beforeEnter: auth,
		component: MyBlowMoldingMachineDetails
	},
	{
		name: 'BlowMoldingMachineEdit',
		path: '/blow-molding-machine-edit',
		beforeEnter: auth,
		component: BlowMoldingMachineEdit
	},
	{
		name: 'ExtruderMachineAdd',
		path: '/extruder-machine-add',
		beforeEnter: auth,
		component: ExtruderMachineAdd
	},
	{
		name: 'ExtruderMachineView',
		path: '/my-extruder-machine-view',
		beforeEnter: auth,
		component: ExtruderMachineView
	},
	{
		name: 'MyExtruderMachineDetails',
		path: '/my-extruder-machine-details',
		beforeEnter: auth,
		component: MyExtruderMachineDetails
	},
	{
		name: 'ExtruderMachineEdit',
		path: '/extruder-machine-edit',
		beforeEnter: auth,
		component: ExtruderMachineEdit
	},
	{
		name: 'ToolDieMachineAdd',
		path: '/tool-die-machine-add',
		beforeEnter: auth,
		component: ToolDieMachineAdd
	},
	{
		name: 'ToolDieMachineView',
		path: '/my-tool-die-machine-view',
		beforeEnter: auth,
		component: ToolDieMachineView
	},
	{
		name: 'MyToolDieMachineDetails',
		path: '/my-tool-die-machine-details',
		beforeEnter: auth,
		component: MyToolDieMachineDetails
	},
	{
		name: 'ToolDieMachineEdit',
		path: '/tool-die-machine-edit',
		beforeEnter: auth,
		component: ToolDieMachineEdit
	},
	{
		name: 'ToolMachineAdd',
		path: '/tool-machine-add',
		beforeEnter: auth,
		component: ToolMachineAdd
	},
	{
		name: 'ToolMachineEdit',
		path: '/tool-machine-edit',
		beforeEnter: auth,
		component: ToolMachineEdit
	},
	{
		name: 'UpdateRole',
		path: '/update-role',
		beforeEnter: auth,
		component: UpdateRole
	},
	{
		name: 'Polymers',
		path: '/polymers',
		beforeEnter: auth,
		component: Polymers
	},
	{
		name: 'CarScrappage',
		path: '/car-scrappage',
		beforeEnter: auth,
		component: CarScrappage
	},
	{
		name: 'KnowCarStatus',
		path: '/know-your-car-status',
		beforeEnter: auth,
		component: KnowCarStatus
	},
	{
		name: 'ScrapCarDealer',
		path: '/scrap-car-dealer',
		beforeEnter: auth,
		component: ScrapCarDealer
	},
	{
		name: 'PolicyUpdate',
		path: '/policy-update',
		beforeEnter: auth,
		component: PolicyUpdate
	},
	{
		name: 'CarFitnessCenter',
		path: '/car-fitness-center',
		beforeEnter: auth,
		component: CarFitnessCenter
	},
	{
		name: 'CarStatus',
		path: '/car-status',
		beforeEnter: auth,
		component: CarStatus
	},
	{
		name: 'MaterialPriceList',
		path: '/download-price-list',
		beforeEnter: auth,
		component: MaterialPriceList
	},
	{
		name: 'MaterialDealer',
		path: '/material-dealer',
		beforeEnter: auth,
		component: MaterialDealer
	},
	{
		name: 'Wallet',
		path: '/my-wallet',
		beforeEnter: auth,
		component: Wallet
	},
	{
		name: 'WalletAddMoney',
		path: '/my-wallet-add-money',
		beforeEnter: auth,
		component: WalletAddMoney
	},
	{
		name: 'Transaction',
		path: '/my-transaction',
		beforeEnter: auth,
		component: Transaction
	},
	{
		name: 'Chat',
		path: '/chat',
		beforeEnter: auth,
		component: Chat
	},
	{
		name: 'ChatListing',
		path: '/chat-user',
		beforeEnter: auth,
		component: ChatListing
	},
	{
		name: 'ChatDetails',
		path: '/chat-details',
		beforeEnter: auth,
		component: ChatDetails
	},
	{
		name: 'LoginWithOtp',
		path: '/login-with-otp',
		beforeEnter: guest,
		component: LoginWithOtp
	},
	{
		name: 'LoginVerify',
		path: '/login-with-otp-verify',
		beforeEnter: guest,
		component: LoginVerify
	},
	{
		name: 'ChangePassword',
		path: '/change-password',
		beforeEnter: auth,
		component: ChangePassword
	},

	{
		name: 'Subscribe',
		path: '/subscribe',
		beforeEnter: auth,
		props: true,
		component: Subscribe
	},
	{ 
		path: '/:catchAll(.*)', 
		component: NotFound,
		name: 'NotFound'
	},


];