<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>Change Password</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <form @submit.prevent="updatePassword">
                                        <div class="form-group row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Old Password *</label>
                                                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.old_password }" v-model="profile.old_password" required>
                                                    <div class="invalid-feedback" v-if="errors.old_password">
                                                        {{ errors.old_password[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>New Password *</label>
                                                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.new_password }" v-model="profile.new_password" required>
                                                    <div class="invalid-feedback" v-if="errors.new_password">
                                                        {{ errors.new_password[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Confirm Password *</label>
                                                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.new_password_confirmation }" v-model="profile.new_password_confirmation" required>
                                                    <div class="invalid-feedback" v-if="errors.new_password_confirmation">
                                                        {{ errors.new_password_confirmation[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        <div class="form-group">
                                            <input type="submit" value="Update" class="btn btn-info">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            profile: {},
            errors:{

            },
            success:null,
            loading: false
        }
    },
    
    methods: {
            updatePassword() {
                this.loading = true
                this.success = null
                this.errors = {}
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/change-password", this.profile)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        window.location.reload();
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            }
        }
}
</script>