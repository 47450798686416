<template>
    <div>
        <div class="main bgF6F9FC">
        <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="home-tit">
                  <h2><span>Executive ({{countryType}})</span></h2>
                </div>
                </div>
              </div>
            <div class="row">
                <div class="col-md-3" v-for="category in categories" :key="category.id">
                    <a @click="show(category.id,category.name)">
                    <div class="cat-itm">
                        <div class="cat-img">
                            <img :src="imagePath+category.image" alt="" class="img-responsive">
                        </div>
                        <div class="cat-tt">
                            <h4>{{ category.name}}</h4>
                        </div>
                    </div>
                  </a>
                </div>
            <!-- <div class="col-md-12">     
                <div class="tab-content">
                <div class="tab-pane active" id="home-v">
                    <div class="col-md-3" v-for="category in categories" :key="category.id">
                    <div class="hm-shop-itm">
                        <router-link v-bind:to="{ name: 'manpowerExecutive', params: { id: category.id }}">
                        <div class="hm-shop-img">
                        <img :src="imagePath+category.image" alt="" class="img-responsive">
                        </div>
                        <div class="hm-shop-cnt">
                        <div class="sp-tt">
                            <h4>{{ category.name}}</h4>
                        </div>
                        </div>
                        
                        </router-link>
                    </div>
                    </div>

                </div>
                </div>
            </div> -->
            </div>
        </div>
        </div>
        
    </div>
</template>

<script>
export default {
    
    data() {
        return {
            categories: [],
            countryType: '',
            imagePath: process.env.VUE_APP_API_URL
        }
    },
    created() {
        this.countryType = localStorage.getItem("ManpowerCountryType");
        this.axios
            .get(process.env.VUE_APP_API_URL+"api/executive-manpower-categories?country_type="+localStorage.getItem("ManpowerCountryType"))
            .then(response => {
                this.categories = response.data['executive_manpower_category'];

            
            });
    },
    methods: {
        show(id,name){
            localStorage.setItem("executiveCategory", id);
            localStorage.setItem("executiveCategoryName", name);
              this.$router.push({ name: "manpowerExecutive" });
        }

    }
};
</script>

