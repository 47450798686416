<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../assets/images/loader.gif" alt="" />
            </div>
        </div>
<div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>Institution Profile</h3>
                        </div>
      <div class="tab-content">
          <div class="tab-pane active" id="home-v" v-for="institute in institutes" :key="institute.id">
            <div class="col-md-4">
              <div class="cnd-main">
                <div class="cad-img">
                  <img :src='imagePath+institute.logo' class="img-responsive">
                </div>
                
              </div>
            </div>
            <div class="col-md-8">
              <div >
        <div class="packs-contant">
          <h3 style="margin-top:0;">{{ institute.institute_name }}</h3>
          <ul>
                    <li><i class="fa fa-mobile"></i> {{ institute.contact_no }}</li>
                    <li><i class="fa fa-envelope"></i> {{ institute.email }}</li>
                  </ul>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Establishment Year</td>
              </tr>
             <tr >
              <td colspan="2">{{ institute.establishment }}</td>
            </tr>
             <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Address</td>
              </tr>
              <tr >
              <td colspan="2">{{ institute.address }}</td>
            </tr>
            <tr>
              <th>City</th>
              <td>{{ institute.city}} </td>
            </tr>
            
            <tr> 
              <th>State</th>
              <td>{{ institute.state}}</td>
            </tr>
            <tr> 
              <th>Pincode</th>
              <td>{{ institute.pin_code }}</td>
            </tr>
            <tr> 
              <th>Country</th>
              <td>{{ institute.country.name }}</td>
            </tr>
          
              <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Student Count Details</td>
              </tr>
             
            <tr v-for='student_count in JSON.parse(institute.student_count)' :key="student_count.course">
                <td colspan="3">
                    <table>
                <tr>
              <td>{{ student_count.course}} <br>{{ student_count.specialization}} </td>
                    <td>{{ student_count.student}}</td>
                    <td>{{ student_count.date | formatDate}}</td>
                    </tr>
                    </table>
                </td>
            </tr>
            
            
            </tbody>
          </table>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>

    </div>
</template>

<script>
  import SideBar from './SideBar.vue';

export default {
  components: {
        'sidebar': SideBar
    },
 props: ['details'],
    data() {
        return {
            institutes: [],
            imagePath: process.env.VUE_APP_API_URL,
            showInfo:false,
            showInfoButton:true,
            loading: false,
            success:null,
        }
    },
    created() {
        this.axios
            .get(process.env.VUE_APP_API_URL+'api/my-institution-placement-details?id=10')
            .then(response => {
                this.institutes = response.data.institutes;
            });
    }
}
</script>