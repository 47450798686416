<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Utility Machine</span></h2>
  <div class="container">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
          <input type="text" v-model="keyword" v-on:input="searchProduct" name="search" class="form-control" placeholder="search e.g. Company Location Size">
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="utilityMachines.length">
      <div class="col-md-3" v-for="(utilityMachine , index) in utilityMachines" v-bind:key="index">
        <div class="grid-item" v-if="utilityMachine">
          <a @click="viewProduct(utilityMachine.id, utilityMachine.machine_size)">
          <div class="imgholder ">
              <img :src="imgPath+utilityMachine.image1">
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ utilityMachine.sellercompany_name }} </span>
              <div class="product-location-grid">{{ utilityMachine.city }} , {{ utilityMachine.country.name }}</div>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid">{{ utilityMachine.name }}</div>
              <div>Rs.{{ utilityMachine.price_from }}- Rs.{{ utilityMachine.price_from }}</div>
              
            </div>
          </div>
          </a>
        </div>
        <div v-else>Record Not Found</div>
      </div>
    </div>
   <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            utilityMachines: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            param : '',
            keyword : null,
            machinetype : ''
        }
    },
    created() {
        this.loading = true
        this.machinetype = sessionStorage.getItem("machineType");
        if(this.machinetype == '')
        {
          this.$router.push({ name: "MachineType" });
        }
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/utility-machine-list?type="+sessionStorage.getItem("machineType"))
          .then(response => {
            this.utilityMachines = response.data.utility_machines;
        })
        .finally(() => this.loading = false);
        
    },
    methods: {
            viewProduct(id,size) {
              sessionStorage.setItem("utilityMachine", id);
              sessionStorage.setItem("utilityMachineSize", size);
              this.$router.push({ name: "utilityMachineDetails" });
            },
            searchProduct() {
              this.loading = true
              this.axios
              .get(process.env.VUE_APP_API_URL+"api/utility-machine-list?country="+this.keyword+"&type="+sessionStorage.getItem("machineType"))
                .then(response => {
                  this.utilityMachines = response.data.utility_machines;
              })
              .finally(() => this.loading = false);
            }

        }
};
</script>

<style>
  #exzoom {
    width: 460px;
  }
  .hidden { display: none; }
</style>