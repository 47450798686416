<template>
    <div>
       <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div> 
<div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12">     
        <div class="tab-content">
          <div class="tab-pane active" id="home-v">
            <div class="col-md-8" v-for="(details , index) in polymerDetails" v-bind:key="index">
              <div class="cnd-main">
                <div class="cad-img">
                  <img :src='imagePath+details.image' class="img-responsive">
                </div>
                <div class="cad-cnt">
                  <div class="cnd-tt">
                    <h3>{{ details.name }}</h3>
                  </div>
                  <ul>
                    <li><b>Brand:</b> {{ details.brand }}</li>
                    <li><b>Mfg Year:</b> {{ details.mfg_year }}</li>
                  </ul>
                </div>
              </div>
              <div class="candidate-cnt">
                <div class="candidate-ditem">
                  <div class="candidate-ditem-tt">
                    
                  </div>
                  <div class="candidate-ditem-dd" >
                     <table class="display nowrap table table-hover table-striped table-bordered" cellspacing="0" width="100%">
                      <thead>
                        <th colspan="2" style="background-color:green;color:white"><h4>Parts</h4></th>
                      </thead>
                       <tr v-for="(parts , index1) in JSON.parse(details.parts)" :key="index1">
                         <td width="40%"><br><img :src='imagePath+parts.image' width="280" class="img-responsive"></td>
                         <td>{{ parts.description}}<br>{{ parts.category}}</td>
                         <br>
                       </tr>
                     </table>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            polymerDetails: [],
            imagePath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
      this.loading = true
      this.axios
        .get(process.env.VUE_APP_API_URL+"api/polymer-product-details?id="+sessionStorage.getItem("polymerId"))
        .then(response => {
            this.polymerDetails = response.data.polymer_products;
        })
        .finally(() => this.loading = false);
    }
};
</script>
<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>