<template>
  <div>
    <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>{{ brand}} Dealers</span></h2>
          
        </div>
      </div>
    </div>
    <div class="row" v-if="materialDealers.length">
      <div class="col-md-3" v-for="(materialDealer , index) in materialDealers" v-bind:key="index">
        <div class="grid-item" v-if="materialDealer">
          <div class="hm-shop-img1 ">
            <img src="../assets/img/usericon.png">
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ materialDealer.dealer }} </span>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid">{{ materialDealer.mobile }}<br> {{ materialDealer.email }}</div>
              <div>{{ materialDealer.address }} </div>
            </div>
          </div>
        </div>
        <div v-else>No dealer found at this location.</div>
      </div>
    </div>
    <div v-else><center><b><br>No dealer found at this location.</b><br><br></center></div>
  </div>
</div>
<div class="cl"></div>
  </div>
</template>
<script>
export default {
  data() {
        return {
            materialDealers: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            param : '',
            brand : ''
        }
    },
    created() {
        this.loading = true
        this.brand = sessionStorage.getItem("materialBrandName");
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/connect-material-dealer-citybrandwise?brand_id="+sessionStorage.getItem("material")+"&city="+sessionStorage.getItem('materialCity'))
          .then(response => {
            this.materialDealers = response.data.dealers;
        })
        .finally(() => this.loading = false);
    }
};
</script>

<style>
  #exzoom {
    width: 460px;
  }
  .hidden { display: none; }
  .hm-shop-img1 {
    width: 28%;
    margin-right: 8px;
    margin-left: 10px;
  }
</style>