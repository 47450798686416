<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		<div class="filter">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="fltr">
          <i class="fa fa-sliders"></i> <span>Filter</span>
        </div>
        <div class="btn-group dropdown-filter">
          <select v-model="filters.brand" class="form-control" @change="brandFilter()">
            <option value="">Select Brand</option>
            <option v-for="brand in brands" :value="brand.brand" :key="brand.brand">{{ brand.brand }}</option>
          </select>
          <input type="hidden" name="polymer_industry_id" v-model="filters.polymer_industry_id">
        </div>
        <div class="btn-group dropdown-filter" style="margin-left: 10px;">
          <select v-model="filters.year" class="form-control" @change="yearFilter()">
            <option value="">Select Mfg Year</option>
            <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
          </select>
        </div>
        
       </div>
    </div>
  </div>
</div>
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12" v-if="filtersData.length">
        <ul class="filterC">
          <li><button @click="clearFilter()">Clear All </button></li>
          <li v-for="(filtersData1, index) in filtersData" v-bind:key="index">{{ filtersData1.name }} <button class="badge badge-danger" @click="removeFilter(index)">X</button></li>
        </ul>
      </div>
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>{{category}}</span></h2>
        </div>
      </div>
    </div>
    <div class="row" v-if="products.length">
      <div class="col-md-3" v-for="(product , index) in products" v-bind:key="index">
        <div class="grid-item" v-if="product">
          <div class="imgholder ">
            <a @click="viewProduct(product.id)">
              <img :src="imgPath+product.image">
            </a>
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ product.name }} </span>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid">{{ product.brand }}</div>
              <div> Mfg Year: {{ product.mfg_year }}</div>
            </div>
          </div>
        </div>
        <div v-else>Record Not Found</div>
      </div>
      
    </div>
    <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            products: [],
            brands: [],
            filtersData: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            name: sessionStorage.getItem("industryName"),
            filters:{
              brand:'',
              year:'',
              polymer_industry_id:'',
            },
            brand:'',
            year:'',
            category: sessionStorage.getItem("industryName"),
        }
    },
    computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 1998}, (value, index) => 2000 + index)
    }
  },
    created() {
        this.loading = true
        
          this.filters.polymer_industry_id = sessionStorage.getItem("industryId");
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/polymer-product?industry_id="+sessionStorage.getItem("industryId"))
        .then(response => {
            this.products = response.data.polymer_products;
        })
        .finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/polymer-product-brand?polymer_industry_id="+sessionStorage.getItem("industryId"))
        .then(response => {
            this.brands = response.data.brands;
        });
    },
    methods: {
            viewProduct(id) {
              sessionStorage.setItem("polymerId",  id);
              this.$router.push({ name: "PolymerDetails" });
            },
            brandFilter(){
              if(this.filters.brand!=''){
              this.filtersData.push({ type: 'Brand', name:this.filters.brand });
              }
              this.filtersData = this.filtersData.filter((v,i,a)=>a.findIndex(t=>(t.name===v.name))===i)
              this.filter();

            },
            yearFilter(){
              if(this.filters.year!=''){
              this.filtersData.push({ type: 'Year', name:this.filters.year });
              }
              this.filtersData = this.filtersData.filter((v,i,a)=>a.findIndex(t=>(t.name===v.name))===i)
              this.filter();
            },
            removeFilter(index){
              this. filtersData.splice(index, 1);
              this.filter();
            },
            filter() {
              
                this.filters.year = '';
                this.filters.brand = '';
                this.year = '';
                this.brand = '';

              for(var i=0;i<this.filtersData.length; i++)
              {
                if(this.filtersData[i].type=='Year')
                {
                  this.year = this.year+','+this.filtersData[i].name;
                }
                if(this.filtersData[i].type=='Brand')
                {
                  this.brand = this.brand+','+this.filtersData[i].name;
                }
              }
              this.products= []

              this.axios
        .get(process.env.VUE_APP_API_URL+"api/polymer-product-filter?polymer_industry_id="+this.filters.polymer_industry_id+"&brand="+this.brand+"&year="+this.year)
        .then(response => {
            this.products = response.data.polymer_products;
        })
        .finally(() => this.loading = false);
            },
            clearFilter(){
              this.filtersData = [];
              this.filter();
            }
        }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>