<template>
  <div>
    <div class="side-bar" v-for="userpro in user" v-bind:key="userpro.name">
  <div class="side-bar-title">
    <div class="profile-pic">
      <img src="../assets/img/usericon.png" class="img-responsive">
    </div>
    <div class="hadding-title">
      <h4><small>Hello</small><br> <span v-for="userpro in user" v-bind:key="userpro.name">{{(userpro.name)}}</span></h4>
      <!-- <a href="#"><small>Edit Photo</small></a> -->
    </div>
  </div>
  <ul id="accordion" class="accordion">
    <li><router-link to="dashboard"><i class="fa fa-tachometer" ></i> Dashboard</router-link></li>
    <li><router-link to="update-role"><i class="fa fa-users"></i> Role Update</router-link></li>

    <li><router-link to="/profile"><i class="fa fa-user-o"></i> My Profile</router-link></li>
    <li><router-link to="/my-wallet"><i class="fa fa-users"></i> My Wallet</router-link></li>
    <li><router-link to="/chat"><i class="fa fa-users"></i> My Chat</router-link></li>
    <li><router-link to="/my-transaction"><i class="fa fa-users"></i> My Transaction</router-link></li>
    <li v-if="material_seller"><router-link to="/product-add" class="has-arrow"><i class="fa fa-th-large"></i> Product Add</router-link></li>
    <li v-if="material_seller"><router-link to="/product-view" class="has-arrow"><i class="fa fa-th-large"></i> Product View</router-link></li>
    <li v-if="machine_vendor"><router-link to="/machine-add" class="has-arrow"><i class="fa fa-exclamation-circle"></i> Machine Add</router-link></li>
     <li v-if="machine_vendor"><router-link to="/machine-view" class="has-arrow"><i class="fa fa-exclamation-circle"></i> Machine View</router-link></li>
    <li v-if="job_seeker"><router-link to="/job-seeker-profile" class="has-arrow"><i class="fa fa-gg"></i>Your Job Seeker Profile</router-link></li>
    <li v-if="contractor"><router-link to="/contractor-profile" class="has-arrow"><i class="fa fa-dot-circle-o"></i>Your Contractor Profile</router-link></li>
    <li v-if="institutes"><router-link to="/institution-profile" class="has-arrow"><i class="fa fa-retweet"></i> Your Institution Profile</router-link></li>
    <li v-if="financial_institutation"><router-link to="/financial-institution-profile" class="has-arrow"><i class="fa fa-th-large"></i>Your Financial Institution Profile</router-link></li>
    <!-- <li v-if="job_provider"><router-link to="/post-job" class="has-arrow"><i class="fa fa-th-large"></i> Post a Job</router-link></li> -->
    
    <li><router-link to="/change-password"><i class="fa fa-key"></i> Change Password</router-link></li>
    <li><a @click="logout"><i class="fa fa-power-off"></i> Logout</a></li>
  </ul>
</div>
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data(){
    return{
      userRole:'',
      material_seller:'',
      machine_vendor:'',
      job_seeker:'',
      job_provider:'',
      buyer:'',
      institutes:'',
      contractor:'',
      financial_institutation:''

    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },

  mounted() {
    //this.userRole = (user[0].user_type).split(",");
  },
  created() {
    this.axios
        .get(process.env.VUE_APP_API_URL+"api/profile")
        .then(response => {
            this.userRole = response.data.profile[0].user_type.split(",");
            this.material_seller = this.userRole.includes('Material Seller')
            this.machine_vendor = this.userRole.includes('Machine Vendor')
            this.job_provider = this.userRole.includes('Job Provider')
            this.buyer = this.userRole.includes('Buyer')
            this.institutes = this.userRole.includes('Institutes')
            this.contractor = this.userRole.includes('Contractor')
            this.financial_institutation = this.userRole.includes('Financial Institutation')
            this.job_seeker = this.userRole.includes('Job Seeker')
        })
    //this.userRole = (this.user1).split(",");
  },
  methods: {
    ...mapActions("auth", ["sendLogoutRequest", "getUserData"]),

    logout() {
      this.sendLogoutRequest().then(() => {
            this.$router.push({ name: "login" });
      });
    }
  }
};
</script>