<template>
    <div>
       <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div> 
<div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12">     
        <div class="tab-content">
          <div class="tab-pane active" id="home-v">
            <div class="col-md-8" v-for="(blog , index) in blogs" v-bind:key="index">
              <div class="cnd-main">
                <div class="cad-img">
                  <img :src='imagePath+blog.image' class="img-responsive">
                </div>
                <div class="cad-cnt">
                  <div class="cnd-tt">
                    <h3>{{ blog.title }}</h3>
                  </div>
                  <ul>
                    <li><b>Writer:</b> {{ blog.writer }}</li>
                    <li><b>Piblish Date:</b> {{ blog.created_at | formatDate }}</li>
                    <li><b>Category:</b> {{ blog.blog_category.name }}</li>
                  </ul>
                </div>
              </div>
              <div class="candidate-cnt">
                <div class="candidate-ditem">
                  <div class="candidate-ditem-tt">
                    <h4>Details</h4>
                  </div>
                  <div class="candidate-ditem-dd" >
                     <p v-html="blog.content"></p>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            blogs: [],
            imagePath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
      this.loading = true
        this.axios
            .get(process.env.VUE_APP_API_URL+'api/blogs/details?id='+this.$route.params.id)
            .then(response => {
                this.blogs = response.data.blogs;
            })
            .finally(() => this.loading = false);
    }
};
</script>
<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>