<template>
    <div>
      <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
<div class="main">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
          <div class="home-tit">
            <h2><span>{{ categoryName }} Blogs</span></h2>
          </div>
          <div class="hom-event">
            <div class="hom-eve-com hom-eve-lhs" v-if="blogs.length">
              <div class="hom-eve-lhs-1 col-md-4" v-for="(blog , index) in blogs" v-bind:key="index">
                <div class="eve-box">
                  <div>
                    <a @click="viewBlog(blog.id)">
                      <img :src="imgPath+blog.image" alt="">
                      <span>{{ blog.created_at | formatDate }}</span>
                    </a>
                  </div>
                  <div>
                    <h4><a @click="viewBlog(blog.id)">{{ blog.title }}</a></h4>
                    <span>{{ blog.writer }}</span>
                  </div>
                </div>
              </div>

            </div>
            <div v-else><center><b>No Data Found</b></center></div>
          </div>
        </div>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>
<script>
export default {
        
    data() {
        return {
            blogs: [],
            imgPath: process.env.VUE_APP_API_URL,
            imgPathLocal: process.env.VUE_APP_URL,
            loading: false,
            categoryName: ''
        }
    },
    created() {
        this.loading = true
        this.categoryName = localStorage.getItem("blogCategoryName");
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/blogs?blog_category="+localStorage.getItem("blogCategory"))
        .then(response => {
            this.blogs = response.data.blogs;
        })
        .finally(() => this.loading = false);
        
    },
    methods: {
            viewBlog(id) {
              sessionStorage.setItem("blogId", id);
              this.$router.push({ name: "BlogDetails" });
            }
        }
};
</script>
<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>