<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>My Products</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="row">
      <div class="col-md-3" v-for="(product , index) in products" v-bind:key="index">
        <div class="grid-item" v-if="product">
          <div class="imgholder ">
            <a @click="viewProduct(product.id, product.category_id)">
              <img :src="imgPath+product.image1">
            </a>
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">Reference ID: {{ product.id }}<br>{{ product.name }} </span>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid"><i class="fa fa-inr"></i> {{ product.price }} {{ product.currency }} / {{ product.price_basis}} </div>
              <div class="product-location-grid">{{ product.city }} , {{ product.state }}, {{ product.country.name }}</div>
            </div>
          </div>
        </div>
        <div v-else>Record Not Found</div>
      </div>
      
    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            products: {},
            imgPath: process.env.VUE_APP_API_URL,
            errors:{

            },
            success:null,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/my-trade-product")
        .then(response => {
            this.products = response.data.products;
        })
        .finally(() => this.loading = false);
    },
    methods: {
            updateProfile() {
                this.loading = true
                this.success = null
                this.errors = {}
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/profile-update", this.profile)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        this.$router.go
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            }
        }
}
</script>