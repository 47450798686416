<template>
    <div>
      <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="cl"></div>
<div class="main">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
          <div class="home-tit">
            <h2><span>QA</span></h2>
          </div>

          <div class="hom-event">
            <div class="hom-eve-com hom-eve-lhs">
              <!--<div class="hom-eve-lhs-1 col-md-4" v-for="(qa , index) in qas" v-bind:key="index">
                <div class="eve-box">
                  <div>
                    <router-link v-bind:to="{ name: 'QADetails', params: { id: qa.id }}">
                      <img :src="imgPath+qa.questionByPhoto" alt="">
                      <span>{{ qa.date | formatDate }}</span>
                    </router-link>
                  </div>
                  <div>
                    <h4><router-link v-bind:to="{ name: 'QADetails', params: { id: qa.id }}">{{ qa.question }}</router-link></h4>
                    <span class="addr">{{ qa.questionBy }}</span>
                  </div>
                </div>
              </div> -->
              <div>
                <div class="profl-sec">
                  <div class="profile-details">
                    <div class="col-md-12 p_t_10">
                      <div class="chat-main">
                        <div v-for="(qa , index) in qas" v-bind:key="index">
                        <a href="Q&A-details.php">
                          <div class="chat-itam">
                          <div class="chat-img">
                            <img :src="imgPath+qa.questionByPhoto">
                          </div>
                          <div class="chat-cnt">
                            <h4>{{ qa.questionBy }}</h4>
                            <h5><strong>Ques :</strong> {{ qa.question }}</h5>
                            <p><strong>Ans :</strong> Lorem Ipsum Is Simply Dummy Text Of The</p>
                          </div>
                          <div class="chat-time">
                            <span class="btn btn-success">View More</span>
                            <span class="btn btn-info">Post Your Answer</span>
                          </div>
                        </div></a>
                      </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>
<script>
export default {
        
    data() {
        return {
            qas: [],
            imgPath: process.env.VUE_APP_API_URL,
            imgPathLocal: process.env.VUE_APP_URL,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/qa?category_id="+this.$route.params.id)
        .then(response => {
            this.qas = response.data.qa;
        })
        .finally(() => this.loading = false);
        
    }
};
</script>
<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>