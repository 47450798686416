<template>
    <div>
        <div class="cl"></div>
<div class="main">
  <div class="container">
    <div class="row">
      <div class="col-md-12"> 
            <div class="home-tit">
                <h2><span>Know Your Polymer</span></h2>
                <div class="container">
                    <div class="row">
                      <div class="col-md-3"></div>
                      <div class="col-md-6">
                          <input type="text" v-model="keyword" v-on:input="searchPolymer" name="search" class="form-control" placeholder="search">
                      </div>
                      <div class="col-md-2"></div>
                    </div>
                </div>
            </div>    
                <div class="tab-content">
                <div class="tab-pane active" id="home-v" v-if="polymers.length">
                    <div class="col-md-3" v-for="polymer in polymers" :key="polymer.id">
                    <div class="hm-shop-itm-polymer">
                        <a @click="viewPolymer(polymer.id)">
                        <div class="sp-tt">
                            <h4>{{ polymer.name}}</h4>
                        </div>
                        
                        </a>
                    </div>
                    </div>

                </div>
                <div v-else><center><b>No Data Found</b></center></div>
                </div>
            </div>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
      data() {
        return {
            polymers: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            keyword: null
        }
    },
   
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/polymers")
        .then(response => {
            this.polymers = response.data.polymers;
        })
        .finally(() => this.loading = false);
        
    },
    methods: {
            viewPolymer(id) {
              sessionStorage.setItem("polymersId",  id);
              this.$router.push({ name: "Polymers" });
            },
            searchPolymer() {
              this.loading = true
              this.axios
              .get(process.env.VUE_APP_API_URL+"api/polymers-search?keyword="+this.keyword)
                .then(response => {
                  this.polymers = response.data.polymers;
              })
              .finally(() => this.loading = false);
            }
           
        }
  
};
</script>
<style type="text/css">
    .hm-shop-itm-polymer {
    display: block;
    flex-wrap: wrap;
    padding: 15px;
    border: solid 1px #eee;
    margin-bottom: 25px;
    border-radius: 4px;
    box-shadow: 0 8px 26px 0 rgba(0,0,0,.08);
    background-color: #fff;
}
</style>