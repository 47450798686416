<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
<div class="cl"></div>
<div class="trade-sec">
  <div class="container" v-for="(brand , index) in brands" v-bind:key="index">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>{{ brand.name}} Materials</span></h2>
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-6">
          <input type="text" v-model="keyword" v-on:input="searchMaterial" name="search" class="form-control" placeholder="search e.g. Name Category Grade">
      </div>
      <div class="col-md-1"> <button class="btn btn-success" @click="dealer(brand.name)">Find Dealer</button></div>
      <div class="col-md-2"><button class="btn btn-warning">Compare ({{ compareItem.length }}
)</button></div>
    </div>
  </div>
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-md-3" v-for="(material , index) in materials" v-bind:key="index">
        <div class="grid-item" v-if="material">
          <div class="imgholder ">
            <a @click="viewDetails(material.id)">
              <img :src="imgPath+material.image1" v-if="material.image1">
              <img :src="imgPath+brand.image" v-else>
            </a>
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ material.name }} </span>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid">{{ material.product_category.name }}</div>
              <div>{{ material.grade }}</div>
              <div>{{ material.polymer_type }}</div>
              <div><button class="btn btn-success" @click="compare(material.id)">Add to compare</button><button class="btn btn-danger" @click="compareRemove(material.id)">Remove from compare</button></div>
            </div>
          </div>
        </div>
        <div v-else>Record Not Found</div>
      </div>
      
    </div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            materials: [],
            brands: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            compareItem: [],
            keyword:null
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/connect-materials?brand_id="+sessionStorage.getItem("material"))
          .then(response => {
            this.materials = response.data.connect_materials.data;
            this.brands = response.data.brands;
        })
        .finally(() => this.loading = false);
        var existing = localStorage.getItem('materialCompare');
              existing = existing ? existing.split(',') : [];
              this.compareItem = existing;
    },
    methods: {
            viewDetails(id) {
              sessionStorage.setItem("materialId", id);
              this.$router.push({ name: "MaterialDetails" });
            },
            dealer(name) {
              sessionStorage.setItem("materialBrandName", name);
              this.$router.push({ name: "MaterialDealer" });
            },
            compare(id) {
              var existing = localStorage.getItem('materialCompare');
              existing = existing ? existing.split(',') : [];
              var total = existing.length;
              if(total>2){
                alert('You can Compare upto 3 items');
              }else{
              existing.push(id);
              localStorage.setItem('materialCompare', existing.toString());
              }
              this.compareItem = existing;
              //this.$router.push({ name: "MaterialDetails" });
            },
            compareRemove(id) {
              var ids = "'"+id+"'";
              var existing = localStorage.getItem('materialCompare');
              existing = existing ? existing.split(',') : [];
              const index = existing.indexOf(ids);
              //if (index > -1) {
                console.log(index);
                console.log(ids);
                this.$delete(existing, id);
                //existing.splice(index, 1);
                console.log(existing);
              //}
              localStorage.setItem('materialCompare', existing.toString());
              this.compareItem = existing;
            },
            searchMaterial() {
              this.loading = true
              this.axios
              .get(process.env.VUE_APP_API_URL+"api/connect-materials?brand_id="+sessionStorage.getItem("material")+"&keyword="+this.keyword)
                .then(response => {
                  this.materials = response.data.connect_materials;
              })
              .finally(() => this.loading = false);
            }
        }
};
</script>

<style>
  #exzoom {
    width: 460px;
  }
  .hidden { display: none; }
</style>