<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
        <div class="main bgF6F9FC">
        <div class="container">
            <div class="row">
            <div class="col-md-12"> 
            <div class="home-tit">
            <h2><span>Know Your Product Composition</span></h2>
          </div>    
                <div class="tab-content">
                <div class="tab-pane active" id="home-v">
                    <div class="col-md-3" v-for="category in categories" :key="category.id">
                    <div class="hm-shop-itm">
                        <a @click="viewProduct(category.id, category.name)" style="display: flex;">
                        <div class="hm-shop-img" style="width: 28%;height: 40px;overflow: hidden;">
                        <img :src="imagePath+category.image" alt="" class="img-responsive">
                        </div>
                        <div class="hm-shop-cnt">
                        <div class="sp-tt">
                            <h4 style="display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;">{{ category.name}}</h4>
                        </div>
                        </div>
                        
                        </a>
                    </div>
                    </div>

                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        
    </div>
</template>

<script>
export default {
    
    data() {
        return {
            categories: [],
            imagePath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
            .get(process.env.VUE_APP_API_URL+"api/polymer-industries")
            .then(response => {
                this.categories = response.data['polymer_industries'];
            })
            .finally(() => this.loading = false);
    },
    methods: {
            viewProduct(id,name) {
              sessionStorage.setItem("industryId", id);
              sessionStorage.setItem("industryName", name);
              this.$router.push({ name: "PolymerProduct" });
            }
        }
};
</script>
<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
</style>