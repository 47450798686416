<template>
    <div>
        <div class="cl"></div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
             <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                        <div class="profl-sec">
                            <div class="h3-title">
                                <h3>Role Update</h3>
                            </div>
                            <div class="row">
                               <div class="">
      <div class="modal-header">
        <h4 class="modal-title">Choose You Role </h4>
        <p>You can choose Multile Role</p>
      </div>
      <div class="modal-body choice-modal" >
         <div class="container-fluid">
          <div class="row inner-scroll" >
            <div class="alert alert-success text-center" v-if="success">
                                        {{ success }}
            </div>
            <div class="invalid-feedback" v-if="errors">
                <div class="alert alert-danger">
                    <ul>
                        <li v-for="error in errors" :key="error">
                            {{ error }}
                        </li>
                    </ul>                    
            </div>
            </div>
               <form @submit.prevent="updateRole">
                <div class=" col-md-3" v-for="roleList in roleLists" :key="roleList.name">
                  <div class="gallery-card">
                    <div class="gallery-card-body">
                      <label class="block-check">
                     <img :src="imgPath+roleList.image" />
                     <input type="checkbox" :id="roleList.name" v-bind:value="roleList.name" v-model="role">
                      <span class="checkmark"></span>
                      </label>
                       <div class="mycard-footer">
                        <a href="#" class="card-link">{{ roleList.name }}</a>
                        </div>
                    </div>
                  </div>
                </div>
                <br>
                <div class="col-md-12">
                    <button type="submit" class="btn btn-primary" data-dismiss="modal">Submit</button> 
                </div>
              
              </form> 
      </div>
      <div class="">
        
      </div>
    </div>

  </div>
</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './SideBar.vue';
export default {
    components: {
        'sidebar': SideBar
    },
    data() {
        return {
            roleLists: [],
            role : [],
            roles:'',
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            success:null,
            errors:null,
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/role-list")
        .then(response => {
            this.roleLists = response.data.role_list;
        })
        .finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/profile")
        .then(response => {
            this.role = (response.data.profile[0].user_type).split(",");
            console.log(response.data.profile[0].user_type);
        })
    },
    methods: {
            updateRole() {
                this.loading = true
                this.success = null
              this.errors = null
              this.roles = this.role.join(',')
             this.axios
                    .post(process.env.VUE_APP_API_URL+"api/user-role-update?role="+this.roles)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        window.location.reload();
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            
        }
};
</script>
<style type="text/css">
    a {
    color: #fff;
    text-decoration: none;
}
a:hover {
    color: #fff;
    text-decoration: none;
}
/*--choice modal1--*/

.openbtn{
    margin-top:80px;
}
.modal-header {
    padding: 15px;
    text-align: center;
     border-bottom: none;
}
.modal-title{
    font-weight:bold;
}
.modal-body.choice-modal{
    position: relative;
    padding: 0px;

}

.row.inner-scroll {
    height: auto;
    overflow: auto;
}

.mycard-footer {
    /*height: 25px;*/
    text-align: center;
    
    /*text-indent: 10px;
    border-radius: 0 0px 4px 4px;*/
}
.mycard-footer a{color: #222;font-size: 16px;}
.gallery-card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 15px;
        height: 160px;
        margin-bottom:14px;
}
.gallery-card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    /*padding: 1.25rem;*/
}
.gallery-card img {
    padding: 30px;
}
label{
    margin-bottom: 0 !important;
}
/*--checkbox--*/

.block-check {
    display: block;
    position: relative;
   
    height: 110px;
    text-align: center;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.block-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 25px;
    width: 25px;
    background-color: #eee;
    cursor: pointer;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.block-check:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.block-check input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.block-check input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.block-check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


/*--checkbox css end--*/
</style>