<template>
  <div>
    <div class="cl"></div>
    <div class="profile-sec">
      <div class="container-fluid">
        <div class="row">
         <div class="col-md-2 zero col-xs-12">
          <sidebar/>
        </div>
        <div class="col-md-10 col-xs-12">
          <div class="profl-sec">
            <div class="h3-title">
              <h3>Dashboard</h3>
            </div>
            <div class="row">
              <div class="col-md-12 zero">
                <div class="profile-details">
                  <div class="col-md-12 p_t_10">
                    <div>
                      <div v-if="material_seller">
                        <div class="col-md-3">
                          <router-link to="/product-view">
                            <div class="card1 bg-primary p-20">
                              <div class="media widget-ten">

                                <div class="media-body media-text-right">
                                  <h2 class="color-white">{{profile.totalProducts}}</h2>
                                  <p class="m-b-0">Listed Product</p>
                                </div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                        <div class="col-md-3">
                          <div class="card1 bg-danger p-20">
                            <div class="media widget-ten">
                              
                              <div class="media-body media-text-right">
                                <h2 class="color-white">{{profile.trade_product_leads}}</h2>
                                <p class="m-b-0">Product Intrested Buyer</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div v-if="machine_vendor">
                        <div class="col-md-3">
                          <router-link to="/machine-view">
                            <div class="card1 bg-success p-20">
                              <div class="media widget-ten">
                                <div class="media-body media-text-right">
                                  <h2 class="color-white">{{profile.total_machine}}</h2>
                                  <p class="m-b-0">Listed Machines</p>
                                </div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                        <div class="col-md-3">
                          <div class="card1 bg-danger p-20">
                            <div class="media widget-ten">
                              <div class="media-body media-text-right">
                                <h2 class="color-white">{{profile.blow_molding_machine_leads}}</h2>
                                <p class="m-b-0">Machine Intrested Buyer</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>

                      <div v-if="job_provider">
                        <div class="col-md-3">
                          <div class="card1 bg-primary p-20">
                            <div class="media widget-ten">
                              <div class="media-body media-text-right">
                                <h2 class="color-white">{{profile.totalJob}}</h2>
                                <p class="m-b-0">Listed Jobs</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="card1 bg-danger p-20">
                            <div class="media widget-ten">
                              <div class="media-body media-text-right">
                                <h2 class="color-white">{{profile.jobLeads}}</h2>
                                <p class="m-b-0">Intrested Candidates</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div v-if="job_seeker">
                        <router-link to="/job-seeker-profile">
                        <div class="col-md-3">
                          <div class="card1 bg-pink p-20">
                            <div class="media widget-ten">
                              <div class="media-body media-text-right">
                                <h2 class="color-white">&nbsp;</h2>
                                <p class="m-b-0">Manage Your Job Seeker Profile</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </router-link>
                        <div class="col-md-3">
                          <div class="card1 bg-success p-20">
                            <div class="media widget-ten">
                              <div class="media-body media-text-right">
                                <h2 class="color-white">{{profile.executiveLeads}}</h2>
                                <p class="m-b-0"> Intrested Employer</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="contractor">
                        <div class="col-md-3">
                          <router-link to="/contractor-profile">
                          <div class="card1 bg-primary p-20">
                            <div class="media widget-ten">
                             <div class="media-body media-text-right">
                                <h2 class="color-white"></h2>
                                <p class="m-b-0">Manage Your Contractor Profile</p>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        </div>
                        <div class="col-md-3">
                          <div class="card1 bg-danger p-20">
                            <div class="media widget-ten">
                              <div class="media-body media-text-right">
                                <h2 class="color-white">{{profile.bulkLeads}}</h2>
                                <p class="m-b-0"> Intrested Companies</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div v-if="institutes">
                        <div class="col-md-3">
                          <router-link to="/institution-profile">
                          <div class="card1 bg-pink p-20">
                            <div class="media widget-ten">
                              <div class="media-body media-text-right">
                                <h2 class="color-white"></h2>
                                <p class="m-b-0">Manage Your Institution Profile</p>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        </div>
                        <div class="col-md-3">
                          <div class="card1 bg-success p-20">
                            <div class="media widget-ten">
                              <div class="media-body media-text-right">
                                <h2 class="color-white">{{profile.institutionalLeads}}</h2>
                                <p class="m-b-0"> Institutional Request</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="financial_institutation">
                        <div class="col-md-3">
                          <router-link to="/machine-view">
                          <div class="card1 bg-pink p-20">
                            <div class="media widget-ten">
                              <div class="media-body media-text-right">
                                <h2 class="color-white"></h2>
                                <p class="m-b-0">Manage Your Financial Institution Profile</p>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        </div>
                        <div class="col-md-3">
                          <div class="card1 bg-danger p-20">
                            <div class="media widget-ten">
                              <div class="media-body media-text-right">
                                <h2 class="color-white">{{profile.finacialInstitutionLeads}}</h2>
                                <p class="m-b-0"> Intrested Candidates</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cl"></div>
</div>
</template>
<script>
  import SideBar from './SideBar.vue';
  export default {
    data(){
      return{
        profile:'',
        userRole:'',
        material_seller:'',
        machine_vendor:'',
        job_seeker:'',
        job_provider:'',
        buyer:'',
        institutes:'',
        contractor:'',
        financial_institutation:''

      }
    },
    components: {
      'sidebar': SideBar
    },
    created() {
      this.axios
      .get(process.env.VUE_APP_API_URL+"api/profile")
      .then(response => {
        this.userRole = response.data.profile[0].user_type.split(",");
        this.material_seller = this.userRole.includes('Material Seller')
        this.machine_vendor = this.userRole.includes('Machine Vendor')
        this.job_provider = this.userRole.includes('Job Provider')
        this.buyer = this.userRole.includes('Buyer')
        this.institutes = this.userRole.includes('Institutes')
        this.contractor = this.userRole.includes('Contractor')
        this.financial_institutation = this.userRole.includes('Financial Institutation')
        this.job_seeker = this.userRole.includes('Job Seeker')
        this.profile=response.data
      })
    }
  };
</script>