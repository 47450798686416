<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>Extruder Machine Add</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="invalid-feedback" v-if="errors">
                                        <div class="alert alert-danger">
                                            <ul>
                                                <li v-for="error in errors" :key="error">
                                                    {{ error }}
                                                </li>
                                            </ul>                    
                                        </div>
                                    </div>
                                    <form @submit.prevent="addMachine" ref="myForm">
                                        <div class="form-group row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Name *</label>
                                                    <input type="text" name="name" class="form-control" :class="{ 'is-invalid': errors.name }" v-model="machine.name" readonly="">
                                                    <div class="invalid-feedback" v-if="errors.name">
                                                        {{ errors.name[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Machine Type *</label>
                                                    <select name="machine_type" class="form-control" :class="{ 'is-invalid': errors.machine_type }" v-model="machine.machine_type" required>
                                                        <option value="" selected="">Select</option>
                                                        <option v-for="machineType in machineTypes" :key="machineType.name" :value="machineType.name">{{machineType.name}}</option>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.machine_type">
                                                        {{ errors.machine_type[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Screw Size Diameter <sub>mm</sub> *</label>
                                                    <input type="number" name="screw_size_diameter" class="form-control" :class="{ 'is-invalid': errors.screw_size_diameter }" v-model="machine.screw_size_diameter" required>
                                                    <div class="invalid-feedback" v-if="errors.screw_size_diameter">
                                                        {{ errors.screw_size_diameter[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Screw Size Length <sub>mm</sub> *</label>
                                                    <input type="number" name="screw_size_length" class="form-control" :class="{ 'is-invalid': errors.screw_size_length }" v-model="machine.screw_size_length" required>
                                                    <div class="invalid-feedback" v-if="errors.screw_size_length">
                                                        {{ errors.screw_size_length[0] }}
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Make *</label>
                                                    <input type="text" name="make" class="form-control" :class="{ 'is-invalid': errors.make }" v-model="machine.make" required>
                                                    <div class="invalid-feedback" v-if="errors.make">
                                                        {{ errors.make[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Mfg Year *</label>
                                                    <input type="number" name="mfg_year" class="form-control" :class="{ 'is-invalid': errors.mfg_year }" v-model="machine.mfg_year" required>
                                                    <div class="invalid-feedback" v-if="errors.mfg_year">
                                                        {{ errors.mfg_year[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                             <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Application</label>
                                                    <select name="application" class="form-control" :class="{ 'is-invalid': errors.application }" v-model="machine.application">
                                                        <option value="" selected="">Select</option>
                                                        <option v-for="application in applications" :key="application.name" :value="application.name">{{application.name}}</option>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.application">
                                                        {{ errors.application[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Extrusion Capacity<sub> (KG/Hr)</sub></label>
                                                    <input type="number" name="extrusion_capacity" class="form-control" :class="{ 'is-invalid': errors.extrusion_capacity }" v-model="machine.extrusion_capacity">
                                                    <div class="invalid-feedback" v-if="errors.extrusion_capacity">
                                                        {{ errors.extrusion_capacity[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Spare Capacity <sub> (Hours/Month)</sub></label>
                                                    <input name="spare_capacity" type="number" class="form-control" :class="{ 'is-invalid': errors.spare_capacity }" v-model="machine.spare_capacity">
                                                    <div class="invalid-feedback" v-if="errors.spare_capacity">
                                                        {{ errors.spare_capacity[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Raw Material Feed *</label>
                                                    <select name="raw_material_feed" class="form-control" :class="{ 'is-invalid': errors.raw_material_feed }" v-model="machine.raw_material_feed" required>
                                                        <option value="" selected="">Select</option>
                                                        <option v-for="rawMaterialFeed in rawMaterialFeeds" :key="rawMaterialFeed.name" :value="rawMaterialFeed.name">{{rawMaterialFeed.name}}</option>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.raw_material_feed">
                                                        {{ errors.raw_material_feed[0] }}
                                                    </div>
                                                </div>
                                            </div> -->
                                             
                                             <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Country *</label>
                                                    <select name="country_id" class="form-control" :class="{ 'is-invalid': errors.country_id }" v-model="machine.country_id" required="">
                                                        <option value="">Select</option>
                                                        <option v-for="country in countries" v-bind:key="country.id" :value="country.id" :selected="1">{{ country.name }}</option>
                                                    </select>
                                                    <div class="invalid-feedback" v-if="errors.country_id">
                                                        {{ errors.country_id[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>State/Province *</label>
                                                    <input type="text" name="state"  class="form-control" :class="{ 'is-invalid': errors.state }" v-model="machine.state" required="">
                                                    <div class="invalid-feedback" v-if="errors.state">
                                                        {{ errors.state[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>City *</label>
                                                    <input type="text" name="city"  class="form-control" :class="{ 'is-invalid': errors.city }" v-model="machine.city" required="">
                                                    <div class="invalid-feedback" v-if="errors.city">
                                                        {{ errors.city[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Pin Code *</label>
                                                    <input type="number" name="zip_code"  class="form-control" :class="{ 'is-invalid': errors.zip_code }" v-model="machine.zip_code" required="">
                                                    <div class="invalid-feedback" v-if="errors.zip_code">
                                                        {{ errors.zip_code[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Image1</label>
                                                    <input type="file" name="image1"  class="form-control" :class="{ 'is-invalid': errors.image1 }" accept="image/*">
                                                    <div class="invalid-feedback" v-if="errors.image1">
                                                        {{ errors.image1[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Image2</label>
                                                    <input type="file" name="image2"  class="form-control" :class="{ 'is-invalid': errors.state }">
                                                    <div class="invalid-feedback" v-if="errors.state">
                                                        {{ errors.state[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Image3</label>
                                                    <input type="file" name="image3"  class="form-control" :class="{ 'is-invalid': errors.state }">
                                                    <div class="invalid-feedback" v-if="errors.state">
                                                        {{ errors.state[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Image4</label>
                                                    <input type="file" name="image4"  class="form-control" :class="{ 'is-invalid': errors.state }">
                                                    <div class="invalid-feedback" v-if="errors.state">
                                                        {{ errors.state[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <input type="text" name="description" class="form-control" :class="{ 'is-invalid': errors.description }" v-model="machine.description">
                                                    <div class="invalid-feedback" v-if="errors.description">
                                                        {{ errors.description[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="submit" value="Submit" class="btn btn-info">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './../SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            machine: {
                machine_type:'',
                application:'',
                raw_material_feed:'',
                image1:'',
                name:'Extruder Machine'
            },
            machineTypes: [],
            applications: [],
            countries: [],
            rawMaterialFeeds: [],
            success:null,
            errors:false,
            loading: false,
            image1:null
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/extruder-machine-dropdown")
        .then(response => {
            this.machineTypes = response.data.machine_type;
            this.applications = response.data.application;
            this.rawMaterialFeeds= response.data.raw_material_feed;
        })
        .finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/countries")
        .then(response => {
            this.countries = response.data.countries;
        });
    },
    methods: {
            addMachine() {
                this.loading = true
                this.success = null
                this.errors = null
                var myFormData = new FormData(this.$refs.myForm)
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/extruder-machine-add", myFormData)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        //window.location.reload();
                        this.$router.push({ name: "ExtruderMachineView" });
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            
        }
};
</script>