<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../assets/images/loader.gif" alt="" />
            </div>
        </div>
<div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>Job Seeker Profile</h3>
                        </div>
      <div class="tab-content">
          <div class="tab-pane active" id="home-v" v-for="executive in executives" :key="executive.id">
            <div class="col-md-4">
              <div class="cnd-main">
                <div class="cad-img">
                  <img :src='imagePath+executive.image' class="img-responsive">
                </div>
                
              </div>
            </div>
            <div class="col-md-8">
              <div >
        <div class="packs-contant">
          <h3 style="margin-top:0;">{{ executive.name }}</h3>
          <ul>
                    <li><i class="fa fa-mobile"></i> {{ executive.mobile }}</li>
                    <li><i class="fa fa-envelope"></i> {{ executive.email }}</li>
                  </ul>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">About Me</td>
              </tr>
             <tr >
              <td colspan="2">{{ executive.about }}</td>
            </tr>
             <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Category</td>
              </tr>
             <tr >
              <td colspan="2">{{ executive.executive_manpower_categories }}</td>
            </tr>
             <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Key Skill(s)</td>
              </tr>
             <tr >
              <td colspan="2">{{ executive.key_skills }}</td>
            </tr>
             <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Highest Qualification</td>
              </tr>
             <tr>
              <td colspan="2">{{ executive.highest_qualification }}</td>
            </tr>
            
            <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Specialization</td>
              </tr>
                           <tr >
              <td colspan="2">{{ executive.executive_manpower_specializations }}</td>
            </tr>
              <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Past Company Details</td>
              </tr>
             <tr >
              <td colspan="2"><b>Total Work Experience</b> {{ executive.total_experience }} Years</td>
            </tr>

            <tr v-for='exp in JSON.parse(executive.past_experience)' :key="exp.from">
              <td colspan="2">
                <table width="100%" style="border:0px" class="table">
                  <tr>
                    <td><b>{{ exp.company_name}}</b> </td>
                  </tr>
                  <tr>
                    <td><b>Designation:</b></td>
                  </tr>
                  <tr >
                    <td><b>From:</b> {{ exp.from}} <b>To:</b> {{ exp.to}}</td>
                  </tr>
                  <tr>
                    <td>{{ exp.description}}</td>
                  </tr>
                  <tr></tr>
                </table>
              </td>
            </tr>
            <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Address</td>
              </tr>
              <tr >
              <td colspan="2">{{ executive.address }}</td>
            </tr>
            <tr>
              <th>City</th>
              <td>{{ executive.city}} </td>
            </tr>
            
            <tr> 
              <th>State</th>
              <td>{{ executive.state}}</td>
            </tr>
            <tr> 
              <th>Pincode</th>
              <td>{{ executive.pin_code }}</td>
            </tr>
            <tr> 
              <th>Country</th>
              <td>{{ executive.country.name }}</td>
            </tr>
            
            </tbody>
          </table>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>

    </div>
</template>

<script>
  import SideBar from './SideBar.vue';

export default {
  components: {
        'sidebar': SideBar
    },
 props: ['details'],
    data() {
        return {
            executives: [],
            imagePath: process.env.VUE_APP_API_URL,
            showInfo:false,
            showInfoButton:true
        }
    },
    created() {
        this.axios
            .get(process.env.VUE_APP_API_URL+'api/my-manpower-executive-details?id=5')
            .then(response => {
                this.executives = response.data.my_manpower_executive_details;
            });
    }
}
</script>