<template>
    <div>
        <div class="cl"></div>
<div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12">     
        <div class="top-cat-sec">
          <div class="home-tit">
            <h2><span>Machine {{ machinetype }}</span></h2>
          </div>
          <div class="land-pack">
            <ul>
              <li>
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/img/injection.jpg" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>Injection Molding Machine</h5>
                    <router-link to="/injection-machine" class="land-pack-grid-btn"></router-link>
                  </div>
                </div>
              </li>
              <li>
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/img/blow-molding.jpg" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>Blow Molding Machine</h5>
                    <router-link to="/blow-molding-machine" class="land-pack-grid-btn"></router-link>
                  </div>
                </div>
              </li>
              <li>
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/img/extruder.jpg" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>Extruder Machine</h5>
                    <router-link to="/extruder-machine" class="land-pack-grid-btn"></router-link>
                  </div>
                </div>
              </li>
              <li>
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/img/tool.jpg" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>Tool & Die</h5>
                    <router-link to="/tool-and-die" class="land-pack-grid-btn"></router-link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
  data() {
      return {
          machinetype : ''
      }
  },
    created() {
      this.machinetype = sessionStorage.getItem("machineName");
      if(this.machinetype == '')
      {
        this.$router.push({ name: "MachineType" });
      }
    }
}
</script>