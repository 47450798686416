<template>
    <div>
        
<div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12">     
        <div class="tab-content">
          <div class="tab-pane active" id="home-v" v-for="bulkManpower in bulkManpowers" :key="bulkManpower.id">
            <div class="col-md-4">
              <div class="cnd-main">
                <div class="cad-img">
                  <img :src='imagePath+bulkManpower.logo' class="img-responsive">
                </div>
                <div class="cad-cnt">
                  <div class="cnd-tt">
                    <h3>{{ bulkManpower.name }}</h3>
                    <span v-if="showInfoButton">
                    <button v-on:click="showInfo = true;showInfoButton = false" class="btn btn-success">View Contact Details</button></span>
                  </div>
                  <ul v-if="showInfo">
                    <li><i class="fa fa-mobile"></i> {{ bulkManpower.contact_no }}</li>
                    <li><i class="fa fa-envelope"></i> {{ bulkManpower.email }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div >
        <div class="packs-contant">
          <h3 style="margin-top:0;">Labour Details</h3>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">About Me</td>
              </tr>
             <tr >
              <td colspan="2">{{ bulkManpower.about }}</td>
            </tr>
             <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Address</td>
              </tr>
              <tr >
              <td colspan="2">{{ bulkManpower.address }}</td>
            </tr>
            <tr>
              <th>City</th>
              <td>{{ bulkManpower.city}} </td>
            </tr>
            
            <tr> 
              <th>State</th>
              <td>{{ bulkManpower.state}}</td>
            </tr>
            <tr> 
              <th>Pincode</th>
              <td>{{ bulkManpower.pin_code }}</td>
            </tr>
            <tr> 
              <th>Country</th>
              <td>{{ bulkManpower.country.name }}</td>
            </tr>
          
              <tr>
                <td colspan="2" style="background-color:#5cb85c;color: #fff;">Team Capacity</td>
              </tr>
             <tr >
              <td><b>Team Capacity</b> </td>
              <td><b>{{ bulkManpower.team_capacity }}</b></td>
            </tr>

            <tr v-for='labour in JSON.parse(bulkManpower.labour_categorywise)' :key="labour.category">
              <td>{{ labour.category}} </td>
                    <td>{{ labour.capacity}}</td>
            </tr>
            
            
            </tbody>
          </table>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
</template>

<script>
export default {
 props: ['details'],
    data() {
        return {
            bulkManpowers: [],
            imagePath: process.env.VUE_APP_API_URL,
            showInfo:false,
            showInfoButton:true
        }
    },
    created() {
        this.axios
            .get(process.env.VUE_APP_API_URL+'api/bulk-manpower-work-details?id='+localStorage.getItem("labourid"))
            .then(response => {
                this.bulkManpowers = response.data.bulk_manpower_details;
            });
    }
}
</script>