<template>
    <div>
        <div v-if="loading">
            <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
                <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
            <div class="container-fluid">
                <div class="row">
                   <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>My Tool and Die</h3>
                        </div>
                        <div class="profile-details" >
                            <div class="col-md-12 p_t_10">
                                <div class="alert alert-success text-center" v-if="success">
                                    {{ success }}
                                </div>
                                <div class="row" v-if="toolMachines.length">
                                  <div class="col-md-3" v-for="(toolMachine , index) in toolMachines" v-bind:key="index">
                                    <div class="grid-item" v-if="toolMachine">
                                      <div class="imgholder ">
                                        <a @click="viewMachine(toolMachine.id)">
                                         <img :src="imgPath+toolMachine.logo">
                                         <div class="top-right">
                                           <div class="btn btn-warning" v-if="toolMachine.approved=='Pending'">
                                            Under Review
                                        </div>
                                        <div class="btn btn-success" v-if="toolMachine.approved=='Approved'">
                                            Published
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="product_footer">
                                <div class="grid_product_header">
                                  <span class="name">Reference ID: {{ toolMachine.id }}<br>{{ toolMachine.name }} </span>
                              </div>
                              <div class="product_footer-info">
                                <div>{{ toolMachine.address }},{{ toolMachine.state }}</div>
                                <button class="btn btn-danger" @click="deleteMachine(toolMachine.id)" title="Delete"><i class="fa fa-trash"></i></button></div>
                            </div>
                        </div>
                        <div v-else>Record Not Found</div>
                    </div>

                </div>
                <div v-else><center><b>No Data Found</b></center></div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
<div class="cl"></div>
</div>
</template>
<script>
import SideBar from './../SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },

    data() {
        return {
            toolMachines: {},
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            success:''
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/tool-room-company")
        .then(response => {
            this.toolMachines = response.data.company;
        })
        .finally(() => this.loading = false);
    },
    methods: {
        viewMachine(id) {
          sessionStorage.setItem("MyToolMachine", id);
          this.$router.push({ name: "MyToolDieMachineDetails" });
      },
      deleteMachine(id) {
          this.axios
          .delete(process.env.VUE_APP_API_URL+"api/tool-room-company/"+id)
          .then(response => {
            if(response.error==false){
                window.location.reload();
                    }
                })
          .finally(() => this.loading = false);
        }
  }
};
</script>
<style type="text/css">
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  color: red;
  text-align: right;
}
</style>