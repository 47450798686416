import Vue from 'vue'
import App from './App.vue'
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import axios from 'axios';
import { routes } from './routes';
import store from "./store";
import moment from 'moment';
import './assets/css/custom.css';
  import './assets/css/style.css';
  import './assets/css/style-nav.css';
  import './assets/css/top-slider.css';
  import './assets/css/animate.css';
  import './assets/css/bootstrap.min.css';
  import './assets/css/normalize.min.css';
  import './assets/css/font-awesome.min.css';
  import './assets/css/chat.css';
  /* import './assets/js/jquery-2.2.4.min.js';
  import './assets/js/bootstrap.min.js';
import './assets/js/index.js';
import './assets/js/top-slider.js';
import './assets/js/wow.min.js';
import './assets/js/owl.carousel.js';
import './assets/js/owl.carousel.min.js'; */
import LoadScript from 'vue-plugin-load-script';
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import VueMeta from 'vue-meta';

Vue.use(VueMeta);

Vue.use(VueMaterial)

Vue.use(LoadScript);

  
Vue.config.productionTip = false

axios.interceptors.response.use(
   response => response,
   error => {
     if (error.response.status === 422) {
       store.commit("setErrors", error.response.data.errors);
     } else if (error.response.status === 401) {
       store.commit("auth/setUserData", null);
       localStorage.removeItem("authToken");
       router.push({ name: "login" });
     } else {
       return Promise.reject(error);
     }
   }
 );
 axios.interceptors.request.use(function(config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  return config;
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-Y')
    }
});
Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-Y, h:m a')
    }
});
Vue.filter('formatDateTimeMonth', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM-Y, hh:mm a')
    }
});

Vue.use(VueRouter);
Vue.use(VueAxios, axios);
/* Vue.loadScript('https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js');
Vue.loadScript("https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.0.2/js/bootstrap.min.js");
Vue.loadScript("./assets/js/index.js");
Vue.loadScript("./assets/js/top-slider.js")
.then(() => {
// Script is loaded, do something
});
Vue.loadScript("./assets/js/wow.min.js")
.then(() => {
// Script is loaded, do something
});
Vue.loadScript("./assets/js/owl.carousel.js")
.then(() => {
// Script is loaded, do something
});
Vue.loadScript("./assets/js/owl.carousel.min.js")
.then(() => {
// Script is loaded, do something
}); */
const router = new VueRouter({
    mode: 'history',
    base: '/',
	 //base:process.env.VUE_APP_BASE_URL,
    publicPath: process.env.VUE_APP_BASE_URL,
    routes: routes
});


new Vue({
  router: router,
    store:store,
  render: h => h(App),
}).$mount('#app')
