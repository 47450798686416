<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>Add Token</h3>
                        </div>
                        <div class="profile-details" >
                            <div class="col-md-12 p_t_10">
                                <div class="alert alert-success text-center" v-if="success!=null">
                                    {{ success }}
                                </div>
                                <div class="row">
                                    <div class="col-md-12">     
                                        <div class="top-cat-sec">
                                          <div class="home-tit">
                                            <h2><span>Package</span></h2>
                                        </div>
                                        <div class="land-pack">
                                            <ul>
                                              <li v-for="(package1 , index) in packages"  v-bind:key="index">
                                                <div class="land-pack-grid">
                                                  <div class="land-pack-grid-img">
                                                  {{ package1.token }} Token  
                                                </div>
                                                RS. {{ package1.price }}
                                                <div class="land-pack-grid-text">
                                                    <h5>{{ package1.name }}</h5>
                                                    
                                                </div>
                                            </div>
                                        </li>
                                        
                        </ul>
                    </div>
                </div>
            </div>

        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './../SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            packages: {},
            imgPath: process.env.VUE_APP_API_URL,
            errors:{

            },
            success:null,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/wallet-package")
        .then(response => {
            this.packages = response.data.packages;
        })
        .finally(() => this.loading = false);
    },
    methods: {
            updateProfile() {
                this.loading = true
                this.success = null
                this.errors = {}
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/profile-update", this.profile)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        this.$router.go
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            }
        }
}
</script>