<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>Blow Molding Machine Edit</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="invalid-feedback" v-if="errors">
                                        <div class="alert alert-danger">
                                            <ul>
                                                <li v-for="error in errors" :key="error">
                                                    {{ error }}
                                                </li>
                                            </ul>                    
                                        </div>
                                    </div>
                                    <div >
                                        <form @submit.prevent="addMachine" ref="myForm">
                                            <div class="form-group row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Name *</label>
                                                        <input type="text" name="name" class="form-control" :class="{ 'is-invalid': errors.name }" v-model="machine.name" readonly="">
                                                        <div class="invalid-feedback" v-if="errors.name">
                                                            {{ errors.name[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Clamp Force <sub>Ton</sub> *</label>
                                                        <input type="number" name="machine_size" class="form-control" :class="{ 'is-invalid': errors.machine_size }" v-model="machine.machine_size" required>
                                                        <div class="invalid-feedback" v-if="errors.machine_size">
                                                            {{ errors.machine_size[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Capacity<sub> Ltrs</sub> *</label>
                                                        <input type="number" name="accumulator_capacity" class="form-control" :class="{ 'is-invalid': errors.accumulator_capacity }" v-model="machine.accumulator_capacity" required>
                                                        <div class="invalid-feedback" v-if="errors.accumulator_capacity">
                                                            {{ errors.accumulator_capacity[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Platen Size Length <sub>mm</sub> *</label>
                                                        <input type="number" name="platen_size_length" class="form-control" :class="{ 'is-invalid': errors.platen_size_length }" v-model="machine.platen_size_length" required>
                                                        <div class="invalid-feedback" v-if="errors.platen_size_length">
                                                            {{ errors.platen_size_length[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Platen Size Width <sub>mm</sub> *</label>
                                                        <input type="number" name="platen_size_width" class="form-control" :class="{ 'is-invalid': errors.platen_size_width }" v-model="machine.platen_size_width" required>
                                                        <div class="invalid-feedback" v-if="errors.platen_size_width">
                                                            {{ errors.platen_size_width[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Tie Bar Distance Length <sub>mm</sub> *</label>
                                                        <input type="number" name="tie_bar_distance_length" class="form-control" :class="{ 'is-invalid': errors.tie_bar_distance_length }" v-model="machine.tie_bar_distance_length" required>
                                                        <div class="invalid-feedback" v-if="errors.tie_bar_distance_length">
                                                            {{ errors.tie_bar_distance_length[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Tie Bar Distance Width <sub>mm</sub> *</label>
                                                        <input type="number" name="tie_bar_distance_width" class="form-control" :class="{ 'is-invalid': errors.tie_bar_distance_width }" v-model="machine.tie_bar_distance_width" required>
                                                        <div class="invalid-feedback" v-if="errors.tie_bar_distance_width">
                                                            {{ errors.tie_bar_distance_width[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Make *</label>
                                                        <input type="text" name="make" class="form-control" :class="{ 'is-invalid': errors.make }" v-model="machine.make" required>
                                                        <div class="invalid-feedback" v-if="errors.make">
                                                            {{ errors.make[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Mfg Year *</label>
                                                        <input type="number" name="mfg_year" class="form-control" :class="{ 'is-invalid': errors.mfg_year }" v-model="machine.mfg_year" required>
                                                        <div class="invalid-feedback" v-if="errors.mfg_year">
                                                            {{ errors.mfg_year[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Machine Drive *</label>
                                                        <select name="machine_drive" class="form-control" :class="{ 'is-invalid': errors.machine_drive }" v-model="machine.machine_drive" required>
                                                            <option value="" selected="">Select</option>
                                                            <option v-for="machineDrive in machineDrives" :key="machineDrive.name" :value="machineDrive.name">{{machineDrive.name}}</option>
                                                        </select>
                                                        <div class="invalid-feedback" v-if="errors.machine_drive">
                                                            {{ errors.machine_drive[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Machine Operation *</label>
                                                        <select name="machine_operation" class="form-control" :class="{ 'is-invalid': errors.machine_operation }" v-model="machine.machine_operation" required>
                                                            <option value="" selected="">Select</option>
                                                            <option v-for="machineOperation in machineOperations" :key="machineOperation.name" :value="machineOperation.name">{{machineOperation.name}}</option>
                                                        </select>
                                                        <div class="invalid-feedback" v-if="errors.machine_operation">
                                                            {{ errors.machine_operation[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Raw Material Feed *</label>
                                                        <select name="raw_material_feed" class="form-control" :class="{ 'is-invalid': errors.raw_material_feed }" v-model="machine.raw_material_feed" required>
                                                            <option value="" selected="">Select</option>
                                                            <option v-for="rawMaterialFeed in rawMaterialFeeds" :key="rawMaterialFeed.name" :value="rawMaterialFeed.name">{{rawMaterialFeed.name}}</option>
                                                        </select>
                                                        <div class="invalid-feedback" v-if="errors.raw_material_feed">
                                                            {{ errors.raw_material_feed[0] }}
                                                        </div>
                                                    </div>
                                                </div> -->
                                                 <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Spare Capacity <sub> (Hours/Month)</sub> *</label>
                                                        <input name="spare_capacity" type="number" class="form-control" :class="{ 'is-invalid': errors.spare_capacity }" v-model="machine.spare_capacity" required>
                                                        <div class="invalid-feedback" v-if="errors.spare_capacity">
                                                            {{ errors.spare_capacity[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Country *</label>
                                                        <select name="country_id" class="form-control" :class="{ 'is-invalid': errors.country_id }" v-model="machine.country_id" required="">
                                                            <option value="">Select</option>
                                                            <option v-for="country in countries" v-bind:key="country.id" :value="country.id" :selected="1">{{ country.name }}</option>
                                                        </select>
                                                        <div class="invalid-feedback" v-if="errors.country_id">
                                                            {{ errors.country_id[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>State/Provinxe *</label>
                                                        <input type="text" name="state"  class="form-control" :class="{ 'is-invalid': errors.state }" v-model="machine.state" required="">
                                                        <div class="invalid-feedback" v-if="errors.state">
                                                            {{ errors.state[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>City *</label>
                                                        <input type="text" name="city"  class="form-control" :class="{ 'is-invalid': errors.city }" v-model="machine.city" required="">
                                                        <div class="invalid-feedback" v-if="errors.city">
                                                            {{ errors.city[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Pin Code *</label>
                                                        <input type="text" name="zip_code"  class="form-control" :class="{ 'is-invalid': errors.zip_code }" v-model="machine.zip_code" required="">
                                                        <div class="invalid-feedback" v-if="errors.zip_code">
                                                            {{ errors.zip_code[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Image1</label>
                                                        <input type="file" name="image1"  class="form-control" :class="{ 'is-invalid': errors.image1 }" accept="image/*" @change="onFileChange">
                                                        <div class="invalid-feedback" v-if="errors.image1">
                                                            {{ errors.image1[0] }}
                                                        </div>
                                                        <div id="preview">
                                                            <img v-if="url" :src="url" />
                                                          </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Image</label>
                                                        <input type="file" name="image2"  class="form-control" :class="{ 'is-invalid': errors.state }" @change="onFileChange2">
                                                        <div class="invalid-feedback" v-if="errors.state">
                                                            {{ errors.state[0] }}
                                                        </div>
                                                        <div id="preview">
                                                            <img v-if="url2" :src="url2" />
                                                          </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Image</label>
                                                        <input type="file" name="image3"  class="form-control" :class="{ 'is-invalid': errors.state }" @change="onFileChange3">
                                                        <div class="invalid-feedback" v-if="errors.state">
                                                            {{ errors.state[0] }}
                                                        </div>
                                                        <div id="preview">
                                                            <img v-if="url3" :src="url3" />
                                                          </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Image</label>
                                                        <input type="file" name="image4"  class="form-control" :class="{ 'is-invalid': errors.state }" @change="onFileChange4">
                                                        <div class="invalid-feedback" v-if="errors.state">
                                                            {{ errors.state[0] }}
                                                        </div>
                                                        <div id="preview">
                                                            <img v-if="url4" :src="url4" />
                                                          </div>
                                                    </div>
                                                </div>
                                                <input type="hidden" name="id" v-model="machine.id">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Description*</label>
                                                        <input type="text" required name="description" class="form-control" :class="{ 'is-invalid': errors.description }" v-model="machine.description">
                                                        <div class="invalid-feedback" v-if="errors.description">
                                                            {{ errors.description[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <input type="submit" value="Submit" class="btn btn-info">
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './../SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            machine: {},
            machineDrives: [],
            machineOperations: [],
            countries: [],
            rawMaterialFeeds: [],
            success:null,
            errors:false,
            loading: false,
            image1:null,
            url: null,
            url2: null,
            url3: null,
            url4: null,
            imgPath: process.env.VUE_APP_API_URL
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/my-blow-molding-machine-details?id="+sessionStorage.getItem("MyBlowMachine"))
        .then(response => {
            this.machine = response.data.blow_modling_machine_details[0];
            this.url = this.imgPath+response.data.blow_modling_machine_details[0].image1;
            this.url2 = this.imgPath+response.data.blow_modling_machine_details[0].image2;
            this.url3 = this.imgPath+response.data.blow_modling_machine_details[0].image3;
            this.url4 = this.imgPath+response.data.blow_modling_machine_details[0].image4;
        })
        .finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/blow-molding-machine-dropdown")
        .then(response => {
            this.machineDrives = response.data.machine_drive;
            this.machineOperations = response.data.machine_operation;
            this.rawMaterialFeeds= response.data.raw_material_feed;
        })
        .finally(() => this.loading = false);

        this.axios
        .get(process.env.VUE_APP_API_URL+"api/countries")
        .then(response => {
            this.countries = response.data.countries;
        });
    },
    methods: {
            addMachine() {
                this.loading = true
                this.success = null
                this.errors = null
                var myFormData = new FormData(this.$refs.myForm)
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/blow-molding-machine-update", myFormData)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                        this.$router.push({ name: "MyBlowMoldingMachineDetails" });
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            onFileChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
            },
            onFileChange2(e) {
                const file = e.target.files[0];
                this.url2 = URL.createObjectURL(file);
            },
            onFileChange3(e) {
                const file = e.target.files[0];
                this.url3 = URL.createObjectURL(file);
            },
            onFileChange4(e) {
                const file = e.target.files[0];
                this.url4 = URL.createObjectURL(file);
            }            
        }
};
</script>
<style type="text/css">
    #preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 100px;
}
</style>